import { useRecoilState } from "recoil";

import { authentication } from '../components/Firebase';
import { authAtom} from "../state";
import { history } from "./history";

import * as ROUTES from '../constants/routes';

export { useFetchWrapper };

function useFetchWrapper() {

    const [auth, setAuth] = useRecoilState(authAtom);

    return {
        get: request('GET'),
        post: request('POST'),
        patch: request('PATCH'),
        delete: request('DELETE'),
    };

    function request (method) {
        return (url, body) => {
            const requestOptions = {
                method: method,
                headers: authHeader(url)
            };
            if (body) {
                requestOptions.headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(body);
            }
            return fetch(url, requestOptions).then(handleResponse);
        }
    }

    function authHeader(url) {
        const token = 
            auth !== null ? auth.accessToken : 
                (localStorage.getItem('user') !== null) ? JSON.parse(localStorage.getItem('user')).accessToken : null;
        
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
    
        if (isLoggedIn && isApiUrl) {
            return { Authorization: `Bearer ${token}` };
        } else {
            return {};
        }
    }

    function handleResponse(response) {
        return response.text().then( text => {
           
           const data = text && JSON.parse(text);
           if (!response.ok) {
                const token = auth !== null ? auth.accessToken : 
                    (localStorage.getItem('user') !== null) ? 
                        JSON.parse(localStorage.getItem('user')).accessToken : 
                        null;
                if ([401,403].includes(response.status) && token) {
                   localStorage.removeItem('user');
                   setAuth(null);
                   authentication.signOut();
                   //TODO fix this push
                   history.push(ROUTES.LANDING);
                }
   
               const error = (data && data.message) || response.statusText;
               return Promise.reject(error);
           }
   
           return data;
       });
    }
}