
import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts';

class CompanyBarChart extends Component {

    render() {

     const stateArea = {
        series: [
          { 
            name: 'Ambiental',
            data: [0, 9, 11, 14, 22, 22, 35, 36, 37, 41, 45, 55], 
          },
          { 
            name: 'Economica',
            data: [0, 3, 4, 5, 8, 10, 11, 11, 11, 12, 15, 17], 
          },
          { 
            name: 'Social',
            data: [1, 3, 10, 18, 19, 22, 23, 28, 31, 37, 39, 41], 
          }
        ],
        colors:['#4C9F38', '#FEB714', '#9C27B0'],
        options: {
          chart: {
            id: 'AreaChart',
            type: 'area',
            toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  selection: true,
                  zoom: false,
                }
              }
          },
          colors: ['#4C9F38','#FEB714', '#EA1E2D' ],
          dataLabels: {
              enabled: false,
              style: {
                fontSize: '12px',
                colors: ['#000000']
              }
          },
          fill: {
              colors: ['#4C9F38','#FEB714','#EA1E2D']
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              }
            },
          },
          legend: {
              labels: {
                  colors: ['#4C9F38','#FEB714','#EA1E2D'],
                  useSeriesColors: true,
              }
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            }
          },
          stroke: {
              curve: 'smooth',
          },
          tooltip: {
              x: {
                  format: 'dd/MM/yy',
              },
          },
          xaxis: {
            categories: ["Enero","Febrero","Marzo","Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
          },
          yaxis: {
            show: true,
          },
        },
      };

        return(
            <ReactApexChart options={stateArea.options} series={this.props.series} type="area" height={350} />
        )
    }
}

export default CompanyBarChart;