import React from 'react';
import { 
    BrowserRouter as Router,
    Route, 
    Switch,
} from 'react-router-dom';

import { withAuthentication } from '../Session';

import * as ROUTES from '../../constants/routes';

import PageNotFound from '../PageNotFound';

import LandingPage from '../Landing';
import CookiesPage from '../Cookies';
import PrivacyPage from '../Privacy';

import SignOutPage from '../SignOut';

import CompanyDashboardPage from '../Company';
import PlansPage from '../Plans';
import FindOutPage from '../FindOut';
import ContactPage from '../Contact';

import UserPasswordForgetPage from '../User/PasswordForget';
import UserSignInPage from '../User/SignIn';
import UserHomePage from '../User/Home';
import UserRatingPage from '../User/Rating';
import UserActionsPage from '../User/Actions';

import CompanySignUpPage from '../Company/SignUp';
import CompanySignInPage from '../Company/SignIn';
import CompanyPasswordForgetPage from '../Company/PasswordForget';
import CompanyReportPage from '../Company/Report';

import UserProfilePage from '../User/Profile';
import CompanyProfilePage from '../Company/Profile';
import CompanyPayPage from '../Company/Pay';
import CompanyManagementPage from '../Company/Management';
import CompanyActionsPage from '../Company/Actions';
import CompanyReportsPage from '../Company/Reports';
import CompanyBasicReportsPage from '../Company/Reports/BasicReports';



function App({ t }) {
  return (
        <Router>
            <Switch>
                <Route exact path={ROUTES.LANDING}  component={LandingPage} />
                <Route exact path={ROUTES.COOKIES}  component={CookiesPage}  />
                <Route exact path={ROUTES.PRIVACY}  component={PrivacyPage}/>
                <Route exact path={ROUTES.SIGNOUT}  component={SignOutPage} />
                <Route exact path={ROUTES.PLANS}    component={PlansPage} />
                <Route exact path={ROUTES.FIND_OUT} component={FindOutPage} />
                <Route exact path={ROUTES.CONTACT}  component={ContactPage} />

                <Route exact path={ROUTES.USER_HOME}     component={UserHomePage} />
                <Route exact path={ROUTES.USER_SIGNIN}   component={UserSignInPage} />
                <Route exact path={ROUTES.USER_PASSWORD_FORGET}  component={UserPasswordForgetPage} />
                <Route exact path={ROUTES.USER_PROFILE} component={UserProfilePage} />
                <Route exact path={ROUTES.USER_RATING}   component={UserRatingPage} />
                <Route exact path={ROUTES.USER_ACTIONS}  component={UserActionsPage} />
            
                
                <Route exact path={ROUTES.COMPANY_SIGNUP} component={CompanySignUpPage}/>
                <Route exact path={ROUTES.COMPANY_PAY} component={CompanyPayPage} />
                <Route exact path={ROUTES.COMPANY_SIGNIN} component={CompanySignInPage}/>
                <Route exact path={ROUTES.COMPANY_PASSWORD_FORGET} component={CompanyPasswordForgetPage}/>
                <Route exact path={ROUTES.COMPANY_DASHBOARD} component={CompanyDashboardPage} />  
                <Route exact path={ROUTES.COMPANY_REPORT} component={CompanyReportPage} />  
                <Route exact path={ROUTES.COMPANY_PROFILE} component={CompanyProfilePage} />
                <Route exact path={ROUTES.COMPANY_ACTIONS} component={CompanyActionsPage} />
                <Route exact path={ROUTES.COMPANY_MANAGEMENT} component={CompanyManagementPage}/>
                <Route exact path={ROUTES.COMPANY_REPORTS} component={CompanyReportsPage} />
                <Route exact path={ROUTES.COMPANY_BASIC_REPORTS} component={CompanyBasicReportsPage} />

                <Route component={PageNotFound}/>
            </Switch>
        </Router>)
}

export default withAuthentication(App);