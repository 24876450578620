import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Link, withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { authentication } from '../../Firebase';
import { useCompanyAdminService } from '../../../services/companyAdmin.service';
import * as ROUTES from '../../../constants/routes';
import { history } from '../../App/CompanyHeader';
import { userMapper, companyBasicMapper } from '../../../utils';
import { authAtom } from '../../../state';

 
const Main = () => {

    const companyAdminService = useCompanyAdminService();

    const setAuth = useSetRecoilState(authAtom);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const onSubmit = event => {
    
       authentication.signInWithEmailAndPassword(email.trim(), password)
        .then((userAuth) => { 

            const userMapped = userMapper(JSON.parse(JSON.stringify(userAuth.user)));
            localStorage.setItem('user', JSON.stringify(userMapped));
            setAuth(userMapped);
        
            companyAdminService.getByUid(userAuth.user.uid)
                .then( data =>  {
                    localStorage.setItem('company',
                        JSON.stringify(companyBasicMapper(
                                        data.companyAdmin.companyId,
                                        data.companyAdmin.companyCode,
                                        data.companyAdmin.companyName)));
                    history.push(ROUTES.COMPANY_DASHBOARD);
                })
                .catch( error => {
                    authentication.signOut();
                    setPassword('')
                    setError(new Error("No tienes suficientes permisos para acceder a esta zona"));
                })
        })
        .catch(error => {
            setError(error);
        });
  
      event.preventDefault();
  };

  const isInvalid = password === '' || email === '';
 
  return (
    <article id="main">
        <section className="wrapper style5">
            <div className="inner">
                <section id="main" className="container">
                    <div className="box"> 
                          <h3>Te damos la bienvenida a la zona de empresa</h3>
                          <h5>Inicia sesión en tu cuenta de Administrador</h5>
                          {error && <Alert color="danger">{error.message}</Alert>}
                          <form onSubmit={onSubmit}>
                              <div className="row gtr-50 gtr-uniform">
                                  <div className="col-12 col-12-mobilep">
                                      <span className="label-input100">Email</span>
                                      <input
                                          name="email"
                                          value={email}
                                          onChange={({ target }) => setEmail(target.value)}
                                          type="text"
                                          placeholder="Introduce tu email"
                                          />
                                  </div>
                                  <div className="col-12 col-12-mobilep">
                                      <span className="label-input100">Contraseña</span>
                                      <input
                                          name="password"
                                          value={password}
                                          onChange={({ target }) => setPassword(target.value)}
                                          type="password"
                                          placeholder="Introduce tu contraseña"/>
                                  </div>                   
                                  <div className="col-12">
                                          <ul className="actions special">
                                              <li><input className="primary" disabled={isInvalid} type="submit" value="Login"/></li>
                                          </ul>
                                  </div>
                                </div>
                            </form>
                      </div>
                      <div className="box">
                            <div className="col-12">
                                <Link to={ROUTES.COMPANY_PASSWORD_FORGET} className="orangelink">¿Has olvidado la contraseña?</Link>
                            </div>
                      </div>
                      
                </section>
              </div>
          </section>
      </article> 
  )
}
  
export default withRouter(Main);