import React, { Component } from 'react';

import CompanyHeader from '../../App/CompanyHeader';
import Main from './Main';

import { loadJSv2 } from '../../../utils/script';


class CompanyPasswordForgetPage extends Component {

  UNSAFE_componentWillMount() {
    loadJSv2('/assets/js/main.js');
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.remove('landing');
    document.getElementById("header").classList.remove("alt");
  }

  render() {
    return (
        [<CompanyHeader key="companyPasswordForgetHeader"/>,<Main key="companyPasswordForgetMain"/>]
      );
  }
}

export default CompanyPasswordForgetPage;