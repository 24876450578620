import React, { useState, useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import { Alert } from 'reactstrap';
import Nav from './Nav';
import StepZero from './StepZero';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import * as structures from '../../../constants/inout';
import { useEmployeeRatingService } from '../../../services/employeeRating.service';
import { useCompanyService } from '../../../services/company.service';

const Main = () => {

    const employeeRatingService = useEmployeeRatingService();
    const companyService = useCompanyService();

    const [error, setError] = useState(null);
    const [state, updateState] = useState({
        form: structures.defaultEmployeeRatingForm,
        transitions: {
            enterRight: '.animated .enterRighet',
            enterLeft: '.animated .enterLeft',
            exitRight: '.animated .exitRight',
            exitLeft: '.animated .exitLeft',
            intro: '.animated .intro',
        },
        // demo: true, // uncomment to see more
      });

    const [totalEmployees, setTotalEmployees] = useState(0);

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    // Do something on step change
    const onStepChange = (stats) => {
        console.log(stats);
    };

    const setInstance = SW => updateState({
        ...state,
        SW,
    });

    const { SW, demo } = state;

    const getEmployeeRating = (employeeId) => {
        const employeeRating = {form: null, transitions: null};
        employeeRatingService.getByEmployeeId(employeeId)
          .then(data => {
              if (!!data.employeeRating.form) {
                updateState(data.employeeRating);
              }
          })
          .catch(error => setError(error));
    }

    const getCompanyBasicData = (companyId) => {
        companyService.getBasicData(companyId)
            .then(data => {
                setTotalEmployees(data.company.totalEmployees);
            })
            .catch(error => setError(error));
    }

    useEffect( () => {
        const employee = JSON.parse(localStorage.getItem('employee'));
        if (employee) {
            getEmployeeRating(employee.employeeId);
            getCompanyBasicData(employee.companyId);
        }        
      }, [])

    return (
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    {error && <Alert color="danger">{error.message}</Alert>}
                    <section id="main" className="container">
                        <div className="box"> 
                              <StepWizard onStepChange={onStepChange}
                                            isHashEnabled
                                            transitions={state.transitions} // comment out for default transitions
                                            nav={<Nav />}
                                            instance={setInstance}>
                                <StepZero form={state.form} hashKey={'InitialStep'} update={updateForm} />
                                <StepOne form={state.form} hashKey={'SocialStep'} update={updateForm} />
                                <StepTwo form={state.form} hashKey={'EnvironmentalStep'} update={updateForm} />
                                <StepThree form={state.form} totalEmployees={totalEmployees} hashKey={'EconomicStep!'} 
                                           update={updateForm}/>
                              </StepWizard>
                        </div>
                    </section>
                </div>
            </section>
        </article>
    )
}

export default Main;