import React from 'react';

import { authentication } from '../Firebase';
import { withRouter } from 'react-router-dom';
import AuthUserContext from './context';

import * as ROUTES from '../../constants/routes';
import * as CONSTANTS from '../../constants';

const withAuthorization = condition => Component => {

    class WithAuthorization extends React.Component {

        componentDidMount() {
    
            this.listener = authentication.onAuthStateChanged(
                authUser => {
                    if (!condition(authUser)) {
                      
                        document.location.pathname.includes(CONSTANTS.PATH_USER) ?
                            this.props.history.push(ROUTES.USER_SIGNIN) :
                            this.props.history.push(ROUTES.COMPANY_SIGNIN);
                    }
                  },
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        condition(authUser) ? <Component {...this.props} /> : null
                    }
                </AuthUserContext.Consumer>);
        }
  }
 
  return withRouter(WithAuthorization);
};
 
export default withAuthorization;
