import React, { Component } from 'react';

import Header from '../App/Header';
import Main from './Main';
import Footer from '../App/Footer';

import { loadJSv2 } from '../../utils/script';

class PlansPage extends Component {

  UNSAFE_componentWillMount() {
      loadJSv2('/assets/js/main.js');
   }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.remove('landing');
    document.getElementById("header").classList.remove("alt");
  }

  render() {
    return (
        [<Header key="plansHeader"/>,<Main key="plansMain"/>,<Footer key="plansFooter"/>]
      );
  }
}


export default PlansPage;