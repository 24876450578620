import React , {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import { AuthUserContext } from '../Session';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory({forceRefresh:true});

class CompanyHeader extends Component {

    render() {
        return (
            <header id="header" className="alt">
                <h1><a href="index.html">Uncon Corporate</a></h1>
                <nav id="nav">
                    <ul>
                        <li className="special">
                            <a href="#menu" className="menuToggle"><span>Menu</span></a>
                            <div id="menu" className="company">
                                <AuthUserContext.Consumer>
                                  {authUser => authUser ?
                                     <ul>
                                        <li>Hola, {authUser.email}</li>
                                        <li><Link to={ROUTES.COMPANY_DASHBOARD} className="icon solid fa-home"> Inicio</Link> </li>
                                        <li><Link to={ROUTES.COMPANY_PROFILE} className="icon solid fa-user-cog"> Mi Perfil</Link></li>
                                        <li><Link to={ROUTES.COMPANY_ACTIONS} className="icon solid fa-lightbulb"> Mis Acciones</Link></li>
                                        <li><Link to={ROUTES.COMPANY_REPORTS} className="icon solid fa-chart-bar"> Mis informes</Link></li>
                                        <li><Link to={ROUTES.COMPANY_MANAGEMENT} className="icon solid fa-cogs"> Administración</Link></li>
                                        <li><Link to={ROUTES.SIGNOUT} className="icon solid fa-sign-out-alt"> Salir</Link></li>
                                    </ul>
                                  :
                                    <ul>
                                        <li><Link to={ROUTES.LANDING} className="icon solid fa-home"> Inicio</Link></li>
                                        <li><Link to={ROUTES.FIND_OUT} className="icon solid fa-hand-point-right"> Descubre más</Link></li>
                                        <li><Link to={ROUTES.COMPANY_SIGNIN} className="icon solid fa-sign-in-alt"> Login</Link></li>
                                        <li><Link to={ROUTES.COMPANY_SIGNUP} className="icon solid fa-user-plus"> Regístrate</Link></li>
                                        <li><Link to={ROUTES.CONTACT} className="icon solid fa-envelope"> Contacto</Link></li>
                                        <li><Link to={ROUTES.USER_SIGNIN} className="icon solid fa-sign-in-alt company"> Empleados</Link></li>
                                    </ul>
                                  }
                                </AuthUserContext.Consumer>
                            </div>
                        </li>
                    </ul>
                </nav>
            </header>
        )
    }
}

export default withRouter(CompanyHeader);