import React , {Component} from 'react';

class ReportFooter extends Component {

    render() {
        return (
            <footer>
                
            </footer>
        )
    }
}

export default ReportFooter;