import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts';

class UserCompanyIndexRadialBarChart extends Component {

    render() {

        const stateBarCompany = {
            series: [
            { 
                name: 'Default user',
                data: [0, 0, 0], 
            },
            { 
                name: 'Default company',
                data: [0, 0, 0],
            },
            ],
            options: {
                chart: {
                    id: 'compareWithCompanyBarChart',
                    type: 'bar',
                    toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: false,
                    }
                    },
                    zoom: {
                    enabled: false,
                    }
                },
                fill: {
                    colors: ['#40A0FC','#50E8A6','#EA1E2D']
                },
                grid: {
                    xaxis: {
                    lines: {
                        show: false,
                    }
                    },
                },
                plotOptions: {
                    bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                    fontSize: '12px',
                    colors: ['#fff']
                    }
                },
                xaxis: {
                    type: 'category',
                    categories: ['Ambiental', 'Social', 'Económica'],
                },
                yaxis: {
                    show: true,
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                tooltip: {
                    enabled: true,
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                },
            },
        };

         return(
           <ReactApexChart options={stateBarCompany.options} 
                            series={this.props.series} 
                            type="bar" 
                            height={310} /> 
        )
    }
}

export default UserCompanyIndexRadialBarChart