export const loadJS = (src) => {
    var ref = window.document.getElementsByTagName("script")[0];
    
    if (ref.src !== (window.location.origin + "/assets/js/main.js")) {
        var script = window.document.createElement("script");
        script.src = src;
        script.async = false;
        ref.parentNode.insertBefore(script, ref);
    }
}


export const loadJSv2 = (src) => {

    let found = false;
    let ref = window.document.getElementsByTagName("script")[0];
    let array = Array.prototype.slice.call(window.document.getElementsByTagName("script"));

    array.forEach(element => {
        if (element.src === (window.location.origin + "/assets/js/main.js")) {
            found = true;
        }
    });

    if (!found) {
        var script = window.document.createElement("script");
        script.src = src;
        script.async = false;
        ref.parentNode.insertBefore(script, ref);
    }
}