import React from 'react';

const Modal = ({ handleClose, handleAction, valueAction, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <section className="wrapper style5 modal-wrapper">
            <div className="row">
                <div className="col-12 col-12-medium">
                    {children}
                </div>
            </div>
            <hr className="minor"/>
            <div className="col-12 col-12-medium">
                <ul className="actions">
                    <li>
                        <input type="submit" value={valueAction} onClick={handleAction} className="primary small"></input>
                    </li>
                    <li>
                        <input type="submit" value="Cerrar" onClick={handleClose} className="small"></input>
                    </li>
                </ul>
            </div>
        </section>
      </section>
    </div>
  );
};

export default Modal;