import React, { Component } from 'react';

class Main extends Component {
    render() {
        return(
            <article id="main">
                <section className="wrapper style5">
                    <div className="inner">
                        <ul className="icons major">
                            <li><span className="icon solid fa-road major style5"><span className="label">Contacta con nosostros</span></span></li>
                            <li><b>Vincúlate a uno de nuestros <a className="greenlink" href="/plans">planes</a> para empresas</b></li>
                        </ul>
                        <ul className="icons major">
                            <li>Una vez formes parte del programa, nos encargaremos de dar de alta a tus administradores (plan <span className="greenlink">&nbsp;Profesional</span> 
                                y <span className="greenlink">&nbsp;Premium</span>), que serán los encargados de 
                                iteracccionar con el servicio.
                            </li>
                        </ul>
                        <ul className="icons major">
                            <li><span className="icon solid fa-cogs major style5"><span className="label">Ipsum</span></span></li>
                            <li><b>Te ayudamos en todo momento</b></li>
                        </ul>
                        <ul className="icons major">
                            <li>
                                Nosotros daremos de alta a los empleados y nos integraremos con los sistemas de autenticación de tu compañía (sólo plan <span className="greenlink">Profesional</span> y <span className="greenlink">Premium</span>).
                            </li>
                        </ul>
                        <ul className="icons major">
                            <li><span className="icon solid fa-users major style5"><span className="label">Ipsum</span></span></li>
                            <li><b>Conoce la sostenbilidad de tus empleados</b></li>
                        </ul>
                        <ul className="icons major">
                            <li>
                                Los empleados cumplimentarán su nivel de sosteniblidad, lo que te permitirá conocer el nivel de sostenibilidad
                                de tu empresa y compararlo con tus competidores (plan <span className="greenlink">Profesional</span> y <span className="greenlink">Premium</span>).
                            </li>
                        </ul>
                        <ul className="icons major">
                            <li><span className="icon brands fa-sellsy major style5"><span className="label">Ipsum</span></span></li>
                            <li><b>Tendrás informes avanzados con tu situación y la de la competencia</b></li>
                        </ul>
                        <ul className="icons major">
                            <li>
                                Conocer el impacto de las acciones llevadas a cabo por tus empleados, te permitirán tener una visión
                                360 de la sostenibilidad real de tu compañía. Podrás compartir en redes sociales y generar todo tipo de 
                                informes, tanto tuyos como relacionado con la competencia (plan <span className="greenlink">Profesional</span> y <span className="greenlink">Premium</span>).
                            </li>
                        </ul>
                        <ul className="icons major">
                            <li><span className="icon brands fa-envira major style5"><span className="label">Ipsum</span></span></li>
                            <li><b>Aumenta tu sostenibilidad</b></li>
                        </ul>
                        <ul className="icons major">
                            <li>
                                Ofrecemos las herramientas necesarias a los empleados para aumentar su nivel sostenible y mejorar su impacto.
                                A su vez, motívalos alineando esa mejora con sus objetivos.
                            </li>
                        </ul>
                    </div>
                </section>
            </article>
         );
    }
}
    
export default Main;