import { useSetRecoilState } from "recoil";

import { history, useFetchWrapper} from '../helpers';
import { authAtom } from "../state";

export { usePillarService };

const usePillarService = () => {
    const baseUrl = `${process.env.REACT_APP_API_URL}/pillars`;
    const fetchWrapper = useFetchWrapper();

    return {
        getAll,
        getById,
    }

    function getAll() {
         return fetchWrapper.get(baseUrl);
    }

    function getById(pillarId) {

        return fetchWrapper.get(`${baseUrl}/${pillarId}`);
    }
}