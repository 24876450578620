import { useFetchWrapper } from "../helpers";

const useEmployeeRatingService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/employee-ratings`;
    const fetchWrapper = useFetchWrapper();

    return {
        create,
        getByEmployeeId,
        update,
    }

    function create(employeeRating) {
        return fetchWrapper.post(baseUrl,employeeRating);
    }

    function getByEmployeeId(employeeId) {
        return fetchWrapper.get(`${baseUrl}/${employeeId}`);
    }

    function update(employeeId,employeeRating) {
        return fetchWrapper.patch(`${baseUrl}/${employeeId}`,employeeRating);
    }
}

export { useEmployeeRatingService};
