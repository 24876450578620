import React, { Component } from 'react';
import { Link } from 'react-router-dom';
 
import { authentication } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';

import { Alert } from 'reactstrap';
 
const Main = () => (
    <PasswordForgetForm />
);
 
const INITIAL_STATE = {
  email: '',
  error: null,
};
 
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email } = this.state;
    authentication
      .passwordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, error } = this.state;
 
    const isInvalid = email === '';
 
    return (
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    <section id="main" className="container">
                        <div className="box">
                            {error && <Alert color="danger">{error.message}</Alert>}
                            <form onSubmit={this.onSubmit}>
                                <div className="row gtr-50 gtr-uniform">
                                    <div className="col-6 col-12-mobilep">
                                        <input
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder="Email Address"
                                        />
                                    </div>
                                    <div className="col-6 col-12-mobilep">
                                        <input className="primary" disabled={isInvalid} type="submit" value="Resetear password" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </section>
        </article>

    );
  }
}
 
const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.USER_PASSWORD_FORGET}>Has olvidado la contraseña?</Link>
  </p>
);
 
export default Main;

const PasswordForgetForm = PasswordForgetFormBase;
 
export { PasswordForgetForm, PasswordForgetLink };