import React, { useState } from 'react';
import Stats from './Stats';
import { Alert } from 'reactstrap';
import * as FORM_RATING from '../../../constants/database'


const StepOne = props => {

	const [showAuxiliar, setShowAuxiliar] = useState(false)

    return (
        <div>
            <SectionSocial update={props.update} setShowAuxiliar={setShowAuxiliar} state={props.form}/>
			{ props.form[FORM_RATING.socialP2] ? <SectionSocialAux update={props.update} state={props.form} /> : null }
           
            <Stats step={1} {...props} />
        </div>
    );
};

export default StepOne;

const SectionSocial = (props) => {

	const resetAux = (e) => {
		props.update(e.target.name, false)
		props.update(FORM_RATING.socialP2AuxEconomicLess,false);
		props.update(FORM_RATING.socialP2AuxEconomicMore,false);
		props.update(FORM_RATING.socialP2AuxWorkLess, false);
		props.update(FORM_RATING.socialP2AuxWorkMore, false);
	}

	const update = (e) => {
		e.target.id === 'social-p2-yes' ? props.update(e.target.name, true) : resetAux(e);
        e.target.id === 'social-p2-yes' ? props.setShowAuxiliar(true) : props.setShowAuxiliar(false);
    };

    return (
        <section>
                <div className="row row-form-multistep">
					{!!JSON.parse(localStorage.getItem('employee')).filledRatingForm  && <Alert color="warning">El cuestionario ya ha sido respondido. Desde aquí únicamente podrás 
                    modificar tus áreas de interés. Para seguir progresando en tu rating de sostenbilidad, 
                    puedes hacerlo a través de tus <a href="/">acciones personalizadas</a> </Alert>}
                    <div className="col-12 col-12-small">
                        <label>
                            ¿Colaboras con alguna asociación sin ánimo de lucro? <p className="label-min"> Asociación de cualquier tamaño, desde agrupación vecinal hasta una ONG internacional, 
                            aportando dinero y/o como voluntario</p>
                        </label>
                    </div>
					<div className="col-4 col-4-small">
                        <input type='radio' className='form-control' name='social-p2' id='social-p2-yes'
                            onChange={update} checked={props.state[FORM_RATING.socialP2] ? 1: 0} />
                        <label htmlFor="social-p2-yes">Si</label>
                    </div>
                    <div className="col-4 col-4-small">
                        <input type='radio' className='form-control' name='social-p2' id='social-p2-no'
                            onChange={update} checked={props.state[FORM_RATING.socialP2] ? 0: 1}/>
                        <label htmlFor="social-p2-no">No</label>
                    </div>
					
                </div>
        </section>
    );
};

const SectionSocialAux = (props) => {

	const updateEconomicLess = (e) => {
		props.update(FORM_RATING.socialP2AuxEconomicLess, true)
		props.update(FORM_RATING.socialP2AuxEconomicMore, false)
	};

	const updateEconomicMore = (e) => {
		 props.update(FORM_RATING.socialP2AuxEconomicMore, true)
		 props.update(FORM_RATING.socialP2AuxEconomicLess, false)
    };

	const updateWorkLess = (e) => {
		props.update(FORM_RATING.socialP2AuxWorkLess, true)
		props.update(FORM_RATING.socialP2AuxWorkMore, false)
	};

	const updateWorkMore = (e) => {
		props.update(FORM_RATING.socialP2AuxWorkMore, true)
		props.update(FORM_RATING.socialP2AuxWorkLess, false)
	};

	return(
		<section>
			<div className="row row-form-multistep">
				<div className="col-12 col-12-small">
					<label>
						¿Qué tipo de ayuda y cuál es la aportación aproximada?
					</label>
				</div>
				<div className="col-6 col-12-small">
					<input type='radio' className='form-control' name='social-p2-economic' id='social-p2-aux-economic-less'
						onChange={updateEconomicLess} checked={props.state[FORM_RATING.socialP2AuxEconomicLess] ? 1: 0}/>
					<label htmlFor="social-p2-aux-economic-less">Económica inferior a 10€/mes</label>
				</div>
				<div className="col-6 col-12-small">
					<input type='radio' className='form-control' name='social-p2-economic' id='social-p2-aux-economic-more'
						onChange={updateEconomicMore} checked={props.state[FORM_RATING.socialP2AuxEconomicMore] ? 1: 0}/>
					<label htmlFor="social-p2-aux-economic-more">Económica superior a 10€/mes</label>
				</div>
				<div className="col-6 col-12-small">
					<input type='radio' className='form-control' name='social-p2-work' id='social-p2-aux-work-less'
						onChange={updateWorkLess} checked={props.state[FORM_RATING.socialP2AuxWorkLess] ? 1: 0}/>
					<label htmlFor="social-p2-aux-work-less">Laboral menos de 5 horas al año</label>
				</div>
				<div className="col-6 col-12-small">
					<input type='radio' className='form-control' name='social-p2-work' id='social-p2-aux-work-more'
						onChange={updateWorkMore} checked={props.state[FORM_RATING.socialP2AuxWorkMore] ? 1: 0}/>
					<label htmlFor="social-p2-aux-work-more">Laboral más de 5 horas al año</label>
				</div>
			</div>
		</section>
	);
}