import React, { useEffect, useState } from 'react';
import AuthUserContext from './context';
import { authentication } from '../Firebase';

const withAuthentication = Component => {

    const WithAuthentication = (props) => {

        const [authUser,setAuthUser] = useState(null);

        useEffect( () => {
            authentication.onAuthStateChanged( authUserResponse => {
                if (authUserResponse) {
                  setAuthUser(authUserResponse);
                   
                } else {
                  setAuthUser(null);
                  localStorage.removeItem('employee');
                  localStorage.removeItem('user');
                  localStorage.removeItem('companyid');
                  localStorage.removeItem('company');
                }
            });
        }, []);

        return (
              <AuthUserContext.Provider value={authUser}>                
                  <Component {...props} />            
              </AuthUserContext.Provider>
        );
    }

    return WithAuthentication;
}

export default withAuthentication;