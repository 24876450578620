import React, { Component } from 'react';

import CompanyHeader from '../../App/CompanyHeader';
import Footer from '../../App/Footer';

import PasswordChangeForm from '../../PasswordChange';

import { loadJSv2 } from '../../../utils/script';

class CompanyProfilePage extends Component {

    UNSAFE_componentWillMount() {
        loadJSv2('/assets/js/main.js');
      }
    
    componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.remove('landing');
    document.getElementById("header").classList.remove("alt");
    }

    render() {
    
        return (
          [<CompanyHeader key="companyHeader"/>,<PasswordChangeForm key="accountPasswordChangeForm"/>,<Footer key="companyFooter"/>]
        );
      }
}

export default CompanyProfilePage;