import React from 'react';
import PlanCard from '../App/PlanCard'

const Main = () => {

    const defaultCardBasic = {
        header: {
          longTitle: 'Basic',
          subTitle: '0 €',
        },
        image: {
          src: '/images/personal.png',
        },
        description: [
          { 
            icon: 'fa-spinner',
            text: 'Self-management',
            super: '',
          },
          {
            icon: 'fa-spinner',
            text: 'Compartir en redes sociales',
            super: '',
          },
          {
            icon: 'fa-spinner',
            text: 'Informes básicos',
            super: '1',
          }
        ],
        action: {
          title: 'Únete',
          description: 'Basic',
          to: '/company/signup'
        },
        moreInfo: [
          { 
            index: '1',
            text: 'Consigue infomes avanzados contratando el Plan Professional',
          }
        ]
      }

      const defaultCardProfessional = {
        header: {
          longTitle: 'Professional',
          subTitle: 'Contacta con nosotros',
        },
        image: {
          src: '/images/business.jpg',
        },
        description: [
            { 
              icon: 'fa-spinner',
              text: 'Te ayudamos en tus gestiones',
              super: '',
            },
            {
              icon: 'fa-spinner',
              text: 'Compartir en redes sociales',
              super: '',
            },
            {
              icon: 'fa-spinner',
              text: 'Añadir nuevas acciones de mejora para tus empleados',
              super: '', 
            },
            {
              icon: 'fa-spinner',
              text: 'Informes avanzados' ,
              super: '',
            },
            {
              icon: 'fa-spinner',
              text: 'Comparativa entre tu empresa y la competencia',
              super: '1', 
            }
        ],
        action: {
          title: 'Más información',
          description: 'Profesional',
          to: '/contact'
        },
        moreInfo: [
          { 
            index: '1',
            text: 'Algunas empresas de tu tamaño y sector. Consigue más información contratando el Plan Premium',
          }
        ]
      }
    
      const defaultCardPremium = {
        header: {
          longTitle: 'Premium',
          subTitle: 'Contacta con nosotros',
        },
        image: {
          src: '/images/corporate.jpg',
        },
        description: [
            { 
              icon: 'fa-spinner',
              text: 'Te ayudamos en tus gestiones',
              super: '',
            },
            {
              icon: 'fa-spinner',
              text: 'Compartir en redes sociales',
              super: '',
            },
            {
              icon: 'fa-spinner',
              text: 'Añadir nuevas acciones de mejora para tus empleados',
              super: '', 
            },
            {
              icon: 'fa-spinner',
              text: 'Informes corporativos',
              super: '', 
            },
            {
              icon: 'fa-spinner',
              text: 'Integración con suites corporativas y de terceros',
              super: '', 
            },
            {
              icon: 'fa-spinner',
              text: 'Comparativa entre tu empresa y la competencia',
              super: '', 
            }
        ],
        action: {
          title: 'Más información',
          description: 'Premium',
          to: '/contact'
        },
        moreInfo: []
      }
    return(
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                <section className="dashboard">
                    <div className="row">
                      <div className="col-4 col-12-medium">
                        <PlanCard cardData={ defaultCardBasic } />
                      </div>
                      <div className="col-4 col-12-medium">
                        <PlanCard cardData={ defaultCardProfessional } />
                      </div>
                      <div className="col-4 col-12-medium">
                        <PlanCard cardData={ defaultCardPremium } />
                      </div>
                    </div>  
                  </section>
                </div>
            </section>
        </article>
        );
    
}
    
export default Main;