import React, { Component } from 'react';

import Header from '../../App/Header';
import Footer from '../../App/Footer';
import Main from './Main';

import { loadJSv2 } from '../../../utils/script';

class CompanySignInPage extends Component {
  
  UNSAFE_componentWillMount() {
    loadJSv2('/assets/js/main.js');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.remove('landing');
    document.getElementById("header").classList.remove("alt");
  }

  render() {
    
    return (
      [<Header key="signInCompanyHeader"/>,<Main key="signInCompanyMain"/>,<Footer key="signInCompanyFooter"/>]
    );
  }
}

export default CompanySignInPage;