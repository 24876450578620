import React, { Component } from 'react';
 
import { authentication } from '../Firebase';

import { Alert } from 'reactstrap';

import { withAuthorization } from '../Session';
import { withRouter }  from 'react-router-dom';
 
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {

  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;
    
    authentication
      .passwordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
 
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
 
    return (
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    <section id="main" className="container">
                        <div className="box">
                            {error && <Alert color="danger">{error.message}</Alert>}
                            <form onSubmit={this.onSubmit}>
                                <div className="row gtr-50 gtr-uniform">
                                    <div className="col-6 col-12-mobilep">
                                        <input
                                        name="passwordOne"
                                        value={passwordOne}
                                        onChange={this.onChange}
                                        type="password"
                                        placeholder="New Password"
                                        />
                                    </div>
                                    <div className="col-6 col-12-mobilep">
                                        <input
                                        name="passwordTwo"
                                        value={passwordTwo}
                                        onChange={this.onChange}
                                        type="password"
                                        placeholder="Confirm New Password"
                                        />
                                    </div>
                                    <div className="col-6 col-12-mobilep">
                                        <input className="primary" disabled={isInvalid} type="submit" value="Cambiar Contraseña" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </section>
        </article>
        
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withRouter(PasswordChangeForm));