import { useFetchWrapper } from "../helpers";

const useCompanyIndexService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/company-indexes`;
    const fetchWrapper = useFetchWrapper();

    return {
        post,
        getByCompanyId,
        calculateCompanyIndexFromEmployeeIndex,
    }

    function post(companyIndex) {
        return fetchWrapper.post(baseUrl,companyIndex);
    }

    function getByCompanyId(companyId) {
        return fetchWrapper.get(`${baseUrl}/${companyId}`);
    }

    function calculateCompanyIndexFromEmployeeIndex(companyId, companyIndexFromEmployeeIndex) {
        return fetchWrapper.patch(`${baseUrl}/${companyId}/calculate`,companyIndexFromEmployeeIndex);
    }
}

export { useCompanyIndexService};
