import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { useActionService } from '../../../services/action.service';
import { useEmployeeRatingService } from '../../../services/employeeRating.service';

import Modal from '../../App/Modal';

const Main = (props) => {

    const actionService = useActionService();
    const employeeRatingService = useEmployeeRatingService();

    const [pilar, setPilar] = useState(props.location.state ? props.location.state.pilar : null);
    const [employeeRating,setEmployeeRating] = useState(null);
    const [falseActions, setFalseActions] = useState(null);
    const [basicActions, setBasicActions] = useState(null);
    const [pilarActions, setPilarActions] = useState(null);
    const [allActions, setAllActions] = useState(null);

    const defaultActions = [{  
        title: 'Título',
        description: 'Loading...',
        key: 'action-key',
        type: 'action-type',
        typeInitial: 'A',
        evidence: false
       }]

    const defaultBasicActions = [{
        title: 'Basic Action',
        description: 'descripción',
        key: 'action-key',
        type: 'basic',
        typeInitial: 'A',
        evidence: false,
    }]
    
    const getEmployeeRating = (employee) => {
        employeeRatingService.getByEmployeeId(employee.employeeId)
            .then(data => setEmployeeRating(data.employeeRating));
    }
   
    const getActions = (pillar) => {
        actionService.getByPillar(pillar)
            .then(data => setPilarActions(data.actions));
    }

    const getAllActions = () => {
        actionService.getAll()
            .then(data => setAllActions(data.actions));
    }

    useEffect( () => {
        const employee = JSON.parse(localStorage.getItem('employee'));
        getEmployeeRating(employee);
        pilar ? getActions(pilar) : getAllActions();
      }, [])
    
    return (
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    { !employeeRating || !falseActions ? <BasicActions data={falseActions || defaultBasicActions} key="basicACtions" />: null }
                    <hr className="minor"></hr>
                    { !pilar ? <AllActions actions={allActions || defaultActions} key="allActions"/>: <PilarActions pilar={pilar} actions={pilarActions || defaultActions}/> }
                </div>
            </section>
        </article>
    )
}

export default withRouter(Main);

const BasicActions = (props) => {

    const actions = props.data;
    return(
        <section id="main" className="container">
            <h4 className="">ACCIONES BÁSICAS</h4> 
            <div className="row">
            {actions.map( action => (
                <div className="col-12 col-12-medium">
                    <div className="card"  key={action.key}>
                        <h5 className="green-header">{action.title}</h5>
                        <p>{action.description}</p>
                        <ul className="actions" key="actionBasicAction">
                            <li key="acceptBasic"><input type="submit" value="Aceptar" className="primary small"/></li>
                            <li key="declineBasic"><input type="submit" value="Rechazar" className="tertiary small"/></li>
                        </ul>
                    </div>
                </div>
                 ))}
            </div>
        </section>
    );
}

const AllActions = (props) => {

    const [showModal, setShowModal] = useState(false);
    const fShowModal = () => {
        setShowModal(true);
    }
    const fHideModal = () => {
        setShowModal(false);
    }
    const actions = props.actions;
    return(
        <section id="main" className="container">
            <h4 className="green-header">ACCIONES</h4> 
            <div className="row">
                {actions.map( action => (
                <div className="col-12 col-12-medium">
                    <div className="card">
                        <div className="cardHeader-root">
                            <div className="cardHeader-avatar">
                                <div className={`avatar-root avatar-${action.type}`} aria-label="proposal">
                                    {action.typeInitial}
                                </div>
                            </div>
                            <div className="cardHeader-content">
                                <h5 className="green-header">{action.title}</h5>
                            </div>
                        </div>
                        <hr className="minor"/>
                        <p>{action.description}</p>
                        <ul className="actions">
                            <li key={`acceptAction-${action.key}`}><input type="submit" value="Aceptar" className="primary small"/></li>
                            <li key={`declineAction-${action.key}`}><input type="submit" value="Rechazar" className="tertiary small"/></li>
                        </ul>
                        {action.evidence ?
                        <span>
                            <i>* Requiere evidencia</i>&nbsp;
                            <span className="icon solid fa-plus-circle evidence" onClick={fShowModal}></span>
                        </span>
                        :
                        null }
                    </div>
                    <hr className="minor"/>
                </div>
                 ))}
            </div>
            <Modal show={showModal} handleClose={fHideModal} handleAction={fHideModal} valueAction="Adjuntar">   
                <h4>Añadir evidencia</h4>
                <p>Puedes subir una foto, imagen o documento para validar que has realizado esta acción</p>
                <textarea placeholder="Arrastra y Suelta" rows="6" spellCheck="false" disabled={true}/>         
            </Modal>
        </section>
    )
}



const PilarActions = (props) => {

    const [showModal, setShowModal] = useState(false);

    const fShowModal = () => {
        setShowModal(true);
    }

    const fHideModal = () => {
        setShowModal(false);
    }

    const actions = props.actions;

    const pilars = {
        'economic':'Económico',
        'environmental':'Ambiental',
        'social':'Social'
    };

    return(
        <section id="main" className="container">
            <h4>ACCIONES PILAR <span className={props.pilar.concat(`-header`)}>{pilars[props.pilar]}</span></h4>
            <div className="row">
                {actions.map( action => (
                <div className="col-12 col-12-medium">
                    <div className="card">
                        <h5 className="green-header">{action.title}</h5>
                        <p>{action.description}</p>
                        <ul className="actions">
                            <li key={`acceptAllAction-${action.key}`}><input type="submit" value="Aceptar" className="primary small"/></li>
                            <li key={`declineAllAction-${action.key}`}><input type="submit" value="Rechazar" className="tertiary small"/></li>
                        </ul>
                        {action.evidence ?
                        <span>
                            <i>* Requiere evidencia</i>&nbsp;
                            <span className="icon solid fa-plus-circle evidence" onClick={fShowModal}></span>
                        </span>
                        :
                        null }
                    </div>
                    <hr className="minor"/>
                </div>
                 ))}
            </div>
            <Modal show={showModal} handleClose={fHideModal} handleAction={fHideModal} valueAction="Adjuntar">   
                <h4>Añadir evidencia</h4>
                <p>Puedes subir una foto, imagen o documento para validar que has realizado esta acción</p>
                <textarea placeholder="Arrastra y Suelta" rows="6" spellCheck="false" disabled={true}/>         
            </Modal>
        </section>
    )
}