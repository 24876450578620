import { authentication } from './firebase';

export const createUserWithEmailAndPassword = (email, password) =>
    authentication.createUserWithEmailAndPassword(email, password);

export const signInWithEmailAndPassword = (email, password) =>
    authentication.signInWithEmailAndPassword(email, password);
    
export const signOut = () =>
    authentication.signOut();

export const onAuthStateChanged = (authUser) =>
    authentication.onAuthStateChanged(authUser);

export const passwordReset = (email) =>
    authentication.sendPasswordResetEmail(email);

export const passwordUpdate = (password) =>
    authentication.currentUser.updatePassword(password);