import React from 'react';
import Stats from './Stats';
import { Alert } from 'reactstrap';
import * as FORM_RATING from '../../../constants/database'

const StepZero = props => {
    return (
        <div>
            <h3 className='align-center normalText'>Hola! Nos gustaría hacerte unas sencillas preguntas</h3>
			<SectionGeneral update={props.update} state={props.form} />
            <Stats step={1} {...props} />
        </div>
    );
};

export default StepZero;

const SectionGeneral = (props) => {
    const update = (e) => {
        props.update(e.target.id, e.target.checked);
    };

	return(
		<section>
                <div className="row row-form-multistep">
                    {!!JSON.parse(localStorage.getItem('employee')).filledRatingForm  && <Alert color="warning">El cuestionario ya ha sido respondido. Desde aquí únicamente podrás 
                    modificar tus áreas de interés. Para seguir progresando en tu rating de sostenbilidad, 
                    puedes hacerlo a través de tus <a href="/">acciones personalizadas</a> </Alert> }
                    <div className="col-12 col-12-small">
                        <label>
							¿Cuáles de estas temáticas te interesan más?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='general-p1' id='general-p1-help-people'
                            onChange={update} checked={props.state[FORM_RATING.generalP1HelpPeople] ? 1: 0}/>
                        <label htmlFor="general-p1-help-people">Ayuda a personas</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='general-p1' id='general-p1-help-animals'
                            onChange={update} checked={props.state[FORM_RATING.generalP1HelpAnimals] ? 1: 0}/>
                        <label htmlFor="general-p1-help-animals">Ayuda a animales</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='general-p1' id='general-p1-help-environmental'
                            onChange={update} checked={props.state[FORM_RATING.generalP1HelpEnvironmental] ? 1: 0}/>
                        <label htmlFor="general-p1-help-environmental">Ayuda al medio ambiente</label>
                    </div>  
					<div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='general-p1' id='general-p1-health'
                            onChange={update} checked={props.state[FORM_RATING.generalP1Health] ? 1: 0}/>
                        <label htmlFor="general-p1-health">Salud y alimentación sana y sostenible</label>
                    </div>  
					<div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='general-p1' id='general-p1-consume'
                            onChange={update} checked={props.state[FORM_RATING.generalP1Consume] ? 1: 0}/>
                        <label htmlFor="general-p1-consume">Producción y consumo responsable</label>
                    </div> 
                    <div className='col-4 col-12-small'>
                        <i>
                            Esta selección nos permite crear una ruta de mejora de tu rating sostenible más personalizada y mejor adaptada a 
                            tus intereses.
                        </i>
                    </div>     
                </div>
        </section>
	);
}
