import React from 'react'

/**
 * Stats Component - to illustrate the possible functions
 * Could be used for nav buttons or overview
 */
 const Stats = ({
    nextStep,
    previousStep,
    totalSteps,
    step,
}) => (
    <div>
        <div className="row">
            <div className="col-12">
                <hr/>
            </div>
            <div className="col-6 col-12-medium">
                <ul className="actions">
            { step > 1 &&
                <li><button className='button tertiary' onClick={previousStep}>Volver</button></li>
            }
            { step < totalSteps ?
                <li><button className='button primary' onClick={nextStep}>Continuar</button></li>
                :
                <li><button className='button secondary' onClick={nextStep}>finalizar</button></li>
            }
                </ul>
            </div>
        </div>
    </div>
);

export default Stats;