import { useFetchWrapper } from "../helpers";

const useEmployeeImpactService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/employee-impacts`;
    const fetchWrapper = useFetchWrapper();

    return {
        create,
        getEmployeeImpact,
        calculateEmployeeImpactFromRating
    }

    function create(employeeImpact) {
        return fetchWrapper.post(baseUrl,employeeImpact);
    }

    function getEmployeeImpact(employeeId) {
        return fetchWrapper.get(`${baseUrl}/${employeeId}`);
    }

    function calculateEmployeeImpactFromRating(employeeId,rating) {
        return fetchWrapper.patch(`${baseUrl}/${employeeId}/calculate`,rating);
    }
}

export { useEmployeeImpactService }

