import React from 'react';

import { withAuthorization } from '../../Session';
import { withRouter, Link }  from 'react-router-dom';

import { history } from '../../App/CompanyHeader';

import * as ROUTES from '../../../constants/routes';

const Main = () => {

    const handleSumbitBasicReports = () => {
        history.push(ROUTES.COMPANY_BASIC_REPORTS);
    }

    const handleSumbitProfessionalReports = () => {
        history.push(ROUTES.COMPANY_PROFESSIONAL_REPORTS);
    }

    const handleSumbitPremiumReports = () => {
        history.push(ROUTES.COMPANY_PREMIUM_REPORTS);
    }

    return(
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <div className="row">
                            <div className="col-12 col-12-mobilep">
                                <h3 className="medium">Informes</h3>  
                                <h5 className="medium">Visualiza los informes de tu empresa</h5>
                            </div>
                        </div>
                    </section>
                    <hr className="minor"/>
                    <section id="main" className="container">
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Informes Basic</h5>
                                    <p>
                                        <span className={`icon solid fa-spinner`}/> Número de empleados que están utilizando la plataforma<br/>
                                        <span className={`icon solid fa-spinner`}/> Rating sostenible actual y comparación con el resto de las compañías<br/>
                                        <span className={`icon solid fa-spinner`}/> Histórico del rating sostenible de la compañía<br/>
                                        <span className={`icon solid fa-spinner`}/> Impacto acumulado actual de la compañía<br/>
                                        <span className={`icon solid fa-spinner`}/> Histórico del impacto de la compañía (<i>próximamente</i>)
                                    </p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="acceptBasic"><input type="submit" value="Acceder" className="primary small" onClick={handleSumbitBasicReports}/></li>
                                    </ul>
                                    <ul className='actions'>
                                        <li><Link to={ROUTES.ENGAGE_PLAN}>Contrata ahora el plan <b>Proffesional</b> o <b>Premium</b></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Informes Professional</h5>
                                    <p>
                                        <span className={`icon solid fa-spinner`}/> Número de empleados que están utilizando la plataforma por departamentos<br/>
                                        <span className={`icon solid fa-spinner`}/> Rating sostenible actual y comparación con el resto de las compañías por sectores<br/>
                                        <span className={`icon solid fa-spinner`}/> Histórico del rating sostenible de la compañía<br/>
                                        <span className={`icon solid fa-spinner`}/> Impacto acumulado actual de la compañía y comparativa con la media de impacto del resto de compañías<br/>
                                        <span className={`icon solid fa-spinner`}/> Histórico del impacto de la compañía (<i>próximamente</i>)
                                    </p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="acceptBasic"><input type="submit" value="Acceder" className="primary small" disabled onClick={handleSumbitProfessionalReports}/></li>
                                    </ul>
                                    <ul className='actions'>
                                        <li><Link to={ROUTES.ENGAGE_PLAN}>Contrata ahora el plan <b>Premium</b></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Informes Premium</h5>
                                    <p>
                                        <span className={`icon solid fa-spinner`}/> Número de empleados que están utilizando la plataforma por departamentos<br/>
                                        <span className={`icon solid fa-spinner`}/> Rating sostenible actual y comparación con el resto de las compañías por sectores<br/>
                                        <span className={`icon solid fa-spinner`}/> Histórico del rating sostenible de la compañía<br/>
                                        <span className={`icon solid fa-spinner`}/> Impacto acumulado actual de la compañía y comparativa con la media de impacto del resto de compañías<br/>
                                        <span className={`icon solid fa-spinner`}/> Histórico del impacto de la compañía (<i>próximamente</i>)
                                    </p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="acceptBasic"><input type="submit" value="Acceder" className="primary small" disabled onClick={handleSumbitPremiumReports}/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </article>
    );
}


const condition = authUser => !!authUser;  

export default withAuthorization(condition)(withRouter(Main));
