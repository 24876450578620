import { useFetchWrapper } from "../helpers";

const useCompanyAdminService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/company-admins`;
    const fetchWrapper = useFetchWrapper();

    return {
        post,
        getByUid,
        getByCompanyId
    }

    function post(companyAdmin) {
        return fetchWrapper.post(baseUrl,companyAdmin);
    }

    function getByUid(uid) {
        return fetchWrapper.get(`${baseUrl}/${uid}`);
    }

    function getByCompanyId(companyId) {
        return fetchWrapper.get(`${baseUrl}?companyId=${companyId}`);
    }
}

export { useCompanyAdminService };
