import React from 'react';

const   Impact = ({impacts}) => {

    return(
    <ul className="alt">
        {impacts.map( (impact, index) => (
            <li key={index}>
                <i className={`icon solid ${impact.icon}`}/> {impact.name} <b>{Math.round(impact.value)}</b> {impact.measure}
            </li>
        ))}
    </ul> )
}

export default Impact;
