import { useFetchWrapper } from "../helpers";

const useHistoricalCompanyIndexService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/historical-company-indexes`;
    const fetchWrapper = useFetchWrapper();

    return {
        post,
        getByCompanyId,
        getByYear,
        update
    }

    function post(companyIndex) {
        return fetchWrapper.post(baseUrl,companyIndex);
    }

    function getByYear(companyId,year) {
        return fetchWrapper.get(`${baseUrl}/${companyId}?year=${year}`);
    }

    function getByCompanyId(companyId) {
        return fetchWrapper.get(`${baseUrl}/${companyId}`);
    }

    function update(companyId, historicalCompanyIndex) {
        return fetchWrapper.patch(`${baseUrl}/${companyId}`,historicalCompanyIndex);
    }
}

export { useHistoricalCompanyIndexService};
