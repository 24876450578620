import React , {Component} from 'react';
import { withRouter } from 'react-router-dom';

import {createBrowserHistory} from 'history';

export const history = createBrowserHistory({forceRefresh:true});

class ReportHeader extends Component {

    render() {
        return (
            <header id="header">
                <h1><a href="index.html">Uncon Corp</a></h1>
            </header>
        )
    }
}

export default withRouter(ReportHeader);