import { useFetchWrapper } from "../helpers";

const useEmployeeService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/employees`;
    const fetchWrapper = useFetchWrapper();

    return {
        post,
        getByCompanyId,
        getRatingFormFilledByCompany,
        getByUid,
        updateEmployee,
    }

    function post(employee) {
        return fetchWrapper.post(baseUrl,employee);
    }

    function getByCompanyId(companyId) {
        return fetchWrapper.get(`${baseUrl}?companyId=${companyId}`);
    }

    function getRatingFormFilledByCompany(companyId) {
        return fetchWrapper.get(`${baseUrl}/${companyId}/rating-form-filled`);
    }

    function getByUid(uid) {
        return fetchWrapper.get(`${baseUrl}?uid=${uid}`);
    }

    function updateEmployee(employee) {
        return fetchWrapper.patch(`${baseUrl}/${employee.employeeId}`, employee);
    }
}

export { useEmployeeService};
