import { useFetchWrapper } from "../helpers";

const useImprovementActionService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/improvement-action`;
    const fetchWrapper = useFetchWrapper();

    return {
        getActualImprovementAction,
        createNewImprovementAction,
        doImprovementAction,
        hiddenImprovementAction,
        rejectImprovementAction
    }

    function getActualImprovementAction(employeeId) {
        return fetchWrapper.get(`${baseUrl}/${employeeId}`);
    }

    function createNewImprovementAction(improvementAction) {
        return fetchWrapper.post(`${baseUrl}`,improvementAction);
    }

    function doImprovementAction(improvementAction) {
        return fetchWrapper.patch(`${baseUrl}/${improvementAction.improvementActionId}/do-actual`);
    }

    function hiddenImprovementAction(improvementAction) {
        return fetchWrapper.patch(`${baseUrl}/${improvementAction.improvementActionId}/hidden`);
    }

    function rejectImprovementAction(improvementAction) {
        return fetchWrapper.patch(`${baseUrl}/${improvementAction.improvementActionId}/reject-actual`);
    }
}

export { useImprovementActionService };

