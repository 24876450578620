import React, {Component} from 'react';
import { Link } from 'react-router-dom';

const PlanCardHeader = (props) => {
   
    const header = props.cardData.header
    
    return (
        <div className="planCardHeader-root">
            <div className="planCardHeader-title">
                <b>{header.longTitle}</b>
            </div>
            <div className="planCardHeader-subtitle">
                {header.subTitle}
            </div>
        </div>
    )
}

const PlanCardImage = (props) => {

    const image = props.cardData.image

    return(
        <span className="image fit  planCard-image">
            <img src={image.src} alt="" />
        </span>
    )
}

const PlanCardDescription = (props) => {
    
    const descriptions = props.cardData.description.map((description,index) => {
        return(
            <li className={`icon solid ${description.icon}`} key={index}>&nbsp;{description.text}&nbsp;<sup>{description.super}</sup></li>
        )
    })

    return (
        [<ul key="impactsImpact" className="alt">{descriptions}</ul>]
    )
}

const PlanCardAction = (props) => {
    
    const action = props.cardData.action

    

    return(
        [<ul className="actions" key="actionAction">
             <li><Link to={action.to} className="button primary">{action.title} </Link></li>
         </ul>]
    )
}

const PlanCardMoreInfo = (props) => {
    
    const moreInfos = props.cardData.moreInfo.map((moreInfo,index) => {
        return(
            <li key={index} className="planCard-moreInfo"><sup>{moreInfo.index}</sup>&nbsp;{moreInfo.text}</li>
        )
    })

    return (
        [<ul key="impactsImpact" className="alt">{moreInfos}</ul>]
    )
}

class PlanCard extends Component {
    
    render() {

        const {cardData} = this.props;
       
        return(
            <div className="planCard">
                <PlanCardHeader cardData={cardData}/>
                <PlanCardImage cardData={cardData}/>
                <PlanCardDescription cardData={cardData}/>
                <PlanCardAction cardData={cardData}/>
                <PlanCardMoreInfo cardData={cardData}/>
          </div>
        )
    }
}

export default PlanCard