import * as structures from '../constants/inout';

export function userAuthMapper(userAuth, accessToken) {
    const mapped = (!userAuth) ? null :
        {
            displayName: userAuth.displayName,
            email: userAuth.email,
            emailVerified: userAuth.emailVerified,
            phoneNumber: userAuth.phoneNumber || '',
            accessToken: accessToken,
            refreshToken: userAuth.refreshToken,
            uid: userAuth.uid,
        }
    return mapped;
}

export function userMapper(user) {
    
    const mapped = (!user) ? null :
         {
            displayName: user.displayName || '',
            email: user.email || '',
            emailVerified: user.emailVerified,
            phoneNumber: user.phoneNumber || '',
            accessToken: (!user.stsTokenManager) ? '' : user.stsTokenManager.accessToken,
            refreshToken: (!user.stsTokenManager) ? '' : user.stsTokenManager.refreshToken,
            uid: user.uid,
        }
        return mapped;
}

export function employeeMapper(companyId, companyName, email, lastName, name, filledRatingForm) {

    return {
        
        companyId: companyId,
        companyName: companyName,
        email: email,
        lastName: lastName,
        name: name,
        filledRatingForm: filledRatingForm
    }
}

export function companyBasicMapper(companyId, companyCode, companyName) {
    return {
        companyId: companyId,
        companyCode: companyCode,
        companyName: companyName,
    }
}

export function companyMapper(email, uid, data) {

    const admin = {
        email: email,
        position: data.position,
        uid: uid
    };

    return {
        address: data.address,
        address2: data.address2 || '',
        admin: admin,
        city: data.city,
        companyCode: data.companyCode,
        companyName: data.companyName,
        country: data.country,
        hasPayment: data.hasPayment || false,
        phoneNumber: data.phoneNumber,
        plan: data.plan || 'basic',
        postalCode: data.postalCode,
        programInEmployees: data.programInEmployees || 0,
        state: data.state,
        totalEmployees: parseInt(data.totalEmployees,10),
        urlLogo: data.urlLogo || null,
    }
}

export function companyAdminMapperWithFullName(email, companyId, companyName, companyCode, name, lastName) {
    return {
        email: email,
        companyId: companyId,
        companyName: companyName,
        companyCode: companyCode,
        name: name || '',
        lastName: lastName || ''
    }
}

export function companyAdminMapperWithoutName(uid, email, companyId, companyName, companyCode) {
    return {
        uid: uid,
        email: email,
        companyId: companyId,
        companyName: companyName,
        companyCode: companyCode,
        name: '',
        lastName: ''
    }
}

export function companyIdMapper(companyId) {
    return {
        companyId: companyId,
    }
}


export function companyIndexMapper(company) {
    return {
        companyId: company.companyId,
    }
}

export function companyImpactMapper(company) {
    return {
        companyId: company.companyId,
    }
}

export function historicalCompanyIndexMapper(company, year, indexMonths) {
    return {
        companyId: company.companyId,
        year: year,
        months: indexMonths,
    }
}

export function employeeIdMapper(employeeId) {
    return {
        employeeId: employeeId,
    }
}

export function employeeImpactMapper(employeeImpact) {
    return {
        employeeId: employeeImpact.employeeId,
        animal: employeeImpact.animal,
        co2: employeeImpact.co2,
        health: employeeImpact.health,
        money: employeeImpact.money,
        people: employeeImpact.people,
        tax: employeeImpact.tax,
        water: employeeImpact.water
    }
}

export function employeeIndexMapper(economic,environmental,social) {
    return {
        economic: economic,
        environmental: environmental,
        social: social,
    }
}

export function contactMapper(email,name,message,subject) {
    return {
        email: email,
        name: name,
        message: message,
        subject: subject
    }
}

export function employeeRatingMapper(form) {
    
    return {
        form: form
    }
}

export function companyIndexFromEmployeeIndexMapper(employeeIndex,totalEmployees,employeeId) {
    return {
        employeeIndex: employeeIndex,
        totalEmployees: totalEmployees,
        employeeId: employeeId
    }
}

export function updateHistoricalCompanyIndexMapper(year, month, companyIndex) {

    let months = {};
    let data = {
        economic: companyIndex.economic,
        environmental: companyIndex.environmental,
        social: companyIndex.social
    }

    months[`${month}`] = data;
    return {
        year: year,
        months: months
    }
}

export function employeeRatingDefaultMapper(employeeId) {
    return {
        employeeId: employeeId,
        form: structures.defaultEmployeeRatingForm
    }
}

export function improvementActionMapper(employeeId, actionId, order) {
    return {
        employeeId: employeeId,
        actionId: actionId,
        order: order,
    }
}

export function actionProposalMapper(employeeId, actions) {
    return {
        employeeId: employeeId,
        actions: actions,
    }
}