import React, { useEffect, useState } from 'react';

import { withAuthorization } from '../../../Session';
import { withRouter }  from 'react-router-dom';
import { Alert } from 'reactstrap';
import { Modal } from 'react-responsive-modal';

import { useCompanyService } from '../../../../services/company.service';
import { useEmployeeService } from '../../../../services/employee.service';
import { useEmployeeRatingService } from '../../../../services/employeeRating.service';

import { useCompanyIndexService } from '../../../../services/companyIndex.service';
import { useCompanyImpactService } from '../../../../services/companyImpact.service';
import { useHistoricalCompanyIndexService } from '../../../../services/historicalCompanyIndex.service';
import { useTotalCompaniesIndexesService } from '../../../../services/totalCompaniesIndexes.service';

import { percentageUnconEmployees } from '../../../../utils';


import { EmployeesChart, EmployeePreferencesChart, CompanyIndexChart, HistoricalCompanyIndexChart, ImpactCompanyChart } from '../../../Chart/Reports';


const Main = () => {

    const companyService = useCompanyService();
    const employeeService = useEmployeeService();
    const employeeRatingService = useEmployeeRatingService();
    const companyIndexService = useCompanyIndexService();
    const totalCompaniesIndexesService = useTotalCompaniesIndexesService();

    const companyImpactService = useCompanyImpactService();
    const historicalCompanyIndexService = useHistoricalCompanyIndexService();
    
    const defaultCompany = {
        company: {
            totalEmployees: 0,
            programInEmployees: 0,
        }
    }

    const [error, setError] = useState(null);
    const [unconEmployeesPercentage, setUnconEmployeesPercentage] = useState([0]);
    const [company, setCompany] = useState(defaultCompany);
    const [employeesWithRatingFormFilled, setEmployeesWithRatingFormFilled] = useState(0);
    const [employeesWithRatingFormFilledPercentage, setEmployeesWithRatingFormFilledPercentage] = useState(0);
    const [economicInterest, setEconomicInterest] = useState(0);
    const [environmentalInterest, setEnvironmentalInterest] = useState(0);
    const [socialInterest, setSocialInterest] = useState(0);
    const [employeePreferenceSerie, setEmployeePreferenceSerie] = useState([{
        name: 'Económico',
        data: [0]
      }, {
        name: 'Ambiental',
        data: [0]
      }, {
        name: 'Social',
        data: [0]
      }]);

    const [companyAndTotalCompanySeries, setCompanyAndTotalCompanySeries] = useState(null);
    const [years, setYears] = useState([{ value: '1979', label: '1979'}]);;
    const months = ['january','february','march','april','may','june','july','august','setember','october','november','december'];
    const [historicalYearSeries, setHistoricalYearSeries] = useState(new Map());

    const defaultImpact = [{  
        key: 'impact',
        name: 'loading impact',
        value: 0,
       }];
    const [impacts, setImpacts] = useState(defaultImpact);

    const [openEmployeesModal, setOpenEmployeesModal] = useState(false,);
    const [openCompanyRatingModal, setOpenCompanyRatingModal] = useState(false);
    const [openHistoricalCompanyRatingModal,setOpenHistoricalCompanyRatingModal] = useState(false);
    const [openImpactModal,setOpenImpactModal] = useState(false);
    const [openAggregateModal,setOpenAggregateModal] = useState(false);

    const showEmployeesModal = () => {
        setOpenEmployeesModal(true);
    }

    const showCompanyRatingModal = () => {
        setOpenCompanyRatingModal(true);
    }

    const showHistoricalCompanyRatingModal = () => {
        setOpenHistoricalCompanyRatingModal(true);
    }

    const showImpactModal = () => {
        setOpenImpactModal(true);
    }

    const showAggregateModal = () => {
        setOpenAggregateModal(true);
    }

    const calculatePreferencesEmployees = (employees,fillRatingEmployees) => {

        let employeePreferenceSerieDefault = [{
            name: 'Económico',
            data: [0]
          }, {
            name: 'Ambiental',
            data: [0]
          }, {
            name: 'Social',
            data: [0]
          }];

        let economicInterest = 0;
        let environmentalInterest = 0;
        let socialInterest = 0;

        employees.map( employee => {
            employeeRatingService.getByEmployeeId(employee.employeeId)
                .then( data => {
                    const employeeRating = data.employeeRating.form;
                    economicInterest = employeeRating["general-p1-consume"] || employeeRating["general-p1-health"] ? economicInterest+1 : economicInterest;
                    environmentalInterest = employeeRating["general-p1-help-environmental"] ? environmentalInterest+1 : environmentalInterest;
                    socialInterest = employeeRating["general-p1-help-people"] || employeeRating["general-p1-help-animals"] ? socialInterest+1 : socialInterest; 
                })
                .finally( () => {
                    setEconomicInterest(economicInterest);
                    setEnvironmentalInterest(environmentalInterest);
                    setSocialInterest(socialInterest);
                    employeePreferenceSerieDefault[0].data[0] = Math.round((economicInterest/fillRatingEmployees)*100);
                    employeePreferenceSerieDefault[1].data[0] = Math.round((environmentalInterest/fillRatingEmployees)*100);
                    employeePreferenceSerieDefault[2].data[0] = Math.round((socialInterest/fillRatingEmployees)*100);
                    setEmployeePreferenceSerie(employeePreferenceSerieDefault);
                });
        });

    }

    const getDataCompanyAndEmployees = (company) => {
        
        const unconEmployees = [0];
        let employeesInUncon = 0;
        companyService.get(company.companyId)
            .then( dataCompany => {
                unconEmployees[0] = dataCompany.company.programInEmployees === 0 ?
                    0 : percentageUnconEmployees(dataCompany.company.programInEmployees, dataCompany.company.totalEmployees);
                setUnconEmployeesPercentage(unconEmployees);
                setCompany(dataCompany.company);
                employeesInUncon = dataCompany.company.programInEmployees;
                return employeesInUncon;
            })
            .then( employeesInUncon => {
                const fillRatingEmployees = [0];
                const fillRatingEmployeesPercentage = [0];
                employeeService.getRatingFormFilledByCompany(company.companyId)
                    .then (dataEmployees => {
                        fillRatingEmployees[0] = dataEmployees.employees.length;
                        setEmployeesWithRatingFormFilled(fillRatingEmployees);
                        calculatePreferencesEmployees(dataEmployees.employees,fillRatingEmployees);
                        fillRatingEmployeesPercentage[0] = Math.round((dataEmployees.employees.length/employeesInUncon)*100);
                        setEmployeesWithRatingFormFilledPercentage(fillRatingEmployeesPercentage);
                    });
            })
            .catch( error => setError(error));
    }

    const calculateTotalCompaniesIndexesAverage = (accummulated, totalCompanies) => {
        return (accummulated/totalCompanies).toFixed(2);
    }

    const getSeries = (company) => {
        
        let companySeries = [0,0,0]
        let companyAndTotalCompanySeries = [{name: 'Loading company', data: [0, 0, 0],},{name: 'Loading total companies',data: [0, 0, 0],},]
        let totalSeries = [0,0,0]
    
        //REMOVE
        companyAndTotalCompanySeries[0].name = company.companyName;
        companyAndTotalCompanySeries[1].name = "Media Total Compañías"
        companyAndTotalCompanySeries[1].data = [0,0,0]
        //-- REMOVE
    
        companyIndexService.getByCompanyId(company.companyId)
          .then( data => {
              companySeries[0] = data.companyIndex.environmental
              companySeries[1] = data.companyIndex.social
              companySeries[2] = data.companyIndex.economic
              companyAndTotalCompanySeries[0].data = companySeries
          })
          .catch((error) => {
            setError("Error getting company index:", error);
          });
    
          totalCompaniesIndexesService.getByCompanyPlan("basic")
            .then ( data => {
              totalSeries[0] = 
                calculateTotalCompaniesIndexesAverage(
                  data.totalCompaniesIndexes.environmentalAccumulated,
                  data.totalCompaniesIndexes.companiesTotalNumber);
              totalSeries[1] = 
                calculateTotalCompaniesIndexesAverage(
                  data.totalCompaniesIndexes.socialAccumulated,
                  data.totalCompaniesIndexes.companiesTotalNumber);
              totalSeries[2] = 
                calculateTotalCompaniesIndexesAverage(
                  data.totalCompaniesIndexes.economicAccumulated,
                  data.totalCompaniesIndexes.companiesTotalNumber);
              companyAndTotalCompanySeries[1].data = totalSeries
              setCompanyAndTotalCompanySeries(companyAndTotalCompanySeries);
                
            })
            .catch( error => setError("Error getting document:", error));        
      }

    const getHistoricalYears = (historicalCompanyIndexes) => {
        const _years = [];
        historicalCompanyIndexes.forEach( (historicalCompanyIndex,index) => {
            _years[index] = { value: historicalCompanyIndex.year, label: historicalCompanyIndex.year};
        });
        return _years;
    }

    async function getHistorical (year,company)  {

        const environmental = [], economic = [], social = [];
        const historical = [
          {name: 'Loading environmental...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
          {name: 'Loading economic...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
          {name: 'Loading social...',data: [0,0,0,0,0,0,0,0,0,0,0,0]}];
        const promiseGetHistoricalYear = 
            await historicalCompanyIndexService.getByYear(company.companyId, year.value)
                .then( data => data);
        
        if (promiseGetHistoricalYear) {
            const actualMonth = (new Date()).getFullYear().toString() === year.value ? (new Date()).getMonth(): 12;
            if (actualMonth === 0) {
                environmental[actualMonth] = promiseGetHistoricalYear.historicalCompanyIndexes[actualMonth].months["january"].environmental;
                economic[actualMonth] = promiseGetHistoricalYear.historicalCompanyIndexes[actualMonth].months["january"].economic;
                social[actualMonth] = promiseGetHistoricalYear.historicalCompanyIndexes[actualMonth].months["january"].social; 
            } else {
                promiseGetHistoricalYear.historicalCompanyIndexes.forEach( historicalCompanyIndex => {
                    months.slice(0,actualMonth).forEach( (month, index) => {
                        environmental[index] = historicalCompanyIndex.months[month].environmental;
                        economic[index] = historicalCompanyIndex.months[month].economic;
                        social[index] = historicalCompanyIndex.months[month].social;
                    })
                });
            }
            historical[0].name = 'Ambiental';
            historical[0].data = environmental;
            historical[1].name = 'Económica';
            historical[1].data = economic;
            historical[2].name = 'Social';
            historical[2].data = social; 
            
        };  
        
        return historical;
    }


    async function getHistoricalIndexCompany(company) {

        let historical = [
            {name: 'Loading environmental...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
            {name: 'Loading economic...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
            {name: 'Loading social...',data: [0,0,0,0,0,0,0,0,0,0,0,0]}];
        const _historicalYears = new Map();
        const promiseGetYears =
            await historicalCompanyIndexService.getByCompanyId(company.companyId)
                .then( data => data);
        if (promiseGetYears) {
            const years = getHistoricalYears(promiseGetYears.historicalCompanyIndexes);
            for (const year of years) {
                historical = await getHistorical(year,company);
                _historicalYears.set(year.label, historical);
            }

            setYears(years);
            setHistoricalYearSeries(_historicalYears);
        }   
    }

    const getImpacts = (company) => {
        companyImpactService.getByCompanyId(company.companyId)
          .then( data => {
              !data.companyImpact?setImpacts(defaultImpact):setImpacts(Object.values(data.companyImpact).filter ( element => { 
                return typeof element=='object';
             }));
          })
          .catch( error => {
            setError("Error getting document:", error);
          });
      }
  
   
    useEffect( () => {
        const company = JSON.parse(localStorage.getItem('company'));
        if (company) {
            getDataCompanyAndEmployees(company);
            getSeries(company);
            getHistoricalIndexCompany(company);
            getImpacts(company);
         
        }
      }, [])

    return(
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    {error && <Alert color="danger">{error.message}</Alert>}
                    <section>
                        <div className="row">
                            <div className="col-12 col-12-mobilep">
                                <h3 className="medium">Informes Básicos</h3>  
                            </div>
                        </div>
                    </section>
                    <hr className="minor"/>
                    <section id="main" className="container">
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Número de empleados que están utilizando la plataforma</h5>
                                    <p>Mostramos datos de alta y uso de la plataforma por parte de los empleados</p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="viewEmployee"><input type="submit" value="Visualizar" className="tertiary small" onClick={showEmployeesModal}/></li>
                                        <li key="downloadEmployee"><input type="submit" value="Descargar" className="secondary small" disabled/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Rating sostenible actual y comparación con el resto de las compañías</h5>
                                    <p>Índice de sosteniblidad de la compañía y comparación con el resto de compañías de la plataforma</p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="viewRating"><input type="submit" value="Visualizar" className="tertiary small" onClick={showCompanyRatingModal}/></li>
                                        <li key="downloadRating"><input type="submit" value="Descargar" className="secondary small" disabled/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Histórico del rating sostenible de la compañía</h5>
                                    <p>Histórico del índice de sosteniblidad de la compañía por años y meses</p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="viewHistoricalRating"><input type="submit" value="Visualizar" className="tertiary small" onClick={showHistoricalCompanyRatingModal}/></li>
                                        <li key="downloadHistoricalRating"><input type="submit" value="Descargar" className="secondary small" disabled/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Impacto acumulado actual de la compañía</h5>
                                    <p>Impacto 360 acumulado por los empleados de la compañía</p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="viewImpact"><input type="submit" value="Visualizar" className="tertiary small" onClick={showImpactModal}/></li>
                                        <li key="downloadImpact"><input type="submit" value="Descargar" className="secondary small" disabled/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Empleados, Rating e Impacto</h5>
                                    <p>Informe agregado de empleados, rating de compañía e impacto 360 acumulado</p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="viewAggregate"><input type="submit" value="Visualizar" className="tertiary small" onClick={showAggregateModal}/></li>
                                        <li key="downloadAggregate"><input type="submit" value="Descargar" className="secondary small" disabled/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-12-medium">
                                <div className="card">
                                    <h5 className="green-header">Histórico del impacto de la compañía (<i>próximamente</i>)</h5>
                                    <p>Histórico del Impacto 360 acumulado por los empleados de la compañía por años y meses</p>
                                    <ul className="actions" key="actionBasicAction">
                                        <li key="viewHistoricalImpact"><input type="submit" value="Visualizar" className="tertiary small" disabled/></li>
                                        <li key="downloadHistoricalImpact"><input type="submit" value="Descargar" className="secondary small" disabled/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section> 
                </div>
                 
                <Modal
                    open={openEmployeesModal}
                    onClose={() => setOpenEmployeesModal(false)}
                    center
                    classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal',
                    }}
                >
                    <h4>Informe Empleados</h4>
                    <div className="row">
                        <div className="col-6 col-6-medium">
                            <EmployeesChart series={ unconEmployeesPercentage } label={`Empleados en Uncon`} />
                            <ul>
                                <li key="totalEmployees"><b>{company.totalEmployees}</b> empleados en la compañía</li>
                                <li key="programIn"><b>{company.programInEmployees}</b> están dados de alta, el <b>{unconEmployeesPercentage}</b>%</li>
                            </ul>
                        </div> 
                        <div className="col-6 col-6-medium">
                            <EmployeesChart series={ employeesWithRatingFormFilledPercentage }  label={`Rating inicial completado`} />
                            <ul>
                                <li key="employeesWithRatingFormFilled">
                                    <b>{employeesWithRatingFormFilled}</b> han rellenado el rating, el <b>{ employeesWithRatingFormFilledPercentage }</b>  %
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <div lass="row">
                        <div className="col-12 col-12-medium">
                            <EmployeePreferencesChart series= {employeePreferenceSerie} />
                            <ul>
                                <li key="economicInterest">
                                <b>{parseFloat((economicInterest/employeesWithRatingFormFilled)*100)} % </b> de los empleados está interesado en el pilar <b>Económico</b>
                                </li>
                                <li key="environmentalInterest">
                                <b>{parseFloat((environmentalInterest/employeesWithRatingFormFilled)*100)} % </b> de los empleados está interesado en el pilar <b>Ambiental</b>
                                </li>
                                <li key="socialInterest">
                                <b>{parseFloat((socialInterest/employeesWithRatingFormFilled)*100)} % </b> de los empleados está interesado en el pilar <b>Social</b>
                                </li>
                            </ul>
                        </div>
                    </div>  
                </Modal>
                <Modal
                    open={openCompanyRatingModal}
                    onClose={() => setOpenCompanyRatingModal(false)}
                    center
                    classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal companyRatingModal',
                    }}
                >
                    <h4>Informe Rating Compañía</h4>
                    <div className="row">
                        <div className="col-12 col-12-medium">
                            <CompanyIndexChart series={companyAndTotalCompanySeries} />
                            <ul>
                                <li key="economicpilar">
                                    Tu compañía tien un rating de <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[0].data[0] : null }</b> 
                                    &nbsp;en el pilar Económico frente a los <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[1].data[0] : null }</b>  de media
                                </li>
                                <li key="environmentalPilar">
                                    Tu compañía tien un rating de <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[0].data[1] : null }</b> 
                                    &nbsp;en el pilar Ambiental frente a los <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[1].data[1] : null }</b>  de media
                               </li>
                                <li key="socialPilar">
                                    Tu compañía tien un rating de <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[0].data[2] : null }</b> 
                                    &nbsp;en el pilar Social frente a los <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[1].data[2] : null }</b>  de media
                               </li>
                            </ul>
                        </div> 
                    </div> 
                  
                </Modal>
                <Modal
                    open={openHistoricalCompanyRatingModal}
                    onClose={() => setOpenHistoricalCompanyRatingModal(false)}
                    center
                    classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal historicalCompanyRatingModal',
                    }}
                >
                    <h4>Informe Histórico Rating Compañía</h4>
                    <div className="row">
                        <div className="col-12 col-12-medium">
                        { 
                            years.map( (year, index) => {
                                
                                        return(
                                        <div>
                                            <h5 className='green-header' >año {year.label}</h5> 
                                            <HistoricalCompanyIndexChart 
                                                series={historicalYearSeries.size !== 0 && historicalYearSeries.get(year.label) !== undefined ? historicalYearSeries.get(year.label) : null} 
                                                />
                                        </div>);
                            })
                        }
                        </div> 
                    </div> 
                  
                </Modal>
                <Modal
                    open={openImpactModal}
                    onClose={() => setOpenImpactModal(false)}
                    center
                    classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal impactModal',
                    }}
                >
                    <h4>Informe Impacto Compañía</h4>
                    <div className="row">
                        <div className="col-12 col-12-medium">
                            <ImpactCompanyChart series={impacts} />
                            <ul>
                            {impacts.map( (impact, index) => (
                                <li key={impact.key + index}>
                                    <i className={`icon solid ${impact.icon}`}/> {impact.name} <b>{Math.round(impact.value)}</b> {impact.measure}
                                </li>
                            ))}
                            </ul>
                        </div> 
                    </div> 
                </Modal>
                <Modal
                    open={openAggregateModal}
                    onClose={() => setOpenAggregateModal(false)}
                    center
                    classNames={{
                    overlay: 'customOverlay',
                    modal: 'customModal aggregateModal',
                    }}
                >
                    <h4>Informe Agregado</h4>
                    <div className="row">
                        <div className="col-6 col-6-medium">
                            <EmployeesChart series={ unconEmployeesPercentage } label={`Empleados en Uncon`} />
                            <ul>
                                <li key="totalEmployee2"><b>{company.totalEmployees}</b> empleados en la compañía</li>
                                <li key="programInEmployees2"><b>{company.programInEmployees}</b> están dados de alta, el <b>{unconEmployeesPercentage}</b>%</li>
                            </ul>
                        </div> 
                        <div className="col-6 col-6-medium">
                            <EmployeesChart series={ employeesWithRatingFormFilledPercentage }  label={`Rating inicial completado`} />
                            <ul>
                                <li key="employeesWithRatingFormFilled2">
                                    <b>{employeesWithRatingFormFilled}</b> han rellenado el rating, el <b>{ employeesWithRatingFormFilledPercentage }</b>  %
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <div lass="row">
                        <div className="col-12 col-12-medium">
                            <EmployeePreferencesChart series= {employeePreferenceSerie} />
                            <ul>
                                <li key="economicAndEmployeesWithRatingFormFilled">
                                <b>{parseFloat((economicInterest/employeesWithRatingFormFilled)*100)} % </b> de los empleados está interesado en el pilar <b>Económico</b>
                                </li>
                                <li key="environmentalAndEmployeesWithRatingFormFilled">
                                <b>{parseFloat((environmentalInterest/employeesWithRatingFormFilled)*100)} % </b> de los empleados está interesado en el pilar <b>Ambiental</b>
                                </li>
                                <li key="socialAndEmployeesWithRatingFormFilled">
                                <b>{parseFloat((socialInterest/employeesWithRatingFormFilled)*100)} % </b> de los empleados está interesado en el pilar <b>Social</b>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-12-medium">
                            <CompanyIndexChart series={companyAndTotalCompanySeries} />
                            <ul>
                                <li key="economicPilar2">
                                    Tu compañía tien un rating de <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[0].data[0] : null }</b> 
                                    &nbsp;en el pilar Económico frente a los <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[1].data[0] : null }</b>  de media
                                </li>
                                <li key="environmentalPilar2">
                                    Tu compañía tien un rating de <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[0].data[1] : null }</b> 
                                    &nbsp;en el pilar Ambiental frente a los <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[1].data[1] : null }</b>  de media
                               </li>
                                <li key="socialPilar2">
                                    Tu compañía tien un rating de <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[0].data[2] : null }</b> 
                                    &nbsp;en el pilar Social frente a los <b>{ companyAndTotalCompanySeries != null ? companyAndTotalCompanySeries[1].data[2] : null }</b>  de media
                               </li>
                            </ul>
                        </div> 
                    </div> 
                    <div className="row">
                        <div className="col-12 col-12-medium">
                            <ImpactCompanyChart series={impacts} />
                            <ul>
                            {impacts.map( (impact, index) => (
                                <li key={impact.key}>
                                    <i className={`icon solid ${impact.icon}`}/> {impact.name} <b>{Math.round(impact.value)}</b> {impact.measure}
                                </li>
                            ))}
                            </ul>
                        </div> 
                    </div> 
                </Modal>
            </section>
        </article>
    );
}


const condition = authUser => !!authUser;  

export default withAuthorization(condition)(withRouter(Main));
