export function percentageUnconEmployees(programInEmployees, totalEmployees ) {
    return Math.round((parseInt(programInEmployees,10)/parseInt(totalEmployees,10))*100);   
}

export function getActualYear() {
    const date = new Date();
    const year = date.getFullYear().toString();
    return year;
}

export function getEmployeePreferences(employeeRating) {

    const preferences = new Map();
        if (employeeRating) {
            const social = employeeRating["general-p1-help-people"] || employeeRating["general-p1-help-animals"];
            const environmental = employeeRating["general-p1-help-environmental"];
            const economic = employeeRating["general-p1-consume"] || employeeRating["general-p1-health"];
            preferences.set("social",social);
            preferences.set("environmental",environmental);
            preferences.set("economic",economic);
        }

        return preferences;
}

export function getRandomKeyMap(elements) {

    let keys = Array.from(elements.keys());
    return keys[Math.floor(Math.random() * keys.length)];
}