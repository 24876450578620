import React, {Component} from 'react';

const CardHeader = (props) => {
   
    const header = props.cardData.header
    
    return (
        <div className="cardHeader-root">
            <div className="cardHeader-avatar">
                <div className={`avatar-root ${header.class}`} aria-label="proposal">
                    {header.shortTitle}
                </div>
            </div>
            <div className="cardHeader-content">
            <b>{header.longTitle}</b>
            </div>
        </div>
    )
}

const CardImage = (props) => {

    const image = props.cardData.image;
    
    return(
        <span className="image fit">
            <img src={image.src} alt="" />
        </span>
    )

    
}

const CardAction = (props) => {
    
    const action = props.action;

    return(
        [<section className="" key="headerAction" >
            <h4 className="green-header">Acción</h4>
            <b>{action.title}</b> <br/> {action.description}
        </section>]
    )
}

const CardBenefits = (props) => {

    var icons = {
        animal: 'fa-dog',
        co2: 'fa-fingerprint',
        health: 'fa-heartbeat',
        money: 'fa-euro-sign',
        people: 'fa-users',
        tax: 'fa-university',
        water: 'fa-tint',
        more: 'fa-plus'
    }

    const propBenefits = props.action.benefits;
    const valuesBenefits = Object.values(propBenefits);
    const entriesBenefits = Object.entries(propBenefits);
    const actionProposal = props.actionProposal;
    
    if (!actionProposal.reject) {

        const retBenefits = valuesBenefits.map( (benefit,index) => {
            if (benefit.value!=='') {
                return(<li className={`icon solid ${icons[entriesBenefits[index][0]]}`} key={`libenefits-${index}`}>&nbsp;{benefit.value}</li>) 
            }
        });
        
        return (
            <section className="dashboard">
                <h4 key={`titleImpact`} className="green-header">Impacto</h4>
                <ul key={`impactsImpact`} className="alt">{retBenefits}</ul>
            </section>
        )
    } else {
        return (
            <section className="dashboard">
                <h5>En breve dispondrás de una nueva acción de mejora</h5>
                <p>Una vez al mes sugerimos una nueva mejora para avanzar en tu camino y que consigas ser una persona con un mayor índice sostenible</p>
            </section>
        )
    }
}

const CardButton = (props) => {
    
    const type = props.type;
    const onclickAccept = props.onclickAccept;
    const onclickReject = props.onclickReject;
    const actionProposal = props.actionProposal;
    const action = props.action;    
    if (!actionProposal.done && action.evidence && !actionProposal.reject) {
        return(
            [<ul><li key={`evidence`}><b><i>Requiere evidencia</i></b></li></ul>,
            <ul className="actions">
                <li key={`doActionWithEvidence`}><input type="submit" value="Realizar propuesta" className={`secondary`} onClick={onclickAccept} pilar={`${type ? type.pillarId : null}`}/></li>
                <li key={`rejectActionWithEvidence`}><input type="submit" value="Descartar propuesta" className={`primary`} onClick={onclickReject} pilar={`${type ? type.pillarId : null}`}/></li>
            </ul>]

        )
    } else if (!actionProposal.done && !action.evidence && !actionProposal.reject) {
        return(
            [<ul className="actions">
                <li key={`doActionWithoutEvidence`}><input type="submit" value="Realizar propuesta" className={`secondary`} onClick={onclickAccept} pilar={`${type ? type.pillarId : null}`}/></li>
                <li key={`rejectActionWithoutEvidence`}><input type="submit" value="Descartar propuesta" className={`primary`} onClick={onclickReject} pilar={`${type ? type.pillarId : null}`}/></li>
            </ul>]
        )
    } else if (actionProposal.done) {
        return(
            [<ul className="actions">
                <li key={`actionDoneDoneDisabled`}><input  type="submit" value="Realizar propuesta" className={`secondary`} onClick={onclickAccept} pilar={`${type ? type.pillarId : null}`} disabled={true}/></li>
                <li key={`actionDoneRejectDisabled`}><input type="submit" value="Descartar propuesta" className={`primary`} onClick={onclickReject} pilar={`${type ? type.pillarId : null}`} disabled={true}/></li>
            </ul>,<h4 className='green-header-important'>Mejora realizada</h4>]
        )
    } else if (actionProposal.reject) {
        return(
            [<ul className="actions">
                <li key={`actionRejectDoneDisabled`}><input type="submit" value="Realizar propuesta" className={`secondary`} onClick={onclickAccept} pilar={`${type ? type.pillarId : null}`} disabled={true}/></li>
                <li key={`actionRejectRejectDisabled`}><input type="submit" value="Descartar propuesta" className={`primary`} onClick={onclickReject} pilar={`${type ? type.pillarId : null}`} disabled={true}/></li>
            </ul>,<h4 className='grey-header-important'>Acción descartada</h4>]
        )
    } else {
        return null;
    }
}

class Card extends Component {
    
    render() {

        const {cardData, action, type, onclickAccept, onclickReject, actionProposal} = this.props;
        return(
            <div className="card">
                <CardHeader cardData={cardData}/>
                <CardImage cardData={cardData}/>
                <CardAction action={action}/>
                <CardBenefits action={action} actionProposal={actionProposal}/>
                <CardButton action={action} type={type} onclickAccept={onclickAccept} onclickReject={onclickReject} actionProposal={actionProposal}/>
            </div>
        )
    }
}

export default Card