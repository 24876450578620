import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

class Main extends Component {
    render() {
        return(
            <article id="main">
                <header>
                    <h2>Política de privacidad</h2>
                </header>
                <section class="wrapper style5">
                    <div class="inner">
                        <p> <span className="greenlink">Uncon Corporate</span> te informa sobre su Política de Privacidad respecto del tratamiento y protección de los datos de carácter personal de los usuarios que puedan ser recabados por la navegación 
                            o el uso de servicios a través del sitio Web <span className="greenlink">unconcorp.com</span>
                            <br/>
                            <br/>
                            <span className="greenlink">Uncon Corporate</span> garantiza el cumplimiento de la normativa vigente en materia de protección de datos personales, reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales 
                            y de Garantía de Derechos Digitales (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del Parlamento Europeo 
                            y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas (GDPR).
                            <br></br>
                            <br></br>
                            El uso de sitio Web implica la aceptación de esta Política de Privacidad así como las condiciones incluidas en el Aviso Legal.
                            <br></br>
                            <br></br>
                            De acuerdo con esto, si eres menor de 14 años no debes registrarte en ningún formulario de esta web sin el consentimiento de tus padres o tutores legales 
                            (puedes encontrar más información sobre esto en el apartado 4 del Aviso Legal).
                            <br></br>
                            <br></br>
                            Como <span className="greenlink">Uncon Corporate</span> nos comprometemos a :
                            <br></br>
                            No solicitar información personal a menos que realmente sea necesaria para prestarte los servicios que requieras.
                            No compartir información personal de los usuarios con terceros, excepto para cumplir con la ley, a no ser que contemos
                            su autorización expresa.
                            No utilizar tus datos personales con una finalidad diferente a la expresada en esta política de privacidad.
                            Esta Política de Privacidad podría variar en función de exigencias legislativas o de autorregulación, 
                            por lo que es aconsejable visitarla periódicamente, ya que será aplicable en caso de que decidas rellenar cualquier 
                            formulario de esta web en el que se recaben datos de carácter personal.
                            </p>
                        <div className="row">
                            <div className="row-6 row-12-mobilep">
                                <h3>Responsable del tratamiento de tus datos personales</h3>
                                <p>
                                    El responsable del tratamiento es <b>Pedro Abalo Martínez</b> con correo electrónico <b>hola@unconcorp.com</b>
                                    <br></br><br></br>
                                    A efectos de lo previsto en el Reglamento General de Protección de Datos antes citado, los datos personales que nos 
                                    envíes a través de los formularios de la web, recibirán el tratamiento de datos de “Usuarios de la web”.
                                    Para el tratamiento de datos de los usuarios, se han implementado medidas técnicas y organizativas 
                                    de seguridad establecidas en la legislación vigente (como se puede ver de manera más detallada en el apartado 
                                    7 del Aviso legal).</p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Principios que aplicamos en el tratamiento de tus datos personales</h3>
                                <p>En el tratamiento de tus datos personales, se aplicarán los siguientes principios ajustados a las exigencias del nuevo reglamento europeo de protección de datos (GDPR):
                                    <br></br><br></br>
                                    <b className="greenlink">Principio de licitud, lealtad y transparencia:</b> tu consentimiento será requerido para el tratamiento de tus datos personales para uno o varios fines específicos y sobre los que te informaremos previamente con absoluta transparencia.<br></br>
                                    <b className="greenlink">Principio de minimización de datos:</b> sólo vamos a solicitar datos estrictamente necesarios en relación con el fin o los fines para los que se requieren.<br></br>
                                    <b className="greenlink">Principio de limitación del plazo de conservación:</b> los datos serán mantenidos durante el tiempo necesario para los fines del tratamiento. En función a la finalidad, serás informado del plazo de conservación correspondiente.<br></br>
                                    <b className="greenlink">Principio de integridad y confidencialidad:</b> tus datos serán tratados de tal manera que se garantice una seguridad adecuada de los datos personales y se garantice confidencialidad. Se tomarán todas las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de nuestros usuarios por parte de terceros.<br></br>
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>¿Cómo obtenemos tus datos?</h3>
                                <p>Los datos personales que tratamons en <b className="greenlink">unconcorp.com</b> proceden del <span className="greenlink">formulario de solicitud de más información</span> de 
                                la página de inicio, del <span className="greenlink"> formulario de contacto</span> y del <span className="greenlink">registro de usuarios</span>.</p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>¿Cuáles son tus derechos cuando nos facilitas tus datos?</h3>
                                <p>Si has cedido tus datos a <b className="greenlink">unconcorp.com</b>, tienes derecho a:
                                <br></br>
                                    <ul>
                                        <li>
                                            <b>Solicitar el acceso a los datos personales almacenados y relacionados contigo.</b>
                                        </li>
                                        <li>
                                            <b>Solicitar su rectificación o supresión.</b>
                                        </li>
                                        <li>
                                            <b>Solicitar la limitación de su tratamiento.</b>
                                        </li>
                                        <li>
                                            <b>Oponerte al tratamiento.</b>
                                        </li>
                                        <li>
                                            <b>Solicitar la portabilidad de los datos.</b>
                                        </li>
                                    </ul>
                                    <br></br>
                                    El ejercicio de estos derechos es personal y por lo tanto, debe ser ejercido directamente por el interesado. 
                                    Como tal, es el interesado el que podrá acceder a sus datos personales, así como a solicitar la rectificación 
                                    de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean 
                                    necesarios para los fines que fueron recogidos. En determinadas circunstancias, podrás solicitar la limitación 
                                    del tratamiento de tus datos, en cuyo caso únicamente serán conservados para el ejercicio o la defensa de reclamaciones.
                                    <br></br>
                                    <br></br>
                                    En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás oponerte al tratamiento
                                    de tus datos. <b className="greenlink">Uncon Corporate</b> dejará de tratar los datos, salvo por motivos legítimos 
                                    imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Cómo interesado, tienes derecho a recibir los datos
                                    personales que te incumban, que hayas facilitado y en un formato estructurado, de uso común y lectura mecánica, 
                                    y a transmitirlos a otro responsable del tratamiento cuando:
                                    <br></br>
                                    <ul>
                                        <li>
                                            <b>El tratamiento esté basado en el consentimiento.</b>
                                        </li>
                                        <li>
                                            <b>Los datos hayan sido facilitados por la persona interesada.</b>
                                        </li>
                                        <li>
                                            <b>El tratamiento se efectúe por medios automatizados.</b>
                                        </li>
                                    </ul>
                                    <br></br>
                                    Para ejercitar tus derechos de acceso, rectificación, cancelación, portabilidad y oposición tienes 
                                    que enviar un correo electrónico a <b className="greenlink">hola@unconcorp.com</b> junto con la prueba 
                                    válida en derecho como una fotocopia del D.N.I. o equivalente.
                                    <br></br>
                                    <br></br>
                                    Como interesado tendrás derecho a la tutela judicial efectiva y a presentar una reclamación 
                                    ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si 
                                    consideras que el tratamiento de datos personales que te conciernen infringe el Reglamento.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>¿Con qué finalidad tratamos tus datos personales?</h3>
                                <p>Tanto en el <b className="greenlink">formulario de solicitud de más información</b> de la página de inicio,
                                en el <b className="greenlink">formulario de contacto</b> y en el <b className="greenlink">registro de usuarios </b>
                                solicitamos los siguientes datos personales:  
                                <b> nombre</b>, <b>email</b>, para responder a los requerimientos de los usuarios de 
                                <span className="greenlink"> unconcorp.com</span>. 
                                <br></br>
                                <br></br>
                                Por ejemplo, podemos utilizar esos datos para responder a tu solicitud y dar respuesta a las dudas, 
                                quejas, comentarios o inquietudes que puedas tener relativas a la información incluida en la web, 
                                los servicios que se prestan a través de la web, el tratamiento de tus datos personales, 
                                cuestiones referentes a los textos legales incluidos en la web, 
                                así como cualesquiera otras consultas que puedas tener y que no estén sujetas a 
                                las condiciones de contratación. <br></br>
                                Te informamos que los datos que nos facilitas estarán ubicados en los servidores de <b>Google Firebase </b> 
                                (proveedor de hosting de <b>unconcorp.com</b>) <b>dentro de la UE</b> (eur3 / europe-west).
                                <br></br>
                                <br></br>
                                Existen otras finalidades por la que trato tus datos personales:
                                <br></br>
                                <br></br>
                                <b>Para garantizar el cumplimiento de las condiciones de uso y la ley aplicable</b> Esto puede incluir el desarrollo de herramientas y algoritmos que ayudan a esta web a garantizar la confidencialidad de los datos personales que recoge.
                                <br></br>
                                <b>Para apoyar y mejorar los servicios que ofrece esta web.</b>
                                <br></br>
                                También se recogen otros datos no identificativos que se obtienen mediante algunas <b>cookies</b> que se descargan en el ordenador del usuario cuando navega en esta web que detallo en la <Link to={ROUTES.COOKIES}>política de cookies</Link>.
                                <br></br>
                                <b>Para gestionar las redes sociales</b>. <b>unconcorp.com</b> puede tener presencia en redes sociales. El tratamiento de los datos que se lleve a cabo de las personas que se hagan seguidoras en las redes sociales de las páginas oficiales de <b>unconcorp.com</b> , se regirá por este apartado. Así como por aquellas condiciones de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada caso y aceptadas previamente por el usuario de Pedro Abalo Martínez. Tratará sus datos con las finalidades de administrar correctamente su presencia en la red social, informando de actividades, productos o servicios de <span className="greenlink">Uncon Corporate</span>. Así como para cualquier otra finalidad que las normativas de las redes sociales permitan. En ningún caso utilizaremos los perfiles de seguidores en redes sociales para enviar publicidad de manera individual.
                                <br></br>
                                <br></br>
                                De acuerdo a lo establecido en el reglamento general de protección de datos europeo (RGPD) 2016/679, <span className="greenlink">Uncon Corporate</span>, será responsable del tratamiento de los datos correspondientes a Usuarios de la web.
                                <br></br>
                                <br></br>
                                <span className="greenlink">Uncon Corporate</span> no vende, alquila ni cede datos de carácter personal que puedan identificar al usuario, ni lo hará en el futuro, a terceros sin el consentimiento previo. Sin embargo, en algunos casos se pueden realizar colaboraciones con otros profesionales, en esos casos, se requerirá consentimiento a los usuarios informando sobre la identidad del colaborador y la finalidad de la colaboración. Siempre se realizará con los más estrictos estándares de seguridad.
                                </p>                            
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Legitimación para el tratamiento de tus datos</h3>
                                <p>
                                La base legal para el tratamiento de sus datos es: el consentimiento expreso y/o el cumplimiento de un contrato.
                                <br></br>
                                <br></br>
                                Para contactar o realizar comentarios en esta web se requiere el consentimiento expreso con esta política de privacidad.
                                <br></br>
                                <br></br>
                                La oferta prospectiva o comercial de productos y servicios está basada en el consentimiento que se te solicita, sin que en ningún caso la retirada de este consentimiento condicione la ejecución del contrato de adquisición de servicios.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Categoría de datos recogidos</h3>
                                <p>Las categorías de datos que se tratan son datos identificativos.
                                    <br></br><br></br>
                                    En ningún caso se tratan categorías de datos especialmente protegidos.</p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>¿Por cuánto tiempo conservaremos tus datos?</h3>
                                <p>Hasta que solicites su eliminación, en el caso de haberte suscrito a algún servicio gratuito, o cuando dejes de interactuar con <b>unconcorp.com</b>  durante un tiempo razonable.</p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>¿A qué destinatarios se comunicarán tus datos?</h3>
                                <p>
                                Algunas herramientas que utilizamos para gestionar tus datos son contratados por terceros.
                                <br></br>
                                <br></br>
                                Para prestar servicios estrictamente necesarios para el desarrollo de la actividad, <b>unconcorp.com</b> , comparte datos con los siguientes prestadores bajo sus correspondientes condiciones de privacidad:
                                <br></br>
                                <br></br>
                                <b>Google Analytics:</b> un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, que son archivos de texto ubicados en tu ordenador, para ayudar a <b>unconcorp.com</b>  a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso de <b>unconcorp.com</b>  (incluyendo tu dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos.
                                <br></br>
                                <br></br>
                                <b>Hosting: </b> <a href="https://firebase.google.com/support/privacy/?hl=es-419">Google Firebase</a> con servidores alojados dentro de la UE.
                    
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Navegación</h3>
                                <p>
                                Al navegar por <b>unconcorp.com</b>  se pueden recoger datos no identificables, que pueden incluir, direcciones IP, ubicación geográfica (aproximadamente), un registro de cómo se utilizan los servicios y sitios, y otros datos que no pueden ser utilizados para identificar al usuario. Entre los datos no identificativos están también los relacionados a tus hábitos de navegación a través de servicios de terceros. Esta web utiliza los siguientes servicios de análisis de terceros: Google Analytics
                                <br></br>
                                <br></br>
                                Utilizamos esta información para analizar tendencias, administrar el sitio, rastrear los movimientos de los usuarios alrededor del sitio y para recopilar información demográfica sobre mi base de usuarios en su conjunto.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Secreto y seguridad de los datos</h3>
                                <p>
                                <b>unconcorp.com</b>  se compromete en el uso y tratamiento de los datos incluidos personales de los usuarios, respetando su confidencialidad y a utilizarlos de acuerdo con la finalidad del mismo, así como a dar cumplimiento a su obligación de guardarlos y adaptar todas las medidas para evitar la alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa vigente de protección de datos.
                                <br></br>
                                Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace que tus datos viajen de manera íntegra y segura, es decir, la transmisión de los datos entre un servidor y usuario web, y en retroalimentación, es totalmente cifrada o encriptada.
                                <br></br>
                                <b>unconcorp.com</b> no puede garantizar la absoluta inexpugnabilidad de la red Internet y por tanto la violación de los datos mediante accesos fraudulentos a ellos por parte de terceros.
                                <br></br>
                                Con respecto a la confidencialidad del procesamiento, Pedro Abalo Martínez se asegurará de que cualquier persona que esté autorizada por Uncon para procesar los datos del cliente (incluido su personal, colaboradores y prestadores), estará bajo la obligación apropiada de confidencialidad (ya sea un deber contractual o legal).
                                <br></br>
                                Cuando se presente algún incidente de seguridad, al darse cuenta <span className="greenlink">Uncon Corporate</span>, deberá notificar al Cliente sin demoras indebidas y deberá proporcionar información oportuna relacionada con el Incidente de Seguridad tal como se conozca o cuando el Cliente lo solicite razonablemente.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Exactitud y veracidad de los datos</h3>
                                <p>
                                Como usuario, eres el único responsable de la veracidad y corrección de los datos que remitas a <b>unconcorp.com</b>  exonerando a Pedro Abalo Martínez, de cualquier responsabilidad al respecto.
                                <br></br>
                                <br></br>
                                Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de contacto.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Aceptación y consentimiento</h3>
                                <p>
                                    El usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de los mismos por parte de <span className="greenlink">Uncon Corporate</span> en la forma y para las finalidades indicadas en esta política de privacidad.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Revocabilidad</h3>
                                <p>
                                El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los interesados, es revocable en cualquier momento comunicándolo a <span className="greenlink">Uncon Corporate</span> en los términos establecidos en esta Política para el ejercicio de los derechos ARCO. Esta revocación en ningún caso tendrá carácter retroactivo.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Cambios en la Política de Privacidad</h3>
                                <p>
                                Uncon se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, Uncon anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.
                                </p>
                            </div>
                            <div className="row-6 row-12-mobilep">
                                <h3>Correos comerciales</h3>
                                <p>
                                De acuerdo con la LSSICE, unconcorp.com no realiza prácticas de SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o autorizados por el usuario. En consecuencia, en cada uno de los formularios habidos en la web, el usuario tiene la posibilidad de dar su consentimiento expreso para recibir el boletín, con independencia de la información comercial puntualmente solicitada.
                                <br/><br/>
                                Conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio electrónico, <b>unconcorp.com</b>  se compromete a no enviar comunicaciones de carácter comercial sin identificarlas debidamente.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
         );
    }
}
    
export default Main;