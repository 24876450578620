import { useFetchWrapper } from "../helpers";

const useTotalCompaniesIndexesService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/total-companies-indexes`;
    const fetchWrapper = useFetchWrapper();

    return {
       getByCompanyPlan,
       getAll
    }

    function getByCompanyPlan(companyPlan) {
        return fetchWrapper.get(`${baseUrl}/${companyPlan}`);
    }

    function getAll() {
        return fetchWrapper.get(baseUrl);
    }
}

export { useTotalCompaniesIndexesService };
