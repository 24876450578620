export const COL_PILLARS_DOC_ENVIRONMENTAL = "environmental"
export const COL_PILLARS_DOC_SOCIAL = "social"
export const COL_PILLARS_DOC_ECONOMIC = "economic"

export const FIELD_TOTAL_EMPLOYEES = "totalEmployees"
export const FIELD_UNCON_EMPLOYEES = "programInEmployees"

export const generalP1Consume = "general-p1-consume";
export const generalP1Health = "general-p1-health";
export const generalP1HelpAnimals = "general-p1-help-animals";
export const generalP1HelpEnvironmental = "general-p1-help-environmental";
export const generalP1HelpPeople = "general-p1-help-people";
export const socialP2 = "social-p2";
export const socialP2AuxEconomicLess= "social-p2-aux-economic-less";
export const socialP2AuxEconomicMore = "social-p2-aux-economic-more";
export const socialP2AuxWorkLess = "social-p2-aux-work-less";
export const socialP2AuxWorkMore = "social-p2-aux-work-more";
export const environmentalP3 = "environmental-p3";
export const environmentalP3AuxPlastic = "environmental-p3-aux-plastic";
export const environmentalP3AuxGlass = "environmental-p3-aux-glass";
export const environmentalP3AuxCardboard = "environmental-p3-aux-cardboard";
export const environmentalP4 = "environmental-p4";
export const environmentalP4AuxShower = "environmental-p4-aux-shower";
export const environmentalP4AuxTap = "environmental-p4-aux-tap";
export const environmentalP4AuxAtomizer = "environmental-p4-aux-atomizer";
export const environmentalP4AuxToilet = "environmental-p4-aux-toilet";
export const environmentalP5 = "environmental-p5";
export const environmentalP5AuxLed = "environmental-p5-aux-led";
export const environmentalP5AuxStandby = "environmental-p5-aux-standby";
export const environmentalP5AuxTemp = "environmental-p5-aux-temp";
export const environmentalP6Car = "environmental-p6-car";
export const environmentalP6Hybrid = "environmental-p6-hybrid";
export const environmentalP6Electric = "environmental-p6-electric";
export const environmentalP6Public = "environmental-p6-public";
export const environmentalP6Bike = "environmental-p6-bike";
export const environmentalP6Walk = "environment-p6-walk";
export const economicP7 = "economic-p7";
export const economicP8 = "economic-p8";
export const economicP9 = "economic-p9";
 