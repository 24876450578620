import { Component } from 'react';
 
import { authentication } from '../Firebase';

import * as ROUTES from '../../constants/routes';
 
class SignOut extends Component {

  componentDidMount() {
    authentication.signOut();
    this.props.history.push(ROUTES.LANDING);
  }

  render() {
    return null;
  }

}
 
export default SignOut;