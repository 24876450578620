import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { Alert } from 'reactstrap';
import { UserCompanyIndexRadialBarChart, UserIndexRadialBarChart } from '../../Chart'
import Card from '../../App/Card'
import Impact from '../../App/Impact'

import { usePillarService } from '../../../services/pillar.service';
import { useEmployeeImpactService } from '../../../services/employeeImpact.service';
import { useEmployeeIndexService } from '../../../services/employeeIndex.service';
import { useCompanyIndexService } from '../../../services/companyIndex.service';
import { useEmployeeService } from '../../../services/employee.service';
import { useImprovementActionService } from '../../../services/improvementAction.service';
import { useActionService } from '../../../services/action.service';
import { useActionProposalService} from '../../../services/actionProposal.service';

import { storage, firebase } from '../../Firebase/firebase'
import * as ROUTES from '../../../constants/routes';
import { history } from '../../App/CompanyHeader';


import Modal from '../../App/Modal';
import { setAnalyticsCollectionEnabled } from '../../Firebase/analytics';

const Main = () => {
    const pillarService = usePillarService();
    const employeeImpactService = useEmployeeImpactService();
    const employeeIndexService = useEmployeeIndexService();
    const companyIndexService = useCompanyIndexService();
    const employeeService = useEmployeeService();
    const improvementActionService = useImprovementActionService();
    const actionService = useActionService();
    const actionProposalService = useActionProposalService();

    const [error, setError] = useState(null);
    const [showModalPhoto, setShowModalPhoto] = useState(false);
    const [showModalAction, setShowModalAction] = useState(false);
    const [showModalRejectAction, setShowModalRejectAction] = useState(false);
    const [employee, setEmployee] = useState(null);
    const [employeePhoto, setEmployeePhoto] = useState(null);
    const [employeePhotoUrl, setEmployeePhotoUrl] = useState(null);
    const [employeeCompanyName, setEmployeeCompanyName] = useState(null);
    const [progress, setProgress] = useState(null);
    const [employeeFullName, setEmployeeFullName] = useState(null);
    const [pillar, setPillar] = useState(null);
    const [action, setAction] = useState(null);
    const [improvementAction, setImprovementAction] = useState(null);
    const [actionProposal, setActionProposal] = useState(null);
    const [employeeIndex, setEmployeeIndex] = useState(null);
    const [userAndCompanySeries, setUserAndCompanySeries] = useState(null);
    const [infoUser, setInfoUser] = useState(null);
    const [impacts, setImpacts] = useState(null);

    const defaultEmployeeFullName = 'Loading full name...';
    const defaultEmployeeCompanyName = 'Loading Company Name...';
    const defaultUserSeries = [0, 0, 0]
    const defaultUserCompanySeries = [{name: 'Loading user...',data: [0, 0, 0],},{name: 'Loading company...',data: [0, 0, 0],},]
    const defaultCard = {
        header: {
          shortTitle: 'N',
          class: 'avatar-environmental',
          longTitle: 'Acción Pendiente',
        },
        image: {
          src: '/images/loading-opt.png',
        },
        action: {
          title: 'Default.',
          description: 'Loading...',
        },
        impacts: [
          {
            icon: 'fa-spinner',
            description: ' Loading...',
          }
        ]
      }

    const defaultAction = {
      description: 'Una vez al mes sugerimos una nueva mejora para avanzar en tu camino y que consigas ser una persona con un mayor índice sostenible',
      evidence: false,
      key: '...',
      title: 'En breve dispondrás de una nueva acción',
      benefits: 
        {
          default: 
          {
            description: 'loading',
            value: ''
          }
        }
    }
    
    const defaultImpact = [{  
       key: 'impact',
       name: 'loading impact',
       value: 0,
      }]

    const defaultImprovementAction = {
      actionId: '',
      employeeId: '',
      show: false,
      done: false,
      actual: false,
      reject: false
    }

    const defaultActionProposal = {
        actionId: '',
        order: 0,
        type: '',
        done: false,
        reject: false,
        actual: false
    }

    const fShowModalPhoto = () => {
        setShowModalPhoto(true);
    }
  
    const fHideModalPhoto = () => {
      setShowModalPhoto(false);
    }
  
    const fShowModalAction = () => {
      setShowModalAction(true);
    }

    const fShowModalRejectAction = () => {
      setShowModalRejectAction(true);
    }

    const fHideModalAction = () => {
      setShowModalAction(false);
    }

    const fHideModalRejectAction = () => {
      setShowModalRejectAction(false);
    }

    const handleDrop = dropped => {
      setEmployeePhoto(dropped[0]);
    }
  
    const fUploadPhoto = () => {
        const file = employeePhoto;
        const storageRef = storage.ref();
        const employeePhotoRef = storageRef.child('employees/photos/' + employee.employeeId + '.jpg')
        const uploadTask = employeePhotoRef.put(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {
            var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
            setProgress(progress);
          }, 
          error => {
            throw error;
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then(
              (url) => {
                employee.photoUrl = url;
                employeeService.updateEmployee(employee)
                  .then( () => console.log('photo updated'))
                  .catch( error => setError('an error happenned ',error));
            })
            setShowModalPhoto(false);
          })
    }
    
    const getSeries = (employee) => {
      const employeeIndex = []
      const userCompanySeries = [{name: 'Loading user', data: [0, 0, 0],},{name: 'Loading company',data: [0, 0, 0],},]
      
      employeeIndexService.getByEmployeeId(employee.employeeId)
        .then( data => {
            employeeIndex.push(data.employeeIndex.environmental.toFixed(2));
            employeeIndex.push(data.employeeIndex.social.toFixed(2));
            employeeIndex.push(data.employeeIndex.economic.toFixed(2));
            setEmployeeIndex(employeeIndex);
            userCompanySeries[0].name = employee.name;
            userCompanySeries[0].data = [];
            userCompanySeries[0].data.push(...employeeIndex);
        })
        .catch(error => setError(error));
      
      companyIndexService.getByCompanyId(employee.companyId)
        .then(data => {
            userCompanySeries[1].name = employee.companyName;
            userCompanySeries[1].data = [];
            userCompanySeries[1].data.push(data.companyIndex.environmental.toFixed(2));
            userCompanySeries[1].data.push(data.companyIndex.social.toFixed(2));
            userCompanySeries[1].data.push(data.companyIndex.economic.toFixed(2));
            setUserAndCompanySeries(userCompanySeries);
        })
        .catch(error => setError(error));
    } 

   const getImpacts = (employee) => {
      employeeImpactService.getEmployeeImpact(employee.employeeId)
        .then(data => {
            !data.employeeImpact?setImpacts(defaultImpact):setImpacts(Object.values(data.employeeImpact).filter ( element => { 
              return typeof element=='object';
           }));
        })
        .catch(error => {
          setError("Error getting employee impacts:", error);
        }); 
    }

    const setEmployeeData = (employee) => {
        setEmployee(employee);
        setEmployeeFullName(!!employee?employee.name + ' ' + employee.lastName:'Loading name');
        setEmployeePhotoUrl(!!employee?employee.employeePhotoUrl:null);
        setEmployeeCompanyName(!!employee?employee.companyName:'Loading company name');
    }

    const getPillar = (pillar, action) => {
      if (pillar != undefined) {
        pillarService.getById(pillar)
          .then(data => {
            setPillar(data.pillar);
            setAction(action);
          })
          .catch(error => setError("Error getting pillar:", error))
        }
    }

    const getActionProposal = (employee) => {
      actionProposalService.getActionProposals(employee.employeeId)
        .then(data => {
            const actions = data.actionProposal.actions;
            if (actions != undefined) {
              const actionProposal = actions[actions.length-1];
              setActionProposal(actionProposal);
              actionService.getById(actionProposal.actionId)
                .then( data => getPillar(data.action.type, data.action));
            }
        })
    }
    
    useEffect( () => {
        const employee = JSON.parse(localStorage.getItem('employee'));
        if (employee && employee.filledRatingForm) {
          getActionProposal(employee);
          setEmployeeData(employee);
          getImpacts(employee);
          getSeries(employee);
        } else {
            history.push(ROUTES.USER_RATING);
        }
    }, []);

   
    const doImprovementAction = e => {
        improvementActionService.doImprovementAction(improvementAction).then(
          data => {
            fHideModalAction();
            setImprovementAction(data.improvementAction);
            //FIX IT - the component modification doesn't render it. We need to reload the home user page
            history.push(ROUTES.USER_HOME);
          });
    }

    const doActionProposal = e => {
        actionProposalService.doActionProposal(actionProposal, employee.employeeId).then(
          data => {
            fHideModalAction();
            setActionProposal(data.actionProposal);
            //FIX IT - the component modification doesn't render it. We need to reload the home user page
            history.push(ROUTES.USER_HOME);
          }
        )
    }

    const rejectActionProposal = e => {
      actionProposalService.rejectActionProposal(actionProposal, employee.employeeId).then(
        data => {
          fHideModalRejectAction();
          setImprovementAction(data.improvementAction);
          //FIX IT - the component modification doesn't render it. We need to reload the home user page
          history.push(ROUTES.USER_HOME);
        }
      )
    }

    return(
      <article id="main">
          <section className="wrapper style5">
              <div className="inner">
                  {error && <Alert color="danger">{error.message}</Alert>}
                  <section>
                      <div className="row">
                        <div className="col-5 col-6-medium">
                            <span className="image fit">
                              <span onClick={fShowModalPhoto} className="evidence">
                                <Avatar name={employeeFullName || defaultEmployeeFullName } src={employeePhotoUrl} round={true} size="120"/>
                              </span>
                            </span>
                            <b>{employeeFullName || defaultEmployeeFullName } </b>| <span className="greenlink">{employeeCompanyName || defaultEmployeeCompanyName}</span>
                          </div>
                      </div>
                  </section>
                  <section className="dashboard">
                    <h4>Tu índice sostenible</h4>
                    <div className="row">
                      <div className="col-5 col-12-medium">
                          <UserIndexRadialBarChart series={ employeeIndex || defaultUserSeries }/>
                      </div>
                      <div className="col-5 col-12-medium">
                          <UserCompanyIndexRadialBarChart series={ userAndCompanySeries || defaultUserCompanySeries }/>
                      </div>
                    </div>
                  </section>
                  <section className="dashboard">
                    <h4>Impacto 360</h4>
                    <div className="row">
                      <div className="col-12">
                          <Impact impacts={ impacts || defaultImpact} />
                       </div>
                    </div>
                  </section>
                  <section className="dashboard">
                    <h4>Propuesta de mejora 
                    { improvementAction && improvementAction.done ? <i className='green-header-important'>&nbsp;&nbsp;- Mejora realizada</i> : null }
                    { improvementAction && improvementAction.reject ? <i className='grey-header-important'>&nbsp;&nbsp;- Acción descartada</i> : null } 
                    </h4>
                    <div className="row">
                      <div className="col-12 col-12-medium">
                          <Card cardData={ pillar || defaultCard } action={ action || defaultAction} actionProposal={ actionProposal || defaultActionProposal } type={pillar} onclickAccept={fShowModalAction} onclickReject={fShowModalRejectAction}/>
                      </div>
                    </div>  
                  </section> 
                  <Modal show={showModalPhoto} handleClose={fHideModalPhoto} handleAction={fUploadPhoto} valueAction="Subir">
                    <Dropzone
                      onDrop={handleDrop}  
                      maxSize={1500000}
                      style={{ width: '250px', height: '250px' }}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <AvatarEditor
                                    image={employeePhotoUrl}
                                    width={250}
                                    height={250}
                                    border={50}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={1.2}/>
                                <input {...getInputProps()} />
                                <p>Arrastra y suelta aquí una foto de perfil</p>
                            </div>
                           )}
                      </Dropzone>
                  </Modal>
                  <Modal show={showModalAction} handleClose={fHideModalAction} handleAction={doActionProposal} valueAction="Añadir">
                      <h4>Añadir evidencia</h4>
                      <p>Puedes subir una foto, imagen o documento para validar que has realizado esta acción</p>
                      <textarea placeholder="Arrastra y Suelta (Pending)" rows="6" spellCheck="false" disabled={true}/>
                  </Modal>
                  <Modal show={showModalRejectAction} handleClose={fHideModalRejectAction} handleAction={rejectActionProposal} valueAction="Descartar">
                      <p>¿Estás seguro que quieres descartar esta acción? Si la descartas deberás esperar hasta que se genere la siguiente</p>
                   </Modal>
                  
              </div>
          </section>
      </article>
   );
}
    
export default Main;