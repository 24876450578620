import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts';

class EmployeesChart extends Component {

    render() {
     
     const stateRadialBar = {
        series: [0],
        colors: ["#20E647"],
        options: {
            chart: {
                height: 250,
                type: 'radialBar',
                offsetY: -10,
                toolbar: {
                    show: false,
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91]
                }
              },
              stroke: {
                dashArray: 4
              },
              labels: [this.props.label],
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                      name: {
                        fontSize: '16px',
                        color: undefined,
                        offsetY: 120
                      },
                      value: {
                        offsetY: 76,
                        fontSize: '22px',
                        color: undefined,
                        formatter: function (val) {
                          return val + "%";
                        }
                      }
                    }
                  }
              },
            },                
          }

        return(
            <ReactApexChart options={stateRadialBar.options} series={this.props.series} type="radialBar" height={200} />
        )
    }

}

export default EmployeesChart