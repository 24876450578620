import React, { Component } from 'react';

import Header from '../../App/Header';

import PasswordChangeForm from '../../PasswordChange';

import { loadJSv2 } from '../../../utils/script';

class UserProfilePage extends Component {

    UNSAFE_componentWillMount() {
        loadJSv2('/assets/js/main.js');
      }
    
    componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.remove('landing');
    document.getElementById("header").classList.remove("alt");
    }

    render() {
    
        return (
          [<Header key="companyHeader"/>,<PasswordChangeForm key="accountPasswordChangeForm"/>]
        );
      }
}

export default UserProfilePage;