import { useFetchWrapper} from '../helpers';

const useActionProposalService = () => {
 
    const baseUrl = `${process.env.REACT_APP_API_URL}/action-proposal`;
    const fetchWrapper = useFetchWrapper();

    return {
        getActionProposals,
        createActionProposal,
        doActionProposal,
        rejectActionProposal
    }

    function getActionProposals(employeeId) {
        return fetchWrapper.get(`${baseUrl}/${employeeId}`);
    }

    function createActionProposal(actionProposal) {
        return fetchWrapper.post(`${baseUrl}`,actionProposal);
    }

    function doActionProposal(actionProposal, employeeId) {
        return fetchWrapper.patch(`${baseUrl}/${employeeId}/do-action`);
    }

    function rejectActionProposal(actionProposal, employeeId) {
        return fetchWrapper.patch(`${baseUrl}/${employeeId}/reject-action`);
    }

}

export { useActionProposalService }