import { useFetchWrapper} from '../helpers';

const useActionService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/actions`;
    const fetchWrapper = useFetchWrapper();

    return {
        getAll,
        getById,
        getByPillar,
        getByPillarAndOrder,
    }

    function getAll() {
         return fetchWrapper.get(baseUrl);
    }

    function getById(actionId) {
        return fetchWrapper.get(`${baseUrl}/${actionId}`);
    }

    function getByPillar(pillar) {  
        return fetchWrapper.get(`${baseUrl}?type=${pillar}`);
    }

    function getByPillarAndOrder(pillar,order) {  
        return fetchWrapper.get(`${baseUrl}?type=${pillar}&order=${order}`);
    }
}

export { useActionService };
