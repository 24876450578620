import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

//Analytics interface
const analytics = firebase.analytics();

analytics.setAnalyticsCollectionEnabled(false);

//Authenticatoin interface
const authentication = firebase.auth();

//Storage interface
const storage = firebase.storage();

//authentication.setPersistence(firebase.auth.Auth.Persistence.SESSION);


if (window.location.hostname === "localhost") {
    authentication.useEmulator('http://localhost:9099/');
    storage.useEmulator('localhost',9199);
  }

export {
    analytics,
    authentication,
    storage,
    firebase,
};