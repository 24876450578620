import React , {Component} from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

class CTA extends Component {

    render() {
        return (
            <section id="cta" className="wrapper style4">
                <div className="inner">
                    <header>
                        <h2>Diferénciate de la competencia</h2>
                        <p>Impulsa la sostenibilidad desde ahora gracias a Uncon Corporate.</p>
                    </header>
                    <ul className="actions stacked">
                        <li><Link to={ROUTES.FIND_OUT} className="button fit primary"> Descúbrelo</Link></li>
                        <li><Link to={ROUTES.CONTACT} className="button fit"> Saber Más</Link></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default CTA;