import React, { Component } from 'react';

import Header from '../App/Header';
import Banner from '../App/Banner';
import SectionOne from '../App/SectionOne';
import SectionTwo from '../App/SectionTwo';
import SectionThree from '../App/SectionThree';
import CTA from '../App/CTA';
import Footer from '../App/Footer';


import { loadJSv2 } from '../../utils/script';


class LandingPage extends Component {

  UNSAFE_componentWillMount() {
   // loadJS('assets/js/main.js');
    loadJSv2('/assets/js/main.js');
  }
  
  render() {
   return (
          [<Header key="landingHeader"/>,
           <Banner  key="landingBanner"/>,
           <SectionOne  key="landingSectionOne"/>,
           <SectionTwo  key="landingSectionTwo"/>,
           <SectionThree  key="landingSectionThree"/>,
           <CTA  key="landingCTA"/>,
           <Footer  key="landingFooter"/>]
    );
  }
}

export default LandingPage;