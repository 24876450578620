import { useFetchWrapper } from "../helpers";

const useCompanyImpactService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/company-impacts`;
    const fetchWrapper = useFetchWrapper();

    return {
        post,
        getByCompanyId,
        updateCompanyImpactFromEmployeeImpact
    }

    function post(companyImpact) {
        return fetchWrapper.post(baseUrl,companyImpact);
    }

    function getByCompanyId(companyId) {
        return fetchWrapper.get(`${baseUrl}/${companyId}`);
    }

    function updateCompanyImpactFromEmployeeImpact(companyId, employeeImpact) {
        return fetchWrapper.patch(`${baseUrl}/${companyId}/calculate`,employeeImpact);
    }
}

export { useCompanyImpactService};
