import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
 
import { authentication} from '../../Firebase';

import { Alert } from 'reactstrap';
import * as ROUTES from '../../../constants/routes';

import { userMapper } from '../../../utils/mapper';

import { history } from '../../App/Header';

import { useEmployeeService } from '../../../services/employee.service';
import { authAtom } from '../../../state';

 
const Main = () => {

    const employeeService = useEmployeeService();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const setAuth = useSetRecoilState(authAtom);

    const redirectLogin = (uid) => {

        employeeService.getByUid(uid)
        .then(data => {
            localStorage.setItem('employee',JSON.stringify(data.employee));
            history.push(ROUTES.USER_HOME);
        })
        .catch(error => {
            setError(error);
        });
  }

  const onSubmit = event => {
    
        authentication.signInWithEmailAndPassword(email.trim(), password)
        .then((userAuth) => { 
            const userMapped = userMapper(JSON.parse(JSON.stringify(userAuth.user)));
            localStorage.setItem('user', JSON.stringify(userMapped));
            setAuth(userMapped);
            redirectLogin(userAuth.user.uid);
        })
        .catch(error => {
            setError(error);
        });
  
      event.preventDefault();
  };

  const isInvalid = password === '' || email === '';
 
  return (
    <article id="main">
        <section className="wrapper style5">
            <div className="inner">
                <section id="main" className="container">
                    <div className="box"> 
                          <h3>Te damos la bienvenida a la zona de empleados</h3>
                          <h5>Inicia sesión en tu cuenta</h5>
                          {error && <Alert color="danger">{error.message}</Alert>}
                          <form onSubmit={onSubmit}>
                              <div className="row gtr-50 gtr-uniform">
                                  <div className="col-12 col-12-mobilep">
                                      <span className="label-input100">Email</span>
                                      <input
                                          name="email"
                                          value={email}
                                          onChange={({ target }) => setEmail(target.value)}
                                          type="text"
                                          placeholder="Introduce tu email"
                                          />
                                  </div>
                                  <div className="col-12 col-12-mobilep">
                                      <span className="label-input100">Contraseña</span>
                                      <input
                                          name="password"
                                          value={password}
                                          onChange={({ target }) => setPassword(target.value)}
                                          type="password"
                                          placeholder="Introduce tu contraseña"/>
                                  </div>                   
                                  <div className="col-12">
                                          <ul className="actions special">
                                              <li><input className="primary" disabled={isInvalid} type="submit" value="Login"/></li>
                                          </ul>
                                  </div>
                                </div>
                            </form>
                      </div>          
                </section>
              </div>
          </section>
      </article> 
  )
}
  
export default withRouter(Main);