import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts';

class HistoricalCompanyIndexChart extends Component {

    render() {

        const months = ['Ene','Feb','Mar','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'];
     
        const stateColumnChart = {
            series: [{
                name: 'Económico',
                data: [1,2,3,4,5,6,7,8,9,10,11,12]
                }, {
                name: 'Ambiental',
                data: [1,2,3,4,5,6,7,8,9,10,11,12]
                }, {
                name: 'Social',
                data: [1,2,3,4,5,6,7,8,9,10,11,12]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Ene','Feb','Mar','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
                },
                yaxis: {
                    title: {
                    text: 'Índice Sostenible'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                }
            }
        };
        

        const num_months = this.props.series ? this.props.series[0].data.length : 0;
        stateColumnChart.options.xaxis.categories = months.slice(0, num_months);
        
        return(
            <ReactApexChart options={stateColumnChart.options} series={ this.props.series }  type="bar" height={200} />
        )
    }
}

export default HistoricalCompanyIndexChart;