import React from 'react';

import { withAuthorization } from '../../Session';
import { withRouter }  from 'react-router-dom';

const Main = () => {

    return(
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <div className="row">
                            <div className="col-12 col-12-mobilep">
                                <h3 className="medium">Acciones de los empleados</h3>  
                                <h5 className="medium">Añade nuevas acciones para tus empleados</h5>
                            </div>
                        </div>
                    </section>
                    <hr className="minor"/>
                    <section>
                            <div><i>Solo para los planes <b>Professional</b> y <b>Premium</b></i></div>
                    </section>
                </div>
            </section>
        </article>
    );
}


const condition = authUser => !!authUser;  

export default withAuthorization(condition)(withRouter(Main));
