import React , {Component} from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import * as ROUTES from '../../constants/routes';

const Banner = ()=> {

    const { t, i18n } = useTranslation();

    return (
        <section id="banner">
            <div className="inner">
                <h2>Uncon Corp</h2>
                <p>Caminando hacia la <br />
                sostenibilidad real<br />
                </p>
                <ul className="actions special">
                    <li>
                        <Link to={ROUTES.FIND_OUT} className="button primary"> { t('Find out') }</Link>
                    </li>
                </ul>
            </div>
            <a href="#one" className="more scrolly">Saber Más</a>
        </section>
    )
}

export default Banner;