import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts';

class ImpactCompanyChart extends Component {

    render() {
     
        const stateBarChart = {
            series: [{
                name: "Impacto",
                data: [0]
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false,
                    }
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['South Korea'],
                }
            }
        };

        const impacts = this.props.series;
        var dataSeries = new Array();
        var categories = new Array();
        const dataImpact = [{
            data: [0]
        }];

        for (const impact of impacts) {
            
            dataSeries.push(Math.round(impact.value));
            categories.push(impact.name);
        }

        stateBarChart.options.xaxis.categories = categories;
        dataImpact[0].data = dataSeries;
        stateBarChart.series = dataImpact;

        return(
            <ReactApexChart options={stateBarChart.options} series={ stateBarChart.series }  type="bar" height={200} />
        )
    }
}

export default ImpactCompanyChart