import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { contactMapper } from '../../utils';
import { useContactService } from '../../services/contact.service';

import * as ROUTES from '../../constants/routes';

const Main = () => {

    const contactService = useContactService();

    const [dataForm, setDataForm] = useState({
        form: {
            name: '',
            email: '',
            subject: '',
            message: '',
            privacy: false,
        }
    });

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const updateForm = (key, value) => {
        const { form } = dataForm;
        form[key] = value;
        setDataForm({
            ...dataForm,
            form,
        });
    };

    const initializeStates = () => {
        setDataForm({
            form: {
                name: '',
                email: '',
                subject: '',
                message: '',
                privacy: false,
            }
        });
    }

    const update = (e) => {
        updateForm(e.target.name, e.target.value);
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        updateForm(e.target.name, value);
    }

    const onSubmit = (event) => {

        const contactMapped = contactMapper(dataForm.form.email,dataForm.form.name,dataForm.form.message,dataForm.form.subject);
        contactService.create(contactMapped)
            .then( () => {
                initializeStates();
                setSuccess(true);
                setError(false);
            })
            .catch( error => {
                initializeStates();
                setSuccess(false);
                setError(true);
            });
        
        event.preventDefault();
    }

    const { name, email, subject, message, privacy } = dataForm;
    const isInvalid = dataForm.form.name === '' || dataForm.form.email === '' || dataForm.form.subject === '' || dataForm.form.message === '' || !dataForm.form.privacy;

    return(
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <div className="box"> 
                            <header>
                                <h4>Contacta con nostros</h4>
                                <p>Cuéntanos lo que necesitas y estaremos encantados de ayudarte</p>
                            </header>
                            { success ? 
                                <Alert color="success">
                                    Muchas gracias por escribirnos! En breve nos podremos en contacto contigo
                                </Alert>   
                                : 
                                null 
                            }
                            { error ? 
                                <Alert color="danger">
                                    Ups... algo ha ido mal. Disculpa las molestias. Siempre nos puedes escribir 
                                    a <b>hola@unconcorp.com</b>
                                </Alert>   
                                : 
                                null
                            }
                            <form onSubmit={onSubmit}>
                                <div className="row gtr-50 gtr-uniform">
                                    <div className="col-6 col-12-mobilep">
                                        <input type="text" name="name" id="name" value={dataForm.form.name} placeholder="Nombre" 
                                            onChange={update}/>
                                    </div>
                                    <div className="col-6 col-12-mobilep">
                                        <input type="email" name="email" id="email"  value={dataForm.form.email} placeholder="Email" 
                                        onChange={update}/>
                                    </div>
                                    <div className="col-12">
                                        <input type="text" name="subject" id="subject" value={dataForm.form.subject} placeholder="Asunto" 
                                        onChange={update}/>
                                    </div>
                                    <div className="col-12">
                                        <textarea name="message" id="message" placeholder="Mensaje" rows="6" value={dataForm.form.message}
                                        onChange={update}></textarea>
                                    </div>
                                    <div className="col-12">
                                        <input type="checkbox"  name="privacy" checked={dataForm.form.privacy} id="privacy"
                                        onChange={handleInputChange}/>
                                        <label htmlFor="privacy">He leído y acepto la <Link to={ROUTES.PRIVACY}>política de privacidad</Link></label>
                                    </div>
                                    <div className="col-12">
                                        <ul className="actions special">
                                            <li><input type="submit" value="Enviar mensaje" disabled={isInvalid}
                                            onChange={update} className="primary"/></li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </section>
        </article>
    );
}

export default Main;