import React from 'react';

const AdminUsersTable = ({data}) => {

    return(
        <div className="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Apellidos</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                {data.map( data => (
                    <tr key={data.email}>
                        <td>{data.name}</td>
                        <td>{data.lastName}</td>
                        <td>{data.email}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default AdminUsersTable;