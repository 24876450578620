import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts';

class EmployeesRadialBarChart extends Component {

    render() {
     
     const stateRadialBar = {
        series: [0],
        colors: ["#20E647"],
        options: {
            chart: {
                height: 280,
                type: 'radialBar',
            },
            fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: 'vertical',
                  gradientToColors: ['#87D4F9'],
                  stops: [0, 100]
                }
              },
              stroke: {
                lineCap: "round"
              },
              labels: ["Empleados en Uncon"],
            plotOptions: {
                radialBar: {
                  hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#2E3942',
                  },
                  track: {
                    dropShadow: {
                      enabled: true,
                      top: 2,
                      left: 0,
                      blur: 4,
                      opacity: 0.15
                    }
                  },
                  dataLabels: {
                    name: {
                      offsetY: -10,
                      color: "#fff",
                      fontSize: "13px"
                    },
                    value: {
                      color: "#fff",
                      fontSize: "30px",
                      show: true
                    }
                  }
                }
              },
            },                
          }

        return(
            <ReactApexChart options={stateRadialBar.options} series={this.props.series} type="radialBar" height={280} />
        )
    }

}

export default EmployeesRadialBarChart