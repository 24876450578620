import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts'

class CompanyIndexRadialBarChart extends Component {

    render() {

      const stateBarCompany = {
        series: [
          { 
            name: 'Demo Company',
            data: [66, 55, 33], 
          }
        ],
        colors:['#4C9F38', '#FEB714', '#9C27B0'],
        options: {
          chart: {
            id: 'compareWithCompanyBarChart',
            type: 'bar',
            toolbar: {
              show: false,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: true,
                zoom: false,
              }
            },
            zoom: {
              enabled: false,
            }
          },
          colors: ['#4C9F38','#FEB714', '#EA1E2D' ],
          dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#fff']
              }
          },
          fill: {
              colors: ['#4C9F38','#FEB714','#EA1E2D']
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              }
            },
          },
          legend: {
              labels: {
                  colors: ['#4C9F38','#FEB714','#EA1E2D'],
                  useSeriesColors: true,
              }
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            }
          },
          stroke: {
              show: true,
              width: 1,
              colors: ['#fff']
            },
          tooltip: {
              enabled: true,
              y: {
                  formatter: undefined,
                  title: {
                      formatter: (seriesName) => seriesName,
                  },
              },
          },
          xaxis: {
            type: 'category',
            categories: ['Ambiental', 'Social', 'Económica'],
          },
          yaxis: {
            show: true,
          },
        },
      };

      return(
            <ReactApexChart options={stateBarCompany.options} series={stateBarCompany.series} type="bar" height={310} />
      )
    }

}

export default CompanyIndexRadialBarChart