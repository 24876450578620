import { analytics } from './firebase';

export const logEvent = (event) =>
    analytics.logEvent(event);

export const setAnalyticsCollectionEnabled = (bool) =>
    analytics.setAnalyticsCollectionEnabled(bool);

export const setCurrentScreen = (screenName) =>
    analytics.setCurrentScreen(screenName);

export const setUserId = (id) =>
    analytics.setUserId(id);

export const setUserProperties = (properties) =>
    analytics.setUserProperties(properties);