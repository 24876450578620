import React , {Component} from 'react';

class SectionThree extends Component {

    render() {
        return (
            <section id="three" className="wrapper style3 special">
                <div className="inner">
                    <header className="major">
                        <h2>cómo ser 100% sostenible</h2>
                    </header>
                    <ul className="features">
                        <li className="icon solid fa-tachometer-alt">
                            <h3>Mide</h3>
                            <p>Medimos la sostenbilidad actual de tu compañía gracias a nuestro rating de empleados.</p>
                        </li>
                        <li className="icon solid fa-stethoscope">
                            <h3>Analiza</h3>
                            <p>Con los datos obtenidos analizamos y elaboramos un plan de mejora a diferentes niveles y alineado con los objetivos de desarrollo sostenible de las Naciones Unidas.</p>
                        </li>
                        <li className="icon solid fa-clock">
                            <h3>Acciona</h3>
                            <p>Las acciones asociadas al plan más adecuado para tu compañía, te permitirán conseguir mejores resultados en menor tiempo.</p>
                        </li>
                        <li className="icon solid fa-rocket">
                            <h3>Evoluciona</h3>
                            <p>Los planes cada vez serán más sofisticados y alineados con tu plan estratégico de compañía.</p>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default SectionThree;