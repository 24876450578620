import { useFetchWrapper } from "../helpers";

const useCompanyService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/companies`;
    const fetchWrapper = useFetchWrapper();

    return {
        post,
        patch,
        get,
        updateCompanyPayment,
        updateCompany,
        getBasicData
    }

    function post(company) {
        return fetchWrapper.post(baseUrl,company);
    }

    function patch(companyId) {
        return fetchWrapper.patch(`${baseUrl}/${companyId}`);
    }

    function updateCompanyPayment(companyId) {
        return fetchWrapper.patch(`${baseUrl}/${companyId}`);
    }

    function updateCompany(company) {
        return fetchWrapper.patch(`${baseUrl}/${company.companyId}`, company);
    }

    function get(companyId) {
        return fetchWrapper.get(`${baseUrl}/${companyId}`);
    }

    function getBasicData(companyId) {
        return fetchWrapper.get(`${baseUrl}/${companyId}/basic`);
    }
}

export { useCompanyService};
