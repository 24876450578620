export const defaultEmployeeRatingForm = {
    'social-p2': false,
    'social-p2-aux-economic-less':  false,
    'social-p2-aux-economic-more':  false,
    'social-p2-aux-work-less': false,
    'social-p2-aux-work-more': false,
    'environmental-p3':  false,
    'environmental-p3-aux-plastic':  false,
    'environmental-p3-aux-glass':  false ,
    'environmental-p3-aux-cardboard':  false ,
    'environmental-p4':  false ,
    'environmental-p4-aux-shower':  false ,
    'environmental-p4-aux-tap':  false ,
    'environmental-p4-aux-atomizer':  false ,
    'environmental-p4-aux-toilet':  false ,
    'environmental-p5':  false ,
    'environmental-p5-aux-led':  false ,
    'environmental-p5-aux-standby':  false ,
    'environmental-p5-aux-temp':  false ,
    'environmental-p6-car':  false ,
    'environmental-p6-hybrid':  false ,
    'environmental-p6-electric':  false ,
    'environmental-p6-public':  false ,
    'environmental-p6-bike':  false ,
    'economic-p7':  false ,
    'economic-p8':  false ,
    'economic-p9':  false ,
}