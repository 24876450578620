import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import Header from '../../App/Header';
import Main from './Main';

import { loadJSv2 } from '../../../utils/script';

import { withAuthorization } from '../../Session';


class UserHomePage extends Component {

  UNSAFE_componentWillMount() {
    loadJSv2('/assets/js/main.js');
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.remove('landing');
    document.getElementById("header").classList.remove("alt");
  }

  render() {
    return (
        [<Header key="homeHeader"/>,<Main key="homeMain"/>]
      );
  }
}

//const condition = authUser => authUser && !!authUser.roles[ROLES.EMPLOYEE];

//!!authUser.roles[ROLES.ADMIN];

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withRouter(UserHomePage));