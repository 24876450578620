import React, { Component } from 'react';

class Main extends Component {
    render() {
        return(
            <article id="main">
                <header>
                    <h2>Política de Cookies</h2>
                </header>
                <section class="wrapper style5">
                    <div class="inner">
                        <div className="row-6 row-12-mobilep">
                        <h3 className="greenlink">¿QUÉ ES UNA COOKIE?</h3>
                            <p>
                                Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada 
                                usuario para que el servidor recuerde cierta información que posteriormente pueda utilizar.
                            </p>
                        </div>
                        <div className="row-6 row-12-mobilep">
                            <h3 className="greenlink">¿QUÉ TIPO DE COOKIES EXISTEN?</h3>
                            <p><b className="greenlink">Cookies técnicas</b>: Son las más elementales y permiten, entre otras cosas, saber cuándo está navegando un humano o una aplicación automatizada, cuándo navega un usuario anónimo y uno registrado, tareas básicas para el funcionamiento de cualquier web dinámica.
                                <br/><br/>
                                <b className="greenlink">Cookies de personalización</b>: Permiten al usuario acceder a un servicio con algunas características de carácter general predefinidas en función de criterios propios del terminal que usa (idioma, tipo de navagador, configuración regional…).
                                <br/><br/>
                                <b className="greenlink">Cookies de análisis</b>: Recogen información anónima sobre el comportamiento de los usuarios en los sitios web con la finalidad de medir y analizar la actividad del sitio web en cuestión para introducir mejoras en función de los datos de uso.
                                <br/><br/>
                                <b className="greenlink">Cookies publicitarias</b>: Permiten gestionar los espacios publicitarios de una web de manera más eficaz, en relación al contenido editado o la frecuencia con la que se muestran los anuncios.
                                <br/><br/>
                                <b className="greenlink">Cookies de publicidad comportamental</b>: Permiten observar de manera continuada los hábitos de navegación de los usuarios de una web con la finalidad de desarrolar perfiles específicos y mostrar publicidad en función de los mismos.
                            </p>
                        </div>
                        <div className="row-6 row-12-mobilep">
                            <h3 className="greenlink">TIPOS DE COOKIES QUE UTILIZAMOS</h3>
                            <p>
                                Esta página web utiliza tanto cookies propias como de terceros, que son aquellas que se envían a tu ordenador o terminal desde un dominio o una página web que no es gestionada por nosotros, 
                                sino por otra entidad que trata los datos obtenidos a través de las cookies.<br/>
                                En este caso las Cookies son utilizadas con fines de seguridad y estadísticos relacionados con las visitas que recibe esta web y las páginas (de la propia web) que se consultan, 
                                quedando aceptado su uso al navegar por ella.
                            </p>
                        </div>
                        <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Proveedor de Servicio</th>
                                            <th>Nombre de cookie</th>
                                            <th>Tipo</th>
                                            <th>Propósito</th>
                                            <th>Descripción</th>
                                            <th>Expiración</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><a className="greenlink" href="https://support.cloudflare.com/hc/en-us/articles/200170156-What-does-the-CloudFlare-cfduid-cookie-do-">CloudFlare</a></td>
                                            <td>__cfduid</td>
                                            <td>Técnica</td>
                                            <td>Estrictamente necesaria</td>
                                            <td>La cookie es establecida por el servicio de CloudFlare 
                                                para identificar tráfico web de confianza. No guarda ningún dato personal 
                                                identificable.</td>
                                            <td>1 mes</td>
                                        </tr>
                                        <tr>
                                            <td>unconcorp.com</td>
                                            <td>rcl_consent_given</td>
                                            <td>Técnica</td>
                                            <td>Estrictamente necesaria</td>
                                            <td>Almacena el consentimiento del usuario web de las cookies estrictamente necesarias
                                                para operar con los servicios de unconcorp.com</td>
                                            <td>1 año</td>
                                        </tr>
                                        <tr>
                                            <td>unconcorp.com</td>
                                            <td>rcl_preferences_consent</td>
                                            <td>Técnica</td>
                                            <td>Estrictamente necesaria</td>
                                            <td>
                                                Almacena el consentimiento del usuario web de las cookies necesarias
                                                para operar con los servicios de unconcorp.com
                                            </td>
                                            <td>1 año</td>
                                        </tr>
                                        <tr>
                                            <td>unconcorp.com</td>
                                            <td>rcl_statistics_consent</td>
                                            <td>Técnica</td>
                                            <td>Estrictamente necesaria</td>
                                            <td>Si existe, almacenará el consentimiento del usuario que permite
                                                el uso de servicios de analítica web
                                            </td>
                                            <td>1 año</td>
                                        </tr>
                                        <tr>
                                            <td><a className="greenlink" href="https://policies.google.com/technologies/cookies?hl=es&gl=es#types-of-cookies">Google Analytics</a></td>
                                            <td>_ga, _ga_XXXXXXXXX</td>
                                            <td>Análisis</td>
                                            <td>Medición</td>
                                            <td>Esta cookie se utiliza para distinguir usuarios únicos asignando un número generado aleatoriamente como identificador de cliente. 
                                                Se incluye en cada solicitud de página en un sitio y se utiliza para calcular los datos de visitantes, sesiones y campañas para 
                                                los informes de análisis de sitios. </td>
                                            <td>2 años</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        <div className="row-6 row-12-mobilep">
                            <p>
                                Si desea más información más sobre los tipos de cookies de seguimiento y análisis de datos de 
                                Google haga <a className="greenlink" href="https://policies.google.com/technologies/cookies?hl=es&gl=es#types-of-cookies">clic aquí</a>
                            </p>
                        </div>
                        <div className="row-6 row-12-mobilep">
                            <h3 className="greenlink">¿SE PUEDEN ELIMINAR LAS COOKIES?</h3>
                            <p>Sí, y no sólo eliminar, también se pueden bloquear, de forma general o particular para un dominio específico.
                                Para eliminar las cookies de un sitio web debes ir a la configuración de tu navegador y allí podrás buscar las asociadas 
                                al dominio en cuestión y proceder a su eliminación.
                            </p>
                            <p>
                            Para informarse sobre cómo eliminar las cookies de su explorador:
                            <br></br>
                            <ul>
                                <li>
                                    <a className="greenlink" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">
                                        Firefox
                                    </a>
                                </li>
                                <li>
                                    <a className="greenlink" href="https://support.google.com/chrome/answer/95647?hl=es">Chrome</a>
                                </li>
                                <li>
                                    <a className="greenlink" href="https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet Explorer</a>
                                </li>
                                <li>
                                    <a className="greenlink" href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">Safari</a>
                                </li>
                                <li>
                                    <a className="greenlink" href="https://www.opera.com/es/case-studies/clean-browser-and-remove-trackers#:~:text=data%20is%20selected.-,Click%20Clear%20data.,icon%20to%20delete%20individual%20cookies.">Opera</a>
                                </li>
                            </ul>
                            </p>
                        </div>
                        <div className="row-6 row-12-mobilep">
                            <h3 className="greenlink">MÁS INFORMACIÓN SOBRE LAS COOKIES</h3>
                            <p>Puedes consultar el reglamento sobre cookies publicado por la Agencia Española de Protección
                                 de Datos en su Guía sobre el uso de las cookies y obtener más información sobre las cookies 
                                 en Internet en <a className="greenlink" href="https://aboutcookies.org">aboutcookies.org</a>.
                                <br/>
                                Si deseas tener un mayor control sobre la instalación de cookies, puedes instalar 
                                programas o complementos en tu navegador, conocidos como herramientas 
                                de <span className="greenlink">Do Not Track</span>, 
                                que te permitirán escoger aquellas cookies que deseas permitir.</p>
                        </div>
                    </div>
                </section>
            </article>
            
         );
    }
}
    
export default Main;