import React, { Component } from 'react';
import CompanyHeader from '../App/CompanyHeader';
import { loadJSv2 } from '../../utils/script';
import Dashboard from './Dashboard';

class CompanyDashboardPage extends Component {

  UNSAFE_componentWillMount() {
    loadJSv2('/assets/js/main.js');
  }
  
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.classList.remove('landing');
    document.getElementById("header").classList.remove("alt");
  }

  render() {
    return (
        [<CompanyHeader key="companyHeader"/>,<Dashboard key="companyDashboard"/>]
      );
  }
}

//const condition = authUser => !!authUser;

//export default withAuthorization(condition)(HomePage);

export default CompanyDashboardPage;