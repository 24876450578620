import React, { Component } from 'react';

import Header from '../../App/ReportHeader';
import Footer from '../../App/ReportFooter';
import Main from './Main';

class CompanyReportPage extends Component {

    render() {
    
        return (
          [<Header key="reportCompanyHeader"/>,<Main key="reportCompanyMain"/>,<Footer key="reportCompanyFooter"/>]
        );
      }
}

export default CompanyReportPage;