import React , {Component} from 'react';

class SectionTwo extends Component {

    render() {
        return (
            <section id="two" className="wrapper alt style2">
                <section className="spotlight">
                    <div className="image"><img src="/images/businesspeople.jpg" alt="" /></div><div className="content">
                        <h2>Empieza a <br />
                        medir</h2>
                        <p>Nos basamos en los ODS (Objetivos de Desarrollo Sostenible) para elaborar un rating sostenible</p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src="/images/blur-portrait-team.jpg" alt="" /></div><div className="content">
                        <h2>Apóyate en <br />
                        tus empleados</h2>
                        <p>Son tu mayor valor dentro de la compañía y los que te ayudarán a evolucionar hacia un modelo de empresa 100% sostenible</p>
                    </div>
                </section>
                <section className="spotlight">
                    <div className="image"><img src="/images/group-casually.jpg" alt="" /></div><div className="content">
                        <h2>Acciona los <br />
                        mecanismos </h2>
                        <p>Descubre qué palancas puedes accionar para mejorar en sostenibilidad</p>
                    </div>
                </section>
            </section>
        )
    }
}

export default SectionTwo;