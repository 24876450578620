import React , {Component} from 'react';

class SectionOne extends Component {

    render() {
        return (
            <section id="one" className="wrapper style1 special">
                <div className="inner">
                    <header className="major">
                        <h2>Innovación para tu estrategia sostenible <br/>
                         
                        </h2>
                        <p>Nuestro modelo y algoritmos permiten incrementar exponencialmente tu imagen de empresa sostenible
                        </p>
                    </header>
                    <ul className="icons major">
                        <li><span className="icon solid fa-users major style1"><span className="label">Lorem</span></span></li>
                        <li><span className="icon solid fa-cogs major style2"><span className="label">Ipsum</span></span></li>
                        <li><span className="icon solid fa-tree major style3"><span className="label">Dolor</span></span></li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default SectionOne;