import React , {Component} from 'react';

import { analytics } from '../Firebase';

import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

class Footer extends Component {

    handleOnAccept() {
        
    }

    handleOnAcceptStatistics() {
        analytics.setAnalyticsCollectionEnabled(true);
    }

    render() {
        return (
            <footer id="footer">
                <ul className="icons">
                    <li><a href="https://www.twitter/unconcorp" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
                    <li><a href="https://www.facebook.com/unconcorp" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
                    <li><a href="https://www.instagram.com/unconcorp" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
                    <li><a href="https://www.dribble.com.com/unconcorp" className="icon brands fa-dribbble"><span className="label">Dribbble</span></a></li>
                    <li><a href="mailto:hola@unconcorp.com" className="icon solid fa-envelope"><span className="label">Email</span></a></li>
                </ul>
                <ul className="copyright">
                    <li>&copy; uncon corp</li><li>Particulares <a href="http://unconpeople.com">Uncon People</a></li>
                </ul>
                <ul className="copyright">
                    <li><Link to={ROUTES.PRIVACY}>política de privacidad</Link></li>
                </ul>
                <ul className="copyright">
                    <li>
                        <a href="https://www.freepik.es/vectores/abstracto" className="vas">Vector de Abstracto creado por pch.vector - www.freepik.es</a>
                    </li>
                </ul>
            </footer>
        )
    }
}

export default Footer;