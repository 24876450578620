import React, { useState } from 'react';
import Stats from './Stats';
import { Alert } from 'reactstrap';

import { useEmployeeRatingService } from '../../../services/employeeRating.service';
import { useEmployeeIndexService } from '../../../services/employeeIndex.service';
import { useCompanyIndexService } from '../../../services/companyIndex.service';
import { useEmployeeImpactService } from '../../../services/employeeImpact.service';
import { useCompanyImpactService } from '../../../services/companyImpact.service';
import { useEmployeeService } from '../../../services/employee.service';
import { useImprovementActionService } from '../../../services/improvementAction.service';
import { useActionService } from '../../../services/action.service';
import { useActionProposalService } from '../../../services/actionProposal.service';

import { employeeIndexMapper, 
        employeeRatingMapper, 
        companyIndexFromEmployeeIndexMapper,
        employeeImpactMapper,
        improvementActionMapper,
        actionProposalMapper } from '../../../utils/mapper';

import { getEmployeePreferences, getRandomKeyMap } from '../../../utils/utility';

import { history } from '../../App/Header';
import * as ROUTES from '../../../constants/routes';

const StepThree = (props) => {

    const employeeRatingService = useEmployeeRatingService();
    const employeeIndexService = useEmployeeIndexService();
    const companyIndexService = useCompanyIndexService();
    const employeeImpactService = useEmployeeImpactService();
    const companyImpactService = useCompanyImpactService();
    const employeeService = useEmployeeService();
    const improvementActionService = useImprovementActionService();
    const actionService = useActionService();
    const actionProposalService = useActionProposalService();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    
    async function calculateEmployeeAndCompanyImpact(employee, rating) {
        const promiseCalculateEmployeeImpact = 
            await employeeImpactService.calculateEmployeeImpactFromRating(employee.employeeId,rating)
                .then( data => data);
        if (promiseCalculateEmployeeImpact) {
            const employeeImpactMapped = employeeImpactMapper(promiseCalculateEmployeeImpact.employeeImpact);
            await companyImpactService.updateCompanyImpactFromEmployeeImpact(employee.companyId,employeeImpactMapped)
            .then( data => data);
        }
    }

    async function calculateEmployeeAndCompanyIndex(employee,employeeRating) {
        const promiseCalculateEmployeeIndex = 
            await employeeIndexService.calculateEmployeeIndexFromRating(employee.employeeId,employeeRating)
                .then( data => data);
        if (promiseCalculateEmployeeIndex) {
            const employeeIndexMapped = employeeIndexMapper(promiseCalculateEmployeeIndex.employeeIndex.economic,promiseCalculateEmployeeIndex.employeeIndex.environmental,promiseCalculateEmployeeIndex.employeeIndex.social);
            const companyIndexFromEmployeeIndexMapped = companyIndexFromEmployeeIndexMapper(employeeIndexMapped,props.totalEmployees, employee.employeeId);
            await companyIndexService.calculateCompanyIndexFromEmployeeIndex(employee.companyId,companyIndexFromEmployeeIndexMapped)
                    .then(data => data);
        }
    }

    

    async function createNewActionProposal(employee,employeeRating) {
        const preferences = getEmployeePreferences(employeeRating.form);
        const filteredPreferencesTrue = new Map([...preferences].filter(x => x[1]));
        const key = getRandomKeyMap(filteredPreferencesTrue);
        const promiseGetByPillarAndOrder = 
            await actionService.getByPillarAndOrder(key,1);
        if (promiseGetByPillarAndOrder) {
            let actions = [{
                actionId: promiseGetByPillarAndOrder.actions[0].actionId,
                type: promiseGetByPillarAndOrder.actions[0].type,
                order: promiseGetByPillarAndOrder.actions[0].order,
                actual: true,
                reject: false,
                done: false
            }];
            const actionProposalMapped = actionProposalMapper(employee.employeeId,actions);
            await actionProposalService.createActionProposal(actionProposalMapped)
                .then(data => data);
        }
    }
    

    function updateFillRatingForm(employee) {
        employee.filledRatingForm = true;
        employeeService.updateEmployee(employee)
            .then ( () => { 
                localStorage.setItem('employee',JSON.stringify(employee));
                history.push(ROUTES.USER_HOME);
            })
            .catch( error => {
                setError("An error happens ", error);
                setLoading(true);
            });
    }
    
    const submit = () => {
        setLoading(true);
        const employee = JSON.parse(localStorage.getItem('employee'));
        if (employee) {
            const employeeRatingMapped = employeeRatingMapper(props.form);
            employeeRatingService.update(employee.employeeId,employeeRatingMapped)
                .then( () => {
                    Promise.all([
                            calculateEmployeeAndCompanyIndex(employee,employeeRatingMapped),
                            calculateEmployeeAndCompanyImpact(employee,employeeRatingMapped),
                            createNewActionProposal(employee,employeeRatingMapped)])
                        .then(() => {
                            updateFillRatingForm(employee);
                         })
                        .catch( error => {
                            setError("An error happens ", error);
                            setLoading(false);
                        });
                })
                .catch(error => {
                    setError(error);
                });
        }
    };

    return (
         <div>
            <div className={'text-center'}>
                {error && <Alert color="danger">{error.message}</Alert>}
                <SectionEconomic update={props.update} state={props.form}/>
            </div>
            <Stats step={4} {...props} nextStep={submit} />
            {loading ? 
                <div className="Loader">
                    <img src="/assets/css/images/Spin-1s-100px.gif" alt="loading"></img>
                </div> : null}
        </div>
            
    );
};

export default StepThree;


const SectionEconomic = (props) => {

    const updateEconomicP7 = (e) => {
        e.target.id === 'economic-p7-yes' ? props.update(e.target.name, true) : props.update(e.target.name, false);
    };

    const updateEconomicP8 = (e) => {
        e.target.id === 'economic-p8-yes' ? props.update(e.target.name, true) : props.update(e.target.name, false);
    };

    const updateEconomicP9 = (e) => {
        e.target.id === 'economic-p9-yes' ? props.update(e.target.name, true) : props.update(e.target.name, false);
    };

    return (
        <section>
                <div className="row row-form-multistep">
                    {!!JSON.parse(localStorage.getItem('employee')).filledRatingForm  && <Alert color="warning">El cuestionario ya ha sido respondido. Desde aquí únicamente podrás 
                    modificar tus áreas de interés. Para seguir progresando en tu rating de sostenbilidad, 
                    puedes hacerlo a través de tus <a href="/">acciones personalizadas</a> </Alert>}
                    <div className="col-12 col-12-small">
                        <label>
                            Al comprar productos de alimentación, ¿tienes en cuenta la cercanía en su producción?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='economic-p7' id='economic-p7-yes'
                            onChange={updateEconomicP7} checked={props.state['economic-p7'] ? 1: 0}/>
                        <label htmlFor="economic-p7-yes">Si</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='economic-p7' id='economic-p7-no'
                            onChange={updateEconomicP7} checked={props.state['economic-p7'] ? 0: 1}/>
                        <label htmlFor="economic-p7-no">No</label>
                    </div>
                </div>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                        ¿Tienes en cuenta al hacer la compra los sellos de bienestar animal y/o ecológico?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='economic-p8' id='economic-p8-yes'
                            onChange={updateEconomicP8} checked={props.state['economic-p8'] ? 1: 0}/>
                        <label htmlFor="economic-p8-yes">Si</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='economic-p8' id='economic-p8-no'
                            onChange={updateEconomicP8} checked={props.state['economic-p8'] ? 0: 1}/>
                        <label htmlFor="economic-p8-no">No</label>
                    </div>
                </div>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                        ¿Donas tu ropa, alimentos u otros objetos que ya no utilizas a organizaciones sin animo de lucro y/o personas que lo necesitan?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='economic-p9' id='economic-p9-yes'
                            onChange={updateEconomicP9} checked={props.state['economic-p9'] ? 1: 0}/>
                        <label htmlFor="economic-p9-yes">Si</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='economic-p9' id='economic-p9-no'
                            onChange={updateEconomicP9} checked={props.state['economic-p9'] ? 0: 1}/>
                        <label htmlFor="economic-p9-no">No</label>
                    </div>
                </div>
        </section>
    );
}