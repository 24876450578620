import { useFetchWrapper } from "../helpers";

const useContactService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/contacts`;
    const fetchWrapper = useFetchWrapper();

    return {
        create
    }

    function create(contact) {
        return fetchWrapper.post(baseUrl,contact);
    }
}

export { useContactService };
