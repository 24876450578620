import React, {Component} from 'react'
import ReactApexChart from 'react-apexcharts';

class UserIndexRadialBarChart extends Component {

     render() { 
      const stateRadialBarUser = {
        series: [0, 0, 0],
        options: {
          chart: {
            type: 'radialBar',
            zoom: {
              enabled: false,
            },
          },
          fill: {
              colors: ['#4C9F38','#FEB714', '#EA1E2D' ]
          },
          legend: {
            labels: {
                useSeriesColors: true,
                colors: ['#4C9F38','#FEB714','#EA1E2D']
            }
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: '22px',
                },
                value: {
                  fontSize: '16px',
                },
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function (w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return w.globals.seriesTotals.reduce((a, b) => {
                        return (Math.round((parseInt(a,10) + parseInt(b,10))/3)).toString().concat('%');
                      }, 0);
                  }
                },
              }
            }
          },
          labels: ['Ambiental', 'Social', 'Económica'],
          colors: ['#4C9F38','#FEB714','#EA1E2D'],
        },
      };
      
      return(
      <ReactApexChart options={stateRadialBarUser.options} 
                           series={ this.props.series } 
                           type="radialBar" 
                           height={310}/> )  
    }

}

export default UserIndexRadialBarChart;