import React, { useEffect, useState } from 'react';
import { withRouter }  from 'react-router-dom';
import { Alert } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import { withAuthorization } from '../../Session';
import { useCompanyAdminService } from '../../../services/companyAdmin.service';
import { useCompanyService } from '../../../services/company.service';
import { useEmployeeImpactService } from '../../../services/employeeImpact.service';
import { useEmployeeService } from '../../../services/employee.service';
import { useEmployeeIndexService } from '../../../services/employeeIndex.service';
import { useEmployeeRatingService } from '../../../services/employeeRating.service';

import { employeeMapper, companyAdminMapperWithFullName, employeeIdMapper,  employeeRatingDefaultMapper} from "../../../utils/mapper";
import AdminUsersTable from './AdminUsersTable';
import EmployeeUsersTable from './EmployeeUsersTable';

const Main = () => {

    const companyAdminService = useCompanyAdminService();
    const companyService = useCompanyService();
    const employeeService = useEmployeeService();
    const employeeImpactService = useEmployeeImpactService();
    const employeeIndexService = useEmployeeIndexService();
    const employeeRatingService = useEmployeeRatingService();

    const [company, setCompany] = useState(null);
    const [companyAdmins, setCompanyAdmins] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [numEmployeesInCompany, setNumEmployeesInCompany] = useState(0);
    const [dataAdminForm, setDataAdminForm] = useState({
        form: { 
            email: '',
            name: '',
            lastName: '',
        }
    });
    const [dataEmployeesForm, setDataEmployeesForm] = useState({
        form: {
            email: '',
            name: '',
            lastName: '',
            companyId: '',
            companyName: '',
        }
    });
    const [error, setError] = useState(null);
    const [errorAdmin, setErrorAdmin] = useState(null);
    const [errorEmployee, setErrorEmployee] = useState(null);
    const [errorEmployeeFile, setErrorEmployeeFile] = useState(null);
    const [showTooltip, setShowTooltip] = useState(true);
    const companyAdminsDefault = [{name:'Loading...',lastName:'Loading...',email:'Loading...'}];
    const employeesDefault = [{name:'Loading...',lastName:'Loading...',email:'Loading...'}];
    
    const initializeStates = () => {
        setDataAdminForm({
            form: {
                email: '',
                name: '',
                lastName: '',
            }
        });
        setDataEmployeesForm({
            form: {
                email: '',
                name: '',
                lastName: '',
                uid: '',
            }
        })
    }

    const updateAdminForm = (key, value) => {
        const { form } = dataAdminForm;
        form[key] = value;
        setDataAdminForm({
            ...dataAdminForm,
            form,
        });
    };

    const updateAdmin = (e) => {
        updateAdminForm(e.target.name, e.target.value);
    }

    const updateEmployeeForm = (key, value) => {
        const { form } = dataEmployeesForm;
        form[key] = value;
        setDataEmployeesForm({
            ...dataEmployeesForm,
            form,
        })
    }

    const updateEmployee = (e) => {
        updateEmployeeForm(e.target.name,e.target.value);
    }

    const createCompanyAdmin = (email, companyId, companyName, companyCode, name, lastName) => {

        const companyAdminMapped = companyAdminMapperWithFullName(
            email,
            companyId,
            companyName,
            companyCode,
            name,
            lastName);

        companyAdminService.post(companyAdminMapped)
            .then( () => Promise.resolve(true))
            .catch (error => {
                setErrorAdmin(error);
                initializeStates();
            });
    }

    const setNewIndexToEmployee = (employeeId) => {
        const employeeIndexMapped = employeeIdMapper(employeeId);
        employeeIndexService.create(employeeIndexMapped)
            .then( () => console.log('employee index created'))
            .catch(error => setErrorEmployee(error));
    }
 
    const setNewImpactToEmployee = (employeeId) => {
        const employeeImpactMapped = employeeIdMapper(employeeId);
        employeeImpactService.create(employeeImpactMapped)
            .then( () => console.log('employee impact created'))
            .catch(error => setErrorEmployee(error));
    }

    const setNewRatingToEmployee = (employeeId) => {
        const employeeRatingMapped = employeeRatingDefaultMapper(employeeId);
        employeeRatingService.create(employeeRatingMapped)
            .then( () => console.log('employee rating created'))
            .catch(error => setErrorEmployee(error));
    }

    const updateInProgramEmployees = (employeeId) => {

        company.programInEmployees += 1;
        companyService.updateCompany(company)
            .then( () => {
                Promise.all([
                    setNewIndexToEmployee(employeeId),
                    setNewImpactToEmployee(employeeId),
                    setNewRatingToEmployee(employeeId)])
                .then( () => console.log('employee created succesfully'))
                .catch( error => setErrorEmployee(error));
            })
            .catch(error =>  setErrorEmployee('error updating ', error));
    }

    const getCompanyAdmins = (companyId) => {
        const companyAdminsList = [];
        companyAdminService.getByCompanyId(companyId)
            .then(data => {
                data.companyAdmins.forEach(
                    companyAdmin => {
                        companyAdminsList.push({
                            name: companyAdmin.name,
                            lastName: companyAdmin.lastName,
                            email: companyAdmin.email
                        })
                    }
                );
                setCompanyAdmins(companyAdminsList);
            }).catch(error => {
                setErrorAdmin(error)
                initializeStates()
            });
    }

    const getDataCompany = (companyId) => {
        companyService.get(companyId)
            .then( data => setCompany(data.company))
            .catch( error => setError(error));
    }

    const getEmployees = (companyId) => {
        const employeeList = [];
        employeeService.getByCompanyId(companyId)
            .then( data => {
                data.employees.forEach(employee => {
                    employeeList.push({
                        name: employee.name,
                        lastName: employee.lastName,
                        email: employee.email,
                        uid: employee.uid
                    })
                });
                setNumEmployeesInCompany(data.employees.length);
                setEmployees(employeeList);
            })
            .catch( error => {
                setError(error);
            });
    }

    const onSubmitAdmins = (e) => {

        createCompanyAdmin(
            dataAdminForm.form.email, 
            JSON.parse(localStorage.getItem('company')).companyId, 
            company.companyName,
            company.companyCode,
            dataAdminForm.form.name,
            dataAdminForm.form.lastName);

         const newAdmin = [...companyAdmins,{email: dataAdminForm.form.email, name: dataAdminForm.form.name, lastName: dataAdminForm.form.lastName}];
         setCompanyAdmins(newAdmin);
         initializeStates();
 
        e.preventDefault();
     }
 
     const onSubmitEmployees = (e) => {
        const employeeMapped = 
                employeeMapper(
                    JSON.parse(localStorage.getItem('company')).companyId,
                    company.companyName,
                    dataEmployeesForm.form.email,
                    dataEmployeesForm.form.lastName,
                    dataEmployeesForm.form.name,
                    false,
                );
                employeeService.post(employeeMapped)
                .then( data => {
                    const newEmployee = [...employees,{email: data.employee.email, name:  data.employee.name, lastName:  data.employee.lastName, uid:data.employee.uid }];
                    setEmployees(newEmployee);
                    setNumEmployeesInCompany(numEmployeesInCompany+1);
                    updateInProgramEmployees(data.employee.employeeId);
                    initializeStates();})
                .catch(error => {
                    setErrorEmployee(error);
                    initializeStates();
                });
         e.preventDefault();
     }
 
     const onSubmitEmployeesFile = (e) => {
 
         e.preventDefault();
     }
 
     const openFile = () => {
         setErrorEmployeeFile(new Error('Función no disponible por ahora'));
     }

     useEffect(() => {
        const company = JSON.parse(localStorage.getItem('company'));
        getDataCompany(company.companyId);
        getCompanyAdmins(company.companyId);
        getEmployees(company.companyId)
    },[]);

    const isInvalidAdmin = dataAdminForm.form.email === '' ||
                           dataAdminForm.form.lastName === '' ||
                           dataAdminForm.form.email === '';
    
    const isInvalidEmployee = dataEmployeesForm.form.email === '' ||
                              dataEmployeesForm.form.lastName === '' ||
                              dataEmployeesForm.form.email === '';

    return(
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    {error && <Alert color="danger">{error.message}</Alert>}
                    <section>
                        <div className="row">
                            <div className="col-12 col-12-mobilep">
                                <h3 className="medium">Administración</h3>  
                                <h5 className="medium">Gestiona a tus empleados</h5>
                            </div>
                        </div>
                    </section>
                    <hr className="minor"/>
                    <section>
                        <p>Hasta 3 administradores con el plan <span className="greenlink">Basic</span></p>
                        {errorAdmin && <Alert color="danger">{errorAdmin.message}</Alert>}
                        <form onSubmit={onSubmitAdmins}>
                            <div className="row gtr-50 gtr-uniform">
                                <div className="col-6 col-12-mobilep">
                                    <input
                                        name="name"
                                        value={dataAdminForm.form.name}
                                        onChange={updateAdmin}
                                        type="text"
                                        placeholder="Nombre"
                                        />
                                </div>
                                <div className="col-6 col-12-mobilep"></div>
                                <div className="col-6 col-12-mobilep">
                                    <input
                                        name="lastName"
                                        value={dataAdminForm.form.lastName}
                                        onChange={updateAdmin}
                                        type="text"
                                        placeholder="Apellidos"
                                    />
                                </div>
                                <div className="col-6 col-12-mobilep"></div>
                                <div className="col-6 col-12-mobilep">
                                    <input
                                        name="email"
                                        value={dataAdminForm.form.email}
                                        onChange={updateAdmin}
                                        type="text"
                                        placeholder="Dirección email"
                                        />
                                </div>
                                <div className="col-6 col-12-mobilep"></div>
                                <div className="col-12">
                                    <ul className="actions">
                                        <li><input disabled={isInvalidAdmin} className="primary"type="submit" value="Alta administrador"/></li>
                                    </ul>
                                </div>
                              </div>
                          </form>
                    </section>
                    <hr className="minor"/>
                    <section>
                        <AdminUsersTable data={companyAdmins || companyAdminsDefault } />
                    </section>
                    <hr className="minor"/>
                    <section>
                        <p>Hasta 50 empleados con el plan <span className="greenlink">Basic</span> 
                            &nbsp;(Puedes dar de alta <span className="greenlink">{50-numEmployeesInCompany}</span> empleados más)</p>
                        <div className="row">
                            <div className="col-6 col-12-medium">
                                {errorEmployee && <Alert color="danger">{errorEmployee.message}</Alert>}
                                <form onSubmit={onSubmitEmployees}>
                                    <div className="row gtr-50 gtr-uniform">
                                        <div className="col-12 col-12-mobilep">
                                            <input
                                                name="name"
                                                value={dataEmployeesForm.form.name}
                                                onChange={updateEmployee}
                                                type="text"
                                                placeholder="Nombre"
                                                />
                                        </div>
                                        <div className="col-12 col-12-mobilep">
                                            <input
                                                name="lastName"
                                                value={dataEmployeesForm.form.lastName}
                                                onChange={updateEmployee}
                                                type="text"
                                                placeholder="Apellidos"
                                            />
                                        </div>
                                        <div className="col-12 col-12-mobilep">
                                            <input
                                                name="email"
                                                value={dataEmployeesForm.form.email}
                                                onChange={updateEmployee}
                                                type="text"
                                                placeholder="Dirección email"
                                                />
                                        </div>
                                        <div className="col-12">
                                            <ul className="actions">
                                                <li><input disabled={isInvalidEmployee} className="tertiary"type="submit" value="Alta empleado"/></li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-6 col-12-medium">
                                {errorEmployeeFile && <Alert color="danger">{errorEmployeeFile.message}</Alert>}
                                <form onSubmit={onSubmitEmployeesFile}>
                                    <div className="row gtr-50 gtr-uniform">
                                        <div className="col-12 col-12-mobilep">
                                            <p>Puedes subir un fichero para dar de alta empleados <span data-tip="hello world" className="icon solid fa-info-circle"></span></p>
                                            <ReactTooltip>
                                                <div className="row">
                                                    <div className="col-12 col-12-mobilep">
                                                        El fichero debe tener extensión .cvs, una linea por empleado y un máximo de 50 elementos. 
                                                        El formato debe ser: [nombre],[apellidos],[email]
                                                    </div>
                                                </div>
                                            </ReactTooltip>
                                        </div>
                                        <div className="col-12 col-12-mobilep">
                                            <input
                                                name="employeefile"
                                                onChange={openFile}
                                                type="employeefile"
                                                placeholder=" Examinar fichero"
                                                />
                                        </div>
                                        <div className="col-12">
                                            <ul className="actions">
                                                <li><input className="tertiary"type="submit" disabled value="Alta fichero"/></li>
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <hr className="minor"/>
                    <section>
                        <p>Tienes <span className="greenlink">{numEmployeesInCompany}</span> dados de alta</p>
                        <EmployeeUsersTable data={employees || employeesDefault } />
                    </section>
                </div>
            </section>
        </article>
    );
}


//const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;  

export default withAuthorization(condition)(withRouter(Main));
