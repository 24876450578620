import React from 'react';
import { withRouter } from 'react-router-dom';

 
const Main = () => {

    return (
        <article id="main">
            <section className="wrapper style5">
                    <div className="inner">
                        <section>
                          <h4>Impacto 360</h4>
                          <div class="row">
                            <div class="col-4">
                              <ul class="alt">
                                <li >
                                    <i className="icon solid fa-users"/> Personas ayudadas <b>1200</b>
                                </li>
                                <li>
                                <i className="icon solid fa-fingerprint"/> CO2 ahorrado <b>20 T.</b>
                                </li>
                                <li>
                                  <i className="icon solid fa-tint"/> Agua ahorrada <b>1500 litros</b>
                                </li>
                              </ul>
                             </div>
                          </div>
                        </section> 

                    </div>
                </section>
        
        </article>
    )
}

export default withRouter(Main);