import React, {useState} from 'react'
import Stats from './Stats';
import { Alert } from 'reactstrap';
import * as FORM_RATING from '../../../constants/database'

const StepTwo = props => {

    const [showAuxiliarFirst, setShowAuxiliarFirst] = useState(false)
    const [showAuxiliarSecond, setShowAuxiliarSecond] = useState(false)
    const [showAuxiliarThird, setShowAuxiliarThird] = useState(false)

    return (
        <div>
            { props.form.firstname && <h3>Hey {props.form.firstname}! 👋</h3> }
            <SectionEnvironmentalFirst update={props.update} setShowAuxiliar={setShowAuxiliarFirst} state={props.form}/>
            { props.form[FORM_RATING.environmentalP3] ? <SectionEnvironmentalFirstAux update={props.update} state={props.form}/> : null }
            <SectionEnvironmentalSecond update={props.update} setShowAuxiliar={setShowAuxiliarSecond} state={props.form}/>
            { props.form[FORM_RATING.environmentalP4] ? <SectionEnvironmentalSecondAux update={props.update} state={props.form}/> : null }
            <SectionEnvironmentalThird update={props.update} setShowAuxiliar={setShowAuxiliarThird} state={props.form}/>
            { props.form[FORM_RATING.environmentalP5] ? <SectionEnvironmentalThirdAux update={props.update} state={props.form}/> : null }
            <SectionEnvironmentalFourth update={props.update} state={props.form}/>
            <Stats step={2} {...props} />
        </div>
    );
};

export default StepTwo;

const SectionEnvironmentalFirst = (props) => {
    
    const resetAux = (e) => {
        props.update(e.target.name, false)
		props.update(FORM_RATING.environmentalP3AuxPlastic,false);
		props.update(FORM_RATING.environmentalP3AuxGlass,false);
		props.update(FORM_RATING.environmentalP3AuxCardboard, false);
    }

	const update = (e) => {

        e.target.id === 'environmental-p3-yes' ? props.update(e.target.name, true) : resetAux(e);
        e.target.id === 'environmental-p3-yes' ? props.setShowAuxiliar(true) : props.setShowAuxiliar(false);
    };

    return (
        <section>
                <div className="row row-form-multistep">
                    {!!JSON.parse(localStorage.getItem('employee')).filledRatingForm  && <Alert color="warning">El cuestionario ya ha sido respondido. Desde aquí únicamente podrás 
                    modificar tus áreas de interés. Para seguir progresando en tu rating de sostenbilidad, 
                    puedes hacerlo a través de tus <a href="/">acciones personalizadas</a> </Alert>}
                    <div className="col-12 col-12-small">
                        <label>
                            ¿Reciclas o separas en tu casa la basura? <p className="label-min">plástico, vidrio, cartón</p>
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p3' id='environmental-p3-yes'
                            onChange={update} checked={props.state['environmental-p3'] ? 1: 0}/>
                        <label htmlFor="environmental-p3-yes">Si</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p3' id='environmental-p3-no'
                            onChange={update} checked={props.state['environmental-p3'] ? 0: 1}/>
                        <label htmlFor="environmental-p3-no">No</label>
                    </div>
                </div>
        </section>
    );
};

const SectionEnvironmentalFirstAux = (props) => {

	const update = (e) => {
        props.update(e.target.id, e.target.checked);
    };

	return(
		<section>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                            ¿Cuáles separas en casa para facilitar el reciclaje?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p3-aux' id='environmental-p3-aux-plastic'
                            onChange={update} checked={props.state['environmental-p3-aux-plastic'] ? 1: 0}/>
                        <label htmlFor="environmental-p3-aux-plastic">Plástico</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p3-aux' id='environmental-p3-aux-glass'
                            onChange={update} checked={props.state['environmental-p3-aux-glass'] ? 1: 0}/>
                        <label htmlFor="environmental-p3-aux-glass">Vidrio</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p3-aux' id='environmental-p3-aux-cardboard'
                            onChange={update} checked={props.state['environmental-p3-aux-cardboard'] ? 1: 0}/>
                        <label htmlFor="environmental-p3-aux-cardboard">Cartón</label>
                    </div>                    
                </div>
        </section>
	);
}

const SectionEnvironmentalSecond = (props) => {
    
    const resetAux = (e) => {
        props.update(e.target.name, false)
		props.update('environmental-p4-aux-shower',false);
		props.update('environmental-p4-aux-tap',false);
		props.update('environmental-p4-aux-atomizer', false);
		props.update('environmental-p4-aux-toilet', false);
    }

	const update = (e) => {

        e.target.id === 'environmental-p4-yes' ? props.update(e.target.name, true) : resetAux(e);
        e.target.id === 'environmental-p4-yes' ? props.setShowAuxiliar(true) : props.setShowAuxiliar(false);
    };

    return (
        <section>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                        ¿Tratas de hacer un consumo responsable del agua en tu casa?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p4' id='environmental-p4-yes'
                            onChange={update} checked={props.state['environmental-p4'] ? 1: 0}/>
                        <label htmlFor="environmental-p4-yes">Si</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p4' id='environmental-p4-no'
                            onChange={update} checked={props.state['environmental-p4'] ? 0: 1}/>
                        <label htmlFor="environmental-p4-no">No</label>
                    </div>
                </div>
        </section>
    );
};

const SectionEnvironmentalSecondAux = (props) => {

	const update = (e) => {
        props.update(e.target.id, e.target.checked);
    };

	return(
		<section>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                            ¿Qué medios empleas?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p4-aux' id='environmental-p4-aux-shower'
                            onChange={update} checked={props.state['environmental-p4-aux-shower'] ? 1: 0}/>
                        <label htmlFor="environmental-p4-aux-shower">Me ducho en lugar de bañarme</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p4-aux' id='environmental-p4-aux-tap'
                            onChange={update} checked={props.state['environmental-p4-aux-tap'] ? 1: 0}/>
                        <label htmlFor="environmental-p4-aux-tap">Cierro el grifo cuando no lo necesito</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p4-aux' id='environmental-p4-aux-atomizer'
                            onChange={update} checked={props.state['environmental-p4-aux-atomizer'] ? 1: 0}/>
                        <label htmlFor="environmental-p4-aux-atomizer">Utilizo atomizadores de agua en grifos</label>
                    </div>   
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p4-aux' id='environmental-p4-aux-toilet'
                            onChange={update} checked={props.state['environmental-p4-aux-toilet'] ? 1: 0}/>
                        <label htmlFor="environmental-p4-aux-toilet">No utilizo el WC como papelera</label>
                    </div>                    
                </div>
        </section>
	);
}


const SectionEnvironmentalThird = (props) => {
    
    const resetAux = (e) => {
        props.update(e.target.name, false)
		props.update('environmental-p5-aux-led',false);
		props.update('environmental-p5-aux-standby',false);
		props.update('environmental-p5-aux-temp', false);
    }

	const update = (e) => {

        e.target.id === 'environmental-p5-yes' ? props.update(e.target.name, true) : resetAux(e);
        e.target.id === 'environmental-p5-yes' ? props.setShowAuxiliar(true) : props.setShowAuxiliar(false);
    };

    return (
        <section>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                        ¿Tomas medidas en casa para reducir tu consumo energético?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p5' id='environmental-p5-yes'
                            onChange={update} checked={props.state['environmental-p5'] ? 1: 0}/>
                        <label htmlFor="environmental-p5-yes">Si</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p5' id='environmental-p5-no'
                            onChange={update} checked={props.state['environmental-p5'] ? 0: 1}/>
                        <label htmlFor="environmental-p5-no">No</label>
                    </div>
                </div>
        </section>
    );
};

const SectionEnvironmentalThirdAux = (props) => {

	const update = (e) => {
        props.update(e.target.id, e.target.checked);
    };

	return(
		<section>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                            ¿Qué medios empleas?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p5-aux' id='environmental-p5-aux-led'
                            onChange={update} checked={props.state['environmental-p5-aux-led'] ? 1: 0}/>
                        <label htmlFor="environmental-p5-aux-led">Bombillas de bajo consumo</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p5-aux' id='environmental-p5-aux-standby'
                            onChange={update} checked={props.state['environmental-p5-aux-standby'] ? 1: 0}/>
                        <label htmlFor="environmental-p5-aux-standby">No dejo aparatos electricos en standby, los apago</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='checkbox' className='form-control' name='environmental-p5-aux' id='environmental-p5-aux-temp'
                            onChange={update} checked={props.state['environmental-p5-aux-temp'] ? 1: 0}/>
                        <label htmlFor="environmental-p5-aux-temp">Adecuo la temperatura de mi casa con termostatos (por ejemplo)</label>
                    </div>                                          
                </div>
        </section>
	);
}


const SectionEnvironmentalFourth = (props) => {

    const update = (e) => {
        
        document.getElementsByName('environmental-p6').forEach(element => {
            props.update(element.id,element.checked)
        })
    };

	return(
		<section>
                <div className="row row-form-multistep">
                    <div className="col-12 col-12-small">
                        <label>
                        ¿Qué medio de transporte es el que utilizas habitualmente?
                        </label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p6' id='environmental-p6-car'
                            onChange={update} checked={props.state['environmental-p6-car'] ? 1: 0}/>
                        <label htmlFor="environmental-p6-car">Coche/moto personal gasolina/diesel</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p6' id='environmental-p6-hybrid'
                            onChange={update} checked={props.state['environmental-p6-hybrid'] ? 1: 0}/>
                        <label htmlFor="environmental-p6-hybrid">Coche/moto personal híbrido</label>
                    </div>
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p6' id='environmental-p6-electric'
                            onChange={update} checked={props.state['environmental-p6-electric'] ? 1: 0}/>
                        <label htmlFor="environmental-p6-electric">Coche/moto personal eléctrico</label>
                    </div> 
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p6' id='environmental-p6-public'
                            onChange={update} checked={props.state['environmental-p6-public'] ? 1: 0}/>
                        <label htmlFor="environmental-p6-public">Transporte público</label>
                    </div> 
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p6' id='environmental-p6-bike'
                            onChange={update} checked={props.state['environmental-p6-bike'] ? 1: 0}/>
                        <label htmlFor="environmental-p6-bike">Bicicleta</label>
                    </div>   
                    <div className="col-4 col-12-small">
                        <input type='radio' className='form-control' name='environmental-p6' id='environmental-p6-walk'
                            onChange={update} checked={props.state['environmental-p6-walk'] ? 1: 0}/>
                        <label htmlFor="environmental-p6-walk">Andando</label>
                    </div>                                       
                </div>
        </section>
	);
}
