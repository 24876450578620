import React, { useEffect, useState } from 'react';
import { withRouter }  from 'react-router-dom';
import { LinkedinShareButton, LinkedinIcon, FacebookShareButton, FacebookIcon} from 'react-share';
import Avatar from 'react-avatar';
import AvatarEditor from 'react-avatar-editor';
import { Alert } from 'reactstrap';
import DropZone from 'react-dropzone';
import Select from 'react-select';
import { withAuthorization } from '../Session';
import { CompanyCompaniesIndexRadialBarChart, EmployeesRadialBarChart, CompanyBarChart} from '../Chart';
import Impact from '../App/Impact';
import { useCompanyService } from '../../services/company.service';
import { useCompanyIndexService } from '../../services/companyIndex.service';
import { useCompanyImpactService } from '../../services/companyImpact.service';
import { useHistoricalCompanyIndexService } from '../../services/historicalCompanyIndex.service';
import { useTotalCompaniesIndexesService } from '../../services/totalCompaniesIndexes.service';

import { storage, firebase } from '../Firebase/firebase'
import { percentageUnconEmployees, getActualYear } from '../../utils';


import Modal from '../App/Modal';

const Dashboard = () => {

  const companyService = useCompanyService();
  const companyIndexService = useCompanyIndexService();
  const companyImpactService = useCompanyImpactService();
  const historicalCompanyIndexService = useHistoricalCompanyIndexService();
  const totalCompaniesIndexesService = useTotalCompaniesIndexesService();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [company, setCompany] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [logoCompany, setLogoCompany] = useState(null);
  const [urlLogoCompany, setUrlLogoCompany] = useState(null);
  const [progress, setProgress] = useState(0);
  const [companyAndTotalCompanySeries, setCompanyAndTotalCompanySeries] = useState(null);
  const [unconEmployeesPercentage, setUnconEmployeesPercentage] = useState([0]);
  const [companyName, setCompanyName] = useState(null);
  const [impacts, setImpacts] = useState(null);
  const [options, setOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState({value: getActualYear(), label: getActualYear()});
  const [historicalYearSeries, setHistoricalYearSeries] = useState(null);

  const defaultCompanyAndTotalCompanySeries = [{name: 'Loading company', data: [0, 0, 0],},{name: 'Loading total companies',data: [0, 0, 0],},]
  const defaultCompanyName = 'Default Company Name'
  const defaultImpact = [{  
    key: 'impact',
    name: 'loading impact',
    value: 0,
   }]
  const defaultOptions = []
  const defaultHistorialYearSeries = [
    {name: 'Loading environmental...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
    {name: 'Loading economic...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
    {name: 'Loading social...',data: [0,0,0,0,0,0,0,0,0,0,0,0]}]

  const months = ['january','february','march','april','may','june','july','august','setember','october','november','december']

  const fShowModal = () => {
      setShowModal(true);
  }

  const fHideModal = () => {
    setShowModal(false);
  }

  const handleDrop = dropped => {
    setLogoCompany(dropped[0]);
  }

  const calculateTotalCompaniesIndexesAverage = (accummulated, totalCompanies) => {
      return (accummulated/totalCompanies).toFixed(2);
  }

  const fUploadLogo = () => {
    let company = JSON.parse(localStorage.getItem('company'));
    if (company !== null) {
      let file = logoCompany;
      let storageRef = storage.ref();
      let companyLogoRef = storageRef.child('companies/logos/' + company.companyid + '.jpg')
      let uploadTask = companyLogoRef.put(file);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          var progress = Math.round((snapshot.bytesTransferred/snapshot.totalBytes))*100
          setProgress(progress);
        }, 
        error => {
           throw error;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(
            (url) => {
              company.urlLogo = url;
              companyService.updateCompany(company)
                .then(data => setSuccess('logo company updated correctly'))
                .catch( (error) => setError('An error happenned uploading the logo company'));
          })
          setShowModal(false);
        })
    }
  }

  const getSeries = (company) => {
    let companySeries = [0,0,0]
    let companyAndTotalCompanySeries = [{name: 'Loading company', data: [0, 0, 0],},{name: 'Loading total companies',data: [0, 0, 0],},]
    let totalSeries = [0,0,0]

    //REMOVE
    companyAndTotalCompanySeries[0].name = company.companyName;
    companyAndTotalCompanySeries[1].name = "Media Total Compañías"
    companyAndTotalCompanySeries[1].data = [0,0,0]
    //-- REMOVE

    companyIndexService.getByCompanyId(company.companyId)
      .then( data => {
          companySeries[0] = data.companyIndex.environmental
          companySeries[1] = data.companyIndex.social
          companySeries[2] = data.companyIndex.economic
          companyAndTotalCompanySeries[0].data = companySeries
      })
      .catch((error) => {
        setError("Error getting company index:", error);
      });

      totalCompaniesIndexesService.getByCompanyPlan("basic")
        .then ( data => {
          totalSeries[0] = 
            calculateTotalCompaniesIndexesAverage(
              data.totalCompaniesIndexes.environmentalAccumulated,
              data.totalCompaniesIndexes.companiesTotalNumber);
          totalSeries[1] = 
            calculateTotalCompaniesIndexesAverage(
              data.totalCompaniesIndexes.socialAccumulated,
              data.totalCompaniesIndexes.companiesTotalNumber);
          totalSeries[2] = 
            calculateTotalCompaniesIndexesAverage(
              data.totalCompaniesIndexes.economicAccumulated,
              data.totalCompaniesIndexes.companiesTotalNumber);
          companyAndTotalCompanySeries[1].data = totalSeries
          setCompanyAndTotalCompanySeries(companyAndTotalCompanySeries)  
        })
        .catch( error => setError("Error getting document:", error));        
  }

  const getImpacts = (company) => {
    companyImpactService.getByCompanyId(company.companyId)
      .then( data => {
          !data.companyImpact?setImpacts(defaultImpact):setImpacts(Object.values(data.companyImpact).filter ( element => { 
              return typeof element=='object';
            }));
      })
      .catch( error => {
        setError("Error getting document:", error);
      });
  }
  
  const getHistoricalCompanyIndexByYear = (company, year) => {
      const environmental = [], economic = [], social = [];
      const historical = [
        {name: 'Loading environmental...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
        {name: 'Loading economic...',data: [0,0,0,0,0,0,0,0,0,0,0,0],},
        {name: 'Loading social...',data: [0,0,0,0,0,0,0,0,0,0,0,0]}];

      historicalCompanyIndexService.getByYear(company.companyId, year)
        .then( data => {
            if (!data.historicalCompanyIndexes)
                throw new Error('data error in historical company index');
            const actualMonth = (new Date()).getFullYear().toString() === year ? (new Date()).getMonth(): 12;
            if (actualMonth === 0) {
                environmental[actualMonth] = data.historicalCompanyIndexes[actualMonth].months["january"].environmental;
                economic[actualMonth] = data.historicalCompanyIndexes[actualMonth].months["january"].economic;
                social[actualMonth] = data.historicalCompanyIndexes[actualMonth].months["january"].social; 
            } else {
              data.historicalCompanyIndexes.forEach( historicalCompanyIndex => {
                months.slice(0,actualMonth).forEach( (month, index) => {
                    environmental[index] = historicalCompanyIndex.months[month].environmental;
                    economic[index] = historicalCompanyIndex.months[month].economic;
                    social[index] = historicalCompanyIndex.months[month].social;
                })
              });
            }
            historical[0].name = 'Ambiental';
            historical[0].data = environmental;
            historical[1].name = 'Económica';
            historical[1].data = economic;
            historical[2].name = 'Social';
            historical[2].data = social;
            setHistoricalYearSeries(historical);

        })
        .catch( error => setError(error));
  }

  const getOptions = (company) => {
      const years = [];
      historicalCompanyIndexService.getByCompanyId(company.companyId)
        .then( data => {
            if (!data.historicalCompanyIndexes)
                throw new Error('data error in historical company index');
            data.historicalCompanyIndexes.forEach( (historicalCompanyIndex,index) => {
              years[index] = { value: historicalCompanyIndex.year, label: historicalCompanyIndex.year};
            });
            setOptions(years);
        })
        .catch( error => setError(error));
  }

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption)
    
    let company = JSON.parse(localStorage.getItem('company'));
    if (company) {
      getHistoricalCompanyIndexByYear(company,selectedOption.value);
    }
  };

  const getDataCompany = (company) => {
    const unconEmployees = [0];
    companyService.get(company.companyId)
        .then( data => {
          unconEmployees[0] = data.company.programInEmployees === 0 ?
            0 : percentageUnconEmployees(data.company.programInEmployees, data.company.totalEmployees);
          setUnconEmployeesPercentage(unconEmployees);
          setCompanyName(data.company.companyName);
          setUrlLogoCompany(data.company.logoCompanyUrl);
          setCompany(data.company);
        })
        .catch( error => setError(error));
  }
  
  useEffect( () => {
    const company = JSON.parse(localStorage.getItem('company'));
    if (company) {
      getDataCompany(company);
      getSeries(company);
      getImpacts(company);
      getOptions(company);
      getHistoricalCompanyIndexByYear(company, getActualYear());
    }
  }, [])

  return(
    <article id="main">
        <section className="wrapper style5">
            <div className="inner">
                {error && <Alert color="danger">{error.message}</Alert>}
                {success && <Alert color="success">{success.message}</Alert>}
                <section>
                    <div className="row">
                    <div className="col-5 col-6-medium">
                      <span className="image fit">
                        <span onClick={fShowModal} className="evidence">
                          <Avatar name={companyName || defaultCompanyName } src={urlLogoCompany} round={true} size="120"/>
                        </span>
                      </span>
                      <b>{companyName || defaultCompanyName }</b>
                    </div>
                  </div>
                </section>
                <section className="dashboard">
                    <h4>Tu índice agregado</h4>
                  <div className="row">
                    <div className="col-6 col-12-medium">
                        <EmployeesRadialBarChart series={ unconEmployeesPercentage }/>
                    </div>
                    <div className="col-6 col-12-medium">
                        <CompanyCompaniesIndexRadialBarChart series={ companyAndTotalCompanySeries || defaultCompanyAndTotalCompanySeries }/>
                    </div>
                  </div>
                </section>
                <section className="dashboard">
                  <h4>Impacto 360</h4>
                  <div className="row">
                    <div className="col-12">
                      <Impact impacts={ impacts || defaultImpact} />
                    </div>
                    <div className="col-8">
                      <ul className="alt">
                        <li>
                            <LinkedinShareButton children={LinkedinIcon} url={'https://unconcorp.com/company/reports/impact-360.html'} className="linkedin-share">
                                <LinkedinIcon size={32} round={true}/>
                              </LinkedinShareButton> 
                              <FacebookShareButton children={FacebookIcon} url={'https://unconcorp.com/company/reports/impact-360.html'} className="linkedin-share">
                                <FacebookIcon size={32} round={true}/>
                              </FacebookShareButton>   
                        </li>
                        <li>
                            
                        </li>
                      </ul>
                      </div>
                    </div>
                </section> 
                <section className="dashboard">
                    <h4>Tu sosteniblidad</h4>
                    <Select name="demo-category" id="demo-category" 
                            options={options || defaultOptions} 
                            value={selectedOption}
                            onChange={handleChange}/>
                    <div className="row">
                        <div className="col-12 col-12-medium">
                          <CompanyBarChart series= {historicalYearSeries || defaultHistorialYearSeries}/> 
                        </div>
                    </div>
                </section>
                <Modal show={showModal} handleClose={fHideModal} handleAction={fUploadLogo} valueAction="Subir">
                    <DropZone
                      onDrop={handleDrop}
                      noClick
                      noKeyboard
                      style={{ width: '250px', height: '250px' }}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <AvatarEditor
                                    image={urlLogoCompany}
                                    width={250}
                                    height={250}
                                    border={50}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={1.2}/>
                                <input {...getInputProps()} />
                                <p>Arrastra y suelta aquí el logo de tu compañía</p>
                            </div>
                           )}
                      </DropZone>
                  </Modal>
            </div>
        </section>
    </article>);
}


//const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;  

export default withAuthorization(condition)(withRouter(Dashboard));