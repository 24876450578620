import React, { Component } from 'react';

class Main extends Component {
    render() {
        return(
            <article id="main">
                <header>
                    <h2>Programa de Empresas</h2>
                </header>
                <section class="wrapper style5">
                    <div class="inner">
                        <div className="row-6 row-12-mobilep">
                            <h3 className="greenlink">Olvido de contraseña</h3>  
                        </div>
                    </div>
                </section>
            </article>
            
         );
    }
}
    
export default Main;