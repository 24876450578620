import React, { useState } from 'react';
import Cards from 'react-credit-cards';
import { Alert } from 'reactstrap';
import 'react-credit-cards/es/styles-compiled.css';
import {
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
} from '../../../utils/payment';

import { history } from '../../App/CompanyHeader';
import { useCompanyService } from '../../../services/company.service';
import { withAuthorization } from '../../Session';
import { withRouter }  from 'react-router-dom';

import * as ROUTES from '../../../constants/routes';

const Main = () => {

    const companyService = useCompanyService();

    const [data, setData] = useState( {
        cvc: '',
        expiry: '',
        name: '',
        number: '',
        focused: '',
    })

    const [error, setError] = useState(null)

    const handleInputFocus = (e) => {
        setData({
            ...data,
            focused: e.target.name,
        });
      };
    

    const handleInputChange = (e) => {

        if (e.target.name === 'number') {
            e.target.value = formatCreditCardNumber(e.target.value);
        } else if (e.target.name === 'expiry') {
            e.target.value = formatExpirationDate(e.target.value);
        } else if (e.target.name === 'cvc') {
            e.target.value = formatCVC(e.target.value);
        }

        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    }

    const onSubmit = (e) => {

        companyService.patch(JSON.parse(localStorage.getItem('company')).companyId)
            .then( () => {
                history.push(ROUTES.COMPANY_DASHBOARD);
            })
            .catch( (error) => {
                setError(error);
            });

        e.preventDefault();
    }

    const isInvalid = data.name === '' || 
                      data.number === '' ||
                      data.cvc === '' ||
                      data.expiry === '';
    
    return(
        <article id="main">
            <section className="wrapper style5">
                <div className="inner">
                    <section>
                        <div className="row">
                            <div className="col-12 col-12-mobilep">
                                <h3 className="medium">Plan <span className="greenlink">personal</span> de empresas</h3>  
                                <h5 className="medium">Este plan está limitado a empresas con menos de 50 empleados</h5>
                            </div>
                        </div>
                    </section>
                    <hr className="minor"/>
                    <section>
                            <div id="PaymentForm">
                                <div className="row">
                                    <div className="col-6 col-12-medium">
                                        <div className="row gtr-uniform">
                                            <div className="col-12 col-12-medium">
                                                <Cards
                                                    cvc={data.cvc}
                                                    expiry={data.expiry}
                                                    focus={data.focus}
                                                    name={data.name}
                                                    number={data.number}
                                                    focused={data.focused}
                                                />
                                                 <hr className="minor"/>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="col-6 col-12-medium">
                                        {error && <Alert color="danger">{error.message}</Alert>}
                                        <form  onSubmit={onSubmit}>
                                            <div className="row gtr-uniform">
                                                <div className="col-12 col-12-medium">
                                                    <input className=""
                                                        type='tel'
                                                        name='name'
                                                        placeholder='Nombre'
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </div>
                                                <div className="col-12 col-12-medium">
                                                    <input 
                                                        type='text'
                                                        name='number'
                                                        placeholder='Número Tarjeta'
                                                        pattern="[\d| ]{16,22}"
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </div>
                                                <div className="col-6 col-12-medium">
                                                    <input 
                                                        type='tel'
                                                        name='expiry'
                                                        placeholder='mm / aa'
                                                        pattern="\d\d/\d\d"
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </div>
                                                <div className="col-6 col-12-medium">
                                                    <input 
                                                        type='tel'
                                                        name='cvc'
                                                        placeholder='CVC'
                                                        pattern="\d{3,4}"
                                                        onChange={handleInputChange}
                                                        onFocus={handleInputFocus}
                                                    />
                                                </div>
                                                <div className="col-6 col-12-medium">
                                                    <input type="submit" value="Realizar Pago"  disabled={isInvalid} className="pay"/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                    </section>
                </div>
            </section>
        </article>
    );
}

//const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
const condition = authUser => !!authUser;  

export default withAuthorization(condition)(withRouter(Main));
