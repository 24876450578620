import { useFetchWrapper } from "../helpers";

const useEmployeeIndexService = () => {

    const baseUrl = `${process.env.REACT_APP_API_URL}/employee-indexes`;
    const fetchWrapper = useFetchWrapper();

    return {
        create,
        getByEmployeeId,
        update,
        calculateEmployeeIndexFromRating
    }

    function create(employeeIndex) {
        return fetchWrapper.post(baseUrl,employeeIndex);
    }

    function getByEmployeeId(employeeId) {
        return fetchWrapper.get(`${baseUrl}/${employeeId}`);
    }

    function update(employeeId, employeeIndex) {
        return fetchWrapper.patch(`${baseUrl}/${employeeId}`,employeeIndex);
    }

    function calculateEmployeeIndexFromRating(employeeId,rating) {
        return fetchWrapper.patch(`${baseUrl}/${employeeId}/calculate`,rating);
    }
}

export { useEmployeeIndexService};
