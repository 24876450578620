import React, { Component } from 'react';

class Main extends Component {
    render() {
        return(
            <section id="banner">
                <div className="inner">
                    <h2>Página no encontrada (Error 404)</h2>
                    <p>Lo siento, pero estás buscando algo que no está aquí<br />
                    </p>
                </div>               
            </section>
         );
    }
}
    
export default Main;