import React, { useState, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Select from 'react-select'
import countryList from 'react-select-country-list'

import { authAtom } from '../../../state';

import { authentication } from '../../Firebase';
import { Alert } from 'reactstrap';
import { history } from '../../App/Header';
import { userMapper, companyMapper, companyAdminMapperWithoutName, companyBasicMapper } from '../../../utils';

import { useCompanyService } from '../../../services/company.service';
import { useCompanyAdminService } from '../../../services/companyAdmin.service';

import * as ROUTES from '../../../constants/routes';
import {
    formatNumber
} from '../../../utils/validations';

const Main = () => {
    const setAuth = useSetRecoilState(authAtom);
    const [loading, setLoading] = useState(false);
    //const [phoneNumber, setPhoneNumber] = useState();
    const options = useMemo(() => countryList().getData(), [])
    const [dataForm, setDataForm] = useState({
        form: {
            email: '',
            passwordOne: '',
            passwordTwo: '',
            companyName: '',
            totalEmployees: '',
            companyCode: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            phoneNumber: '',
            position: '',
        }
    })
    const [error, setError] = useState(null);
    
    const companyService = useCompanyService();
    const companyAdminService = useCompanyAdminService();

    const updateForm = (key, value) => {
        const { form } = dataForm;
        form[key] = value;
        setDataForm({
            ...dataForm,
            form,
        });
    };

    const update = (e) => {
        updateForm(e.target.name, e.target.value);
    }

    const handlePhoneNumberChange = (key, value) => {
        updateForm(key, value);
      }

    const handleCountryChange = value => {
        updateForm('country', value.label);
    }

    const initializeStates = () => {
        setDataForm({
            form: {
                email: '',
                passwordOne: '',
                passwordTwo: '',
                companyName: '',
                totalEmployees: '',
                companyCode: '',
                address: '',
                address2: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
                phoneNumber: '',
                position: '',
            }
        });
    }


    // Call #3
    const createCompanyAdmin =  (company) => {
        
        const companyAdminMapped = companyAdminMapperWithoutName(
            company.admin.uid,
            company.admin.email,
            company.companyId,
            company.companyName,
            company.companyCode);

        companyAdminService.post(companyAdminMapped)
            .then( data => {
                history.push(ROUTES.COMPANY_DASHBOARD); 
            }).catch (error => {
                setError(error);
                setLoading(false);
            });
    }

    // Call #2
    const createCompany = (email, uid, form) => {

        const companyMapped = companyMapper(email, uid, form);
        companyService.post(companyMapped)
            .then( data => {
                createCompanyAdmin(data.company);
                localStorage.setItem('company',
                    JSON.stringify(companyBasicMapper(
                                        data.company.companyId, 
                                        data.company.companyCode, 
                                        data.company.companyName)));
                })
            .catch (error => {
                setError(error);
                setLoading(false);
            });
    }

    // Call #1
    const onSubmit = event => { 

        setLoading(true);
       // create user
       authentication
        .createUserWithEmailAndPassword(dataForm.form.email.trim(), dataForm.form.passwordOne)
        .then((userAuth) => {
            const userMapped = userMapper(JSON.parse(JSON.stringify(userAuth.user)));
            localStorage.setItem('user', JSON.stringify(userMapped));
            setAuth(userMapped);

            // update user with uid if exists in our database for this company
            createCompany(userAuth.user.email, userAuth.user.uid, dataForm.form);
        })
        .catch(error => {
            setError(error);
            initializeStates();
            setLoading(false);
            // ver si podemos borrar usuario
        });
   
        event.preventDefault();
    }

    const handleInputChange = (e) => {
 
        if (e.target.name === 'totalEmployees') {
            e.target.value = formatNumber(e.target.value,2);
        } else if (e.target.name === 'postalCode') {
            e.target.value = formatNumber(e.target.value,5);
        }

       updateForm(e.target.name, e.target.value);
    }

    const isInvalid =
      dataForm.form.passwordOne !== dataForm.form.passwordTwo ||
      dataForm.form.passwordOne === '' ||
      dataForm.form.email === '' || 
      dataForm.form.position === '' || 
      dataForm.form.companyName === '' || 
      dataForm.form.companyCode === '' || 
      dataForm.form.totalEmployees === '' || 
      dataForm.form.address === '' || 
      dataForm.form.city === '' || 
      dataForm.form.postalCode === '' || 
      dataForm.form.city === '' || 
      dataForm.form.country === '' || 
      dataForm.form.phoneNumber === '';

    return(
        <article id="main">
                <section className="wrapper style5">
                <div className="inner">
                    <div className="row-6 row-12-mobilep">
                        <h3 className="greenlink">REGÍSTRATE EN EL PROGRAMA DE EMPRESAS*</h3>  
                        <h5>* Sólo con el plan <span className="greenlink">Basic</span></h5>
                        <hr className="minor"/>
                        {error && <Alert color="danger">{error.message}</Alert>}
                        <form onSubmit={onSubmit}>
                            <div className="row gtr-50 gtr-uniform">
                                
                                <div className="col-6 col-12-mobilep">
                                    <input
                                        name="email"
                                        value={dataForm.email}
                                        onChange={update}
                                        type="text"
                                        placeholder="Email Address"
                                        />
                                </div>
                                <div className="col-4 col-12-mobilep">
                                      <select 
                                      name="position"
                                      value={dataForm.position}
                                      onChange={update}>
                                        <option value="">-- Selecciona tu puesto --</option>
                                        <option value="owner">Owner</option>
                                        <option value="ceo">CEO</option>
                                        <option value="cto">CTO / CIO (Tecnología)</option>
                                        <option value="marketing">Director/a Marketing</option>
                                        <option value="people">Director/a People</option>
                                        <option value="other">Otro</option>
                                      </select>
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <input
                                    name="passwordOne"
                                    value={dataForm.passwordOne}
                                    onChange={update}
                                    type="password"
                                    placeholder="Password"
                                    />
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <input
                                    name="passwordTwo"
                                    value={dataForm.passwordTwo}
                                    onChange={update}
                                    type="password"
                                    placeholder="Confirm Password"
                                    />
                                </div>
                                <hr className="minor"/>
                                <div className="col-12 col-12-mobilep">
                                    <input
                                        name="companyName"
                                        value={dataForm.companyName}
                                        onChange={update}
                                        type="text"
                                        placeholder="Nombre Empresa / Razón Social"
                                        />
                                </div>
                                <div className="col-4 col-12-mobilep">
                                    <input
                                        name="companyCode"
                                        value={dataForm.companyCode}
                                        onChange={update}
                                        type="text"
                                        placeholder="CIF"
                                        />
                                </div>
                                <div className="col-2 col-12-mobilep">
                                    <input
                                        name="totalEmployees"
                                        value={dataForm.totalEmployees}
                                        onChange={update}
                                        type="text"
                                        pattern="\d\d"
                                        placeholder="empleados"
                                        onChange={handleInputChange}
                                        />
                                </div>
                                <div className="col-4 col-12-mobilep">
                                </div>
                                <div className="col-12 col-12-mobilep">
                                    <input
                                        name="address"
                                        value={dataForm.address}
                                        onChange={update}
                                        type="text"
                                        placeholder="Dirección"
                                        />
                                </div>
                                <div className="col-12 col-12-mobilep">
                                    <input
                                        name="address2"
                                        value={dataForm.address2}
                                        onChange={update}
                                        type="text"
                                        placeholder="Dirección 2"
                                        />
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <input
                                        name="city"
                                        value={dataForm.city}
                                        onChange={update}
                                        type="text"
                                        placeholder="Ciudad"
                                        />
                                </div>
                                <div className="col-6 col-12-mobilep">
                                    <input
                                        name="state"
                                        value={dataForm.state}
                                        onChange={update}
                                        type="text"
                                        placeholder="Estado/Provincia"
                                        />
                                </div>
                                <div className="col-4 col-12-mobilep">
                                    <input
                                        name="postalCode"
                                        value={dataForm.postalCode}
                                        onChange={update}
                                        type="text"
                                        placeholder="Código Postal"
                                        onChange={handleInputChange}
                                        />
                                </div>
                                <div className="col-4 col-12-mobilep">
                                    <Select options={options} 
                                            value={dataForm.country} 
                                            onChange={handleCountryChange} 
                                            name="country"/>
                                </div>
                                <div className="col-4 col-12-mobilep">
                                </div>
                                <div className="col-4 col-12-mobilep">
                                    <PhoneInput
                                        name="phoneNumber"
                                        placeholder="teléfono"
                                        value={dataForm.phoneNumber}
                                        defaultCountry="ES"
                                        onChange={phone => handlePhoneNumberChange('phoneNumber',phone)}/>
                                </div>
                                <div className="col-8 col-12-mobilep">
                                </div>
                                <hr className="minor"/>
                                <div className="col-12">
                                    <ul className="actions special">
                                        <li><input className="primary"type="submit" value="Registrarse"  disabled={isInvalid}/></li>
                                    </ul>
                                </div>
                              </div>
                          </form>
                    </div>
                </div>
            </section> 
            {loading ? 
            <div className="Loader">
                <img src="/assets/css/images/Spin-1s-100px.gif" alt="loading"></img>
            </div> : null}
        </article>
        );
}
    
export default Main;