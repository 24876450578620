export const LANDING = '/';
export const COOKIES = '/cookies';
export const PRIVACY = '/privacy';

export const ENGAGE_PLAN = '/engage-plan';

export const SIGNOUT = '/signout';

export const PLANS = '/plans';
export const FIND_OUT = '/find-out';
export const CONTACT = '/contact';

export const USER_PROFILE = '/user/profile';
export const USER_SIGNIN = '/user/signin';
export const USER_SIGNUP = '/user/signup';
export const USER_PASSWORD_FORGET = '/user/password-forget';
export const USER_HOME = '/user/home';
export const USER_RATING = '/user/rating';
export const USER_ACTIONS = '/user/actions';

//export const COMPANY_PROFILE = '/welcome-company';
export const COMPANY_SIGNUP = '/company/signup';
export const COMPANY_PAY = '/company/pay';
export const COMPANY_SIGNIN = '/company/signin';
export const COMPANY_PASSWORD_FORGET = '/company/password-forget';
export const COMPANY_DASHBOARD = '/company/dashboard';
export const COMPANY_REPORT = '/company/report';
export const COMPANY_PROFILE = '/company/profile';
export const COMPANY_ACTIONS = '/company/actions';
export const COMPANY_MANAGEMENT = '/company/management';
export const COMPANY_REPORTS = '/company/reports';
export const COMPANY_BASIC_REPORTS = '/company/reports/basic';
export const COMPANY_PROFESSIONAL_REPORTS = '/company/reports/professional';
export const COMPANY_PREMIUM_REPORTS = '/company/reports/premium';


